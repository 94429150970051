import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import axios from 'axios';
import { Label, Table, Button, Header, Dimmer, Loader, Image } from 'semantic-ui-react'
import "./styles.css"
import no_orders from "../images/empty/no_orders_person.png"

class OrdersDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      loading: true,
      opened: false
    };
  }

  getOrdersByToken = (userid, user_type) => {
    const findPipe = userid.indexOf("|")
    const id = userid.substr(findPipe+1)
    let url = user_type === "ADMIN" ? 'https://api.dontwaste.me/orders/' : `https://api.dontwaste.me/orders/purchases/accounts/${id}`
    if (this.state.loading) {
      axios.get(`${url}`, {})
        .then(res => ( this.setState({ orders: res.data.orders, loading: false}) ))
        .catch(err => {console.log(err);});
    }
  }

  openExtra = () => {
    this.setState({opened: !this.state.opened})
  }

  render() {
    const { orders, loading } = this.state;
    return (
              <div>
                <Dimmer active={loading} inverted data={this.getOrdersByToken(this.props.userid, this.props.user_type)}>
                  <Loader inverted>Loading</Loader>
                </Dimmer>
                { console.log('ordersTable: ', orders) }
                {
                    orders.length <= 0 ?
                      <>
                        <Image src={no_orders} centered verticalAlign="middle" size="medium" />
                        <Header as="h3" textAlign="center">You have no pending orders!</Header>
                      </>
                      :
                      (
                        <Table basic = "very" celled selectable style={{marginTop:'0px'}}>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>{this.props.dashboard_segment_orders_table_id}</Table.HeaderCell>
                            <Table.HeaderCell>{this.props.dashboard_segment_orders_table_status}</Table.HeaderCell>
                            <Table.HeaderCell>{this.props.dashboard_segment_orders_table_item_id}</Table.HeaderCell>
                            <Table.HeaderCell>{this.props.dashboard_segment_orders_table_item_qty}</Table.HeaderCell>
                            <Table.HeaderCell>{this.props.dashboard_segment_orders_table_item_actions}</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                              { orders.map(
                                  (order, i) => (
                                    i < 10 &&
                                    <Table.Row key={order._id} disabled={false}>
                                          <Table.Cell>#{order._id}</Table.Cell>
                                          <Table.Cell>
                                            <Label color = {order.status === 'COMPLETED' ? 'green' : 'red'} load>{order.status}</Label>
                                          </Table.Cell>
                                          
                                          {
                                            console.log('order.inventories.length: ',order.inventories.length),
                                            order.inventories.length > 1 ?
                                              <>  
                                                <Table.Cell>
                                                  {
                                                    order.inventories.map(
                                                        item => (
                                                          <>
                                                            {item.inventory_id}<br/>
                                                          </>
                                                        )
                                                      )
                                                  }
                                                </Table.Cell>
                                                <Table.Cell>
                                                  {
                                                    order.inventories.map(
                                                        item => (
                                                          <>
                                                            {item.quantity}<br/>
                                                          </>
                                                        )
                                                      )
                                                  }
                                                </Table.Cell>
                                              </>
                                            : 
                                              order.inventories.map(
                                                item => (
                                                  <>
                                                    <Table.Cell>{item.inventory_id}</Table.Cell>
                                                    <Table.Cell>{item.quantity}</Table.Cell>
                                                  </>
                                                )
                                              )
                                          }
                                          
                                          <Table.Cell key={i+6}>
                                            <Link to={`/report_item/${order._id}`}><Button color="red">{this.props.dashboard_segment_orders_table_but_report}</Button></Link>
                                          </Table.Cell>
                                    </Table.Row>
                                    
                                  ))
                                }
                        </Table.Body>
                        {/* <Table.Footer>
                              <Table.Row>
                                <Table.HeaderCell colSpan='12'>
                                <Link to={'/orders'}>
                                <Button color="blue" floated="right"><Icon name="search" />View more</Button>
                                </Link>
                                </Table.HeaderCell>
                              </Table.Row>
                        </Table.Footer> */}
                      </Table>
                    )
                  }
              </div>
          )
  }
}

export default OrdersDashboard