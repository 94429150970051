import React from "react";
import axios from 'axios';
import { ReactiveBase } from "@appbaseio/reactivesearch";
import { ReactiveGoogleMap } from "@appbaseio/reactivemaps";
import { Grid, Card, Image, Label, Icon, Header, Modal} from "semantic-ui-react";
import Filters from "./Filters";
import "./mapstyles.css";
import * as moment from "moment";
import 'moment/locale/bg';
import 'moment/locale/en-gb';
import HomeProduct from "../components/HomeProduct";
import no_results from "../components/images/empty/no_results.png";
import posthog from 'posthog-js'

posthog.init('phc_gtRKYeaZQMQKmTiA4taevgqvuKrL25gRcD98dPVJbZs', { api_host: 'https://eu.posthog.com' })

posthog.capture('Main Page', { property: 'value' })
//moment.locale don't work, check docs

const providers = [
  {
    label: "Google Map",
    value: "googleMap"
  },
  {
    label: "OpenStreet Map",
    value: "openstreetMap"
  }
];

export default class MapPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mapProvider: providers[0],
      latitude: 42.069325,
      longitude: 25.606698,
      loading: true,
      loadingLat: true,
      loadingLng: true,
      noProducts: true,
      noResults: false,
      selectedProduct: [],
      productClicked: false, 
      account_info: [],
      product_info: [],
      place_info: [],
      inventory_info: [],
      dist: Number
    };
    this.setProvider = this.setProvider.bind(this);
  }

  componentDidMount() {
    navigator.geolocation.getCurrentPosition(
      this.geo_lat,
      this.geo_error,
      this.geo_options
    );
    navigator.geolocation.getCurrentPosition(
      this.geo_lng,
      this.geo_error,
      this.geo_options
    );
    navigator.geolocation.getCurrentPosition(
      this.geo_success,
      this.geo_error,
      this.geo_options
    );
    this.setState({ loading: false });
  }

  calculateTimeRemaining(timestamp, id) {
    //console.log(this.props.moment_locale_prop)
    moment.locale(this.props.moment_locale_prop)
    const divided = timestamp / 1000;
    var date = moment.unix(divided)
    var displayDate= moment().isAfter(date) ? 
      (
        this.deleteItem(id)
      )
      : date.fromNow(true)+this.props.offers_time_remaining;
    return displayDate;
  }

  async deleteItem(itemid) {
    //this.setState({ openActionDelete: false });
    //console.log(itemid)
    await axios.delete(`https://api.dontwaste.me/inventories/exp/${itemid}`)
    //.then ( this.props.deleteCallback(itemid), console.log('update id: ', itemid))
    // this.setState({ loading: true });
  }

  calculatePickUpTime(timestamp) {
    moment.locale(this.props.moment_locale_prop)
    const divided = timestamp / 1000;
    var date = moment.unix(divided).format("h:mm a on dddd, MMMM Do");
    return date;
  }
  geo_lat = position => {
    this.setState({ latitude: position.coords.latitude, loadingLat: false });
    //console.log("geo_lat= ", this.state.latitude);
  };

  geo_lng = position => {
    this.setState({ longitude: position.coords.longitude, loadingLng: false });
    //console.log("geo_lng= ", this.state.longitude);
  };

  geo_success(position) {
    return (
      position.coords.latitude,
      position.coords.longitude
      //console.log("lat= ", position.coords.latitude, "lon= ", position.coords.longitude)
    );
  }

  geo_error() {
    alert(this.props.alert_geolocation);
  }

  geo_options(enableHighAccuracy, maximumAge, timeout) {
    return (
      (enableHighAccuracy = false), (maximumAge = 30000), (timeout = 27000)
    );
  }

  setProvider(mapProvider) {
    this.setState({
      mapProvider
    });
  }
  
  handleCardClick = (product) => {
    fetch(`https://api.dontwaste.me/inventories/${product.item_id}`)
          .then(res => res.json())
          .then(res => 
            this.setState({ inventory_info: res.item, product_info: res.itemProduct[0], place_info: res.itemPlace[0] },
            /* this.fetchProductInfo(res.item.product_id), */
           /*  this.fetchPlaceInfo(res.item.place_id), */
            /* this.fetchAccountInfo(res.item.account_id), */
            this._findDistance(product.location)))
          .then(() => this.setState({productClicked:true, selectedProduct: product}))
  };

  /* fetchProductInfo = (id) => {
    axios.get(`https://api.dontwaste.me/products/${id}`)
    .then((res) => (this.setState({product_info: res.data.product}), console.log('product_info: ', res.data.product)))
  }; */

  /* fetchPlaceInfo = (id) => {
    axios.get(`https://api.dontwaste.me/places/${id}`)
    .then((res) => (this.setState({place_info: res.data.place}), console.log('place_info: ', res.data.place)))
  }; */

  //Don't work, because we are using Auth0 Database, not our own Mongo in Atlas.
  /* fetchAccountInfo = (id) => {
    axios.get(`https://api.dontwaste.me/accounts/${id}`)
    .then((res) => (this.setState({ account_info: res.data.account}), console.log('account_info: ', res.data.account)))
  }; */

  _findDistance(location) {
    var lat1 = this.state.latitude;
    var lon1 = this.state.longitude;
    var lat2 = location[1];
    var lon2 = location[0];
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    dist = dist * 1.609344;
    dist = dist.toFixed(1);
    this.setState({dist: dist})
  }

  GoBack = () => {
    this.setState({productClicked: false, product_info: [], place_info: [], account_info: [], inventory_info: [] })
  };
  
  defaultQuery = function() {
    return {
      query: {
        match_all: {}
      }
    }
  };

  render() {
    const {selectedProduct, productClicked} = this.state
    const { product_info, place_info, inventory_info } = this.state
    return (
      <ReactiveBase
        app="items2"
        url="https://el.veloxoft.com:444"
        credentials="pepo:Parolata123"
        theme={{
          colors: {
            primaryColor: "#e9707d"
          }
        }}
      >
        <Grid className="home_wrapper" stackable>
          <Grid.Column width={3} className="filters_wrapper">
            <Filters 
              filters_header= {this.props.filters_header}
              filters_data_search_header= {this.props.filters_data_search_header}
              filters_data_search_pl= {this.props.filters_data_search_pl}
              filters_price_range_header= {this.props.filters_price_range_header}
              filters_price_range_currency= {this.props.filters_price_range_currency}
              filters_quantity_header= {this.props.filters_quantity_header}
              filters_date_header= {this.props.filters_date_header}
              filters_date_pl= {this.props.filters_date_pl}
              filters_deal_type_header= {this.props.filters_deal_type_header}
              filters_deal_type_personal= {this.props.filters_deal_type_personal}
              filters_deal_type_business= {this.props.filters_deal_type_business}
              filters_no_suggestions= {this.props.filters_no_suggestions}
            />
          </Grid.Column>
            <Grid.Column width={13} className="map_results_wrapper">
              <ReactiveGoogleMap
                componentId="googleMap"
                defaultMapStyle="Midnight Commander"
                title="FoodChain Map"
                dataField="location"
                size={100}
                defaultZoom={6}
                defaultRadius={10}
                unit="km"
                showMapStyles
                showMarkerClusters
                showSearchAsMove
                searchAsMove
                innerClass= {{
                  checkboxContainer: 'map_checkbox',
                  list: "map_list",
                  checkbox: "map_checkbox_other",
                  select:"map_select",
                  icon:"map_icon",
                  button:"map_button",
                  active:"map_active",
                  paginaiton: "map_pagination"
                }}
                onPopoverClick={data => {
                  return (
                    <div className="popover">
                      <div className="extra-info-container">
                        <div className="type-container info">
                          <Image src="https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/restaurants_images%2Frestaurant1.jpg?alt=media&token=65470e7d-9bac-47b6-8ccf-580f9543bbf5" 
                          size='medium' 
                          onClick={() => this.handleCardClick(data)}
                          />
                          {this.props.map_popup_place_row} {data.place_id} <br />
                          {data.quantity_available} {this.props.map_popup_qty_row}
                        </div>
                        <div className="name-container info">{data.name}</div>
                        <div className="price-container info">
                          {data.reduced_price} {this.props.filters_price_range_currency} {this.props.map_popup_price_row}
                        </div>
                      </div>
                    </div>
                  );
                }}
              autoClosePopover
                renderAllData={(
                  hits,
                  streamHits,
                  loadMore,
                  renderMap,
                  renderPagination
                ) => {
                  return(

                      <Grid className="map_results" stackable>
                        <Grid.Column width={10} className="results_wrapper">
                            <Card.Group doubling itemsPerRow={3} stackable>
                            {hits.map(data => (
                              hits.length > 0 ?
                                        <Card
                                          raised
                                          link
                                          key={data.id}
                                          className="product_card"
                                          onClick= {() => this.handleCardClick(data)}
                                        >
                                          <Image
                                            size="medium"
                                            src={data.image}
                                            ui={false}
                                            centered
                                            className="center_crop_small_card"
                                          />
                                          {console.log('data.item_exp: ', data.item_exp)}
                                          <Label attached="top right" color="red">
                                            <Icon name="hourglass end" />
                                            {this.calculateTimeRemaining(
                                              data.item_exp, data._id
                                            )}
                                          </Label>

                                          <Card.Content>
                                            <Card.Header className="card_small_header">
                                              {data.name}
                                            </Card.Header>
                                            <Card.Meta>
                                              <Icon name="globe" />
                                              {data.place_name}
                                            </Card.Meta>
                                            {/* <Card.Description>
                                              <Label basic size="tiny">
                                                {this.props.offers_area_org_price} {data.original_price} {this.props.filters_price_range_currency}
                                              </Label>
                                              <Label color="green" size="medium">
                                                {this.props.offers_area_red_price} {data.reduced_price} {this.props.filters_price_range_currency}
                                              </Label>
                                            </Card.Description> */}
                                          </Card.Content>
                                          <Card.Content extra>
                                            <p>
                                              <Icon name="tags" />
                                              {this.props.offers_area_qty} {" "}
                                              {data.quantity_available}
                                            </p>
                                            <p>
                                              <Icon name="clock" /> {this.props.offers_area_timе}{" "}
                                              {this.calculatePickUpTime(
                                                data.item_exp
                                              )}
                                            </p>
                                          </Card.Content>
                                        </Card>
                              :
                              <>
                                <Image src={no_results} size="large" centered verticalAlign="middle" className="no_results_image"/>
                                <Header as="h2" textAlign="center">{this.props.offers_area_no_res}
                                <Header.Subheader>
                                  {this.props.оffers_area_no_res_text}
                                </Header.Subheader>
                                </Header>
                              </>
                            ))}
                          </Card.Group>
                         
                        </Grid.Column>
                      <Grid.Column width={6} className="map_wrapper">
                        {renderMap()}
                      </Grid.Column>
                    </Grid>

                    )
                  }
                }

                renderData={data => ({
                label: (
                  <span
                    style={{ width: 40, display: "block", textAlign: "center" }}
                  >
                    ${data.reduced_price}
                  </span>
                )
                })}
                renderError={error => (
                  <div>
                    {this.props.map_render_error}
                    <br />
                    {this.props.map_render_error_text}
                    <br />
                    {error}
                  </div>
                )}
                react={{
                  and: [
                    "Search",
                    "Price range",
                    "Available quantity",
                    "DateSensor",
                    "DealTypes"
                  ]
                }}
              />
            </Grid.Column>
        </Grid>
        <Modal className="product_modal" open={productClicked} onClose={()=>this.GoBack()}>
          <HomeProduct
            //console={console.log('inventory_info: ',inventory_info)}
            product={product_info}
            inventory={inventory_info}
            productClicked={productClicked}
            item={selectedProduct}
            place={place_info}
            dist={this.state.dist}
            handleGoBack={this.receiveGoBack}
            offers_time_remaining={this.props.offers_time_remaining}
            offers_area_org_price={this.props.offers_area_org_price}
            offers_area_red_price={this.props.offers_area_red_price}
            offers_area_qty={this.props.offers_area_qty}
            offers_area_timе={this.props.offers_area_timе}
            offers_area_no_res={this.props.offers_area_no_res}
            оffers_area_no_res_text={this.props.оffers_area_no_res_text}
            offers_area_time={this.props.offers_area_time}
            filters_price_range_currency={this.props.filters_price_range_currency}
            product_detail_qty_choose= {this.props.product_detail_qty_choose}
            product_detail_qty_available= {this.props.product_detail_qty_available}
            product_detail_cart_but_text= {this.props.product_detail_cart_but_text}
            product_detail_cart_sold_out= {this.props.product_detail_cart_sold_out}
            product_detail_report= {this.props.product_detail_report}
            product_detail_env_header= {this.props.product_detail_env_header}
            product_detail_env_co2= {this.props.product_detail_env_co2}
            product_detail_env_water= {this.props.product_detail_env_water}
            product_detail_away= {this.props.product_detail_away}
            product_detail_see_all= {this.props.product_detail_see_all}
            product_detail_buy_before= {this.props.product_detail_buy_before}
            product_detail_pick_up_before= {this.props.product_detail_pick_up_before}
            product_detail_desc= {this.props.product_detail_desc}
            product_detail_aller={this.props.product_detail_aller}
            product_detail_ingr={this.props.product_detail_ingr}
            product_detail_cat_type= {this.props.product_detail_cat_type}
            product_detail_delivery= {this.props.product_detail_delivery}
            product_detail_pick_by= {this.props.product_detail_pick_by}
            product_detail_sold_by= {this.props.product_detail_sold_by}
            product_detail_vendor_desc= {this.props.product_detail_vendor_desc}
            product_detail_place= {this.props.product_detail_place}
            product_detail_but_show={this.props.product_detail_but_show}
            product_detail_but_show_more= {this.props.product_detail_but_show_more}
            product_detail_but_show_less= {this.props.product_detail_but_show_less}
            filters_deal_type_personal= {this.props.filters_deal_type_personal}
            filters_deal_type_business= {this.props.filters_deal_type_business}
            moment_locale_prop={this.props.moment_locale_prop}
          />
        </Modal>
        {/* {console.log('clicked state in product: ', productClicked)} */}
      </ReactiveBase>
    );
  }
}
