import React from 'react'
import { Table, Header, Image } from 'semantic-ui-react'

const HelpPage = () => (
          <div>
            <Header as='h2' style={{color: '#19263D'}}>FoodChain - Preventing food waste. It's not a waste yet!</Header>
            <Table>
              <Table.Row>
                <Table.Cell style={{textAlign: '-moz-center'}}>
                  <Image src="https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/images%2FEU-1.png?alt=media&token=7c6488ac-79b4-4a4d-8842-dd275f6d3df0" />
                </Table.Cell>
                <Table.Cell style={{textAlign: '-moz-center'}}>
                  <Image src="https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/images%2FEU-2.png?alt=media&token=567f8d66-95f7-467d-8d9b-11329a4476b4" />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Header as="h4">
                    Проект: BG16RFOP002-1.002-0067-C01 „Разработване на иновативно маркетингово компютърно и мобилно приложение целящо намаляване на излишната храна".
                  </Header>
                </Table.Cell>
                <Table.Cell>
                  <Header as="h5">
                    Главна цел: Повишаване на иновационната дейност на "Вилоксофт" ООД в тематична област "Нови технологии в креативни и рекреативни индустрии", направление "Компютърни и мобилни приложения и игри с образователен, маркетинг и/или развлекателен характер" от ИСИС, чрез разработването на продуктова иновация в сферата на услугите, а именно маркетинг приложение целящо намаляване на излишната храна.
                  </Header>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Header as="h4">
                    Бенефициент: “Вилоксофт" ООД 
                  </Header>
                </Table.Cell>
                <Table.Cell>
                  <Header as="h5">
                    Обща стойност: 397 804.94 лв., от които 304 320.79 лв. европейско и 53 703.65лв. национално съфинансиране<br />Начало: 09.05.2017 г.<br />Край: 09.05.2019 г.
                  </Header>
                </Table.Cell>
              </Table.Row>
            </Table>
          </div>
)

export default HelpPage
