import React from 'react';
import PropTypes from 'prop-types';
import { connect, ReactReduxContext } from 'react-redux';
import { addToCart, removeFromCart } from '../reducers/productActions';
import { getVisibleProducts } from '../reducers/productReducer';
import ProductsList from '../components/Cart/ProductList';
import { Grid, Icon, Button } from 'semantic-ui-react';

const ProductsContainerSingleItem = ({ products, addToCart, removeFromCart, productId, qty, product_detail_qty_choose, product_detail_qty_available }) => {
  const saveState = (state) => {
    //console.log(state)
    try {
      const serializedState = JSON.stringify(state.cart);
      //console.log('serializedStateSaveState+++++++: ', serializedState);
      localStorage.setItem('cart', serializedState);
    } catch {
      // ignore write errors
    }
  };

  const getCardAddedIds = (state, product) => {
    //console.log('getCardAddedIds',state)
    /* if (!state.cart.addedIds.length) {
      return (
                  <Grid style={{"display":"block"}}>
                      <Grid.Column width={4}>
                        <Button icon disabled={qty > product.quantity_available ? false : true} onClick={() => (removeFromCart(product._id, product.place_id, product.reduced_price, product.expiry_date, product.place_name, product.product_name), saveState(state))}>
                          <Icon name='minus' />
                        </Button>
                      </Grid.Column>
                      <Grid.Column width={4}>
                        <Button icon disabled={product.quantity_available > 0 ? '' : 'disabled'} onClick={() => (addToCart(product._id, product.place_id, product.reduced_price, product.expiry_date, product.place_name, product.product_name), saveState(state))}>
                          <Icon name='plus' />
                        </Button>
                      </Grid.Column>
                  </Grid>
      )
    } else return (
      <p>Only one item in cart is allowed!</p>
    ) */
    return (
      <Grid style={{"display":"block"}}>
          <Grid.Column width={4}>
            <Button icon disabled={qty > product.quantity_available ? false : true} onClick={() => (removeFromCart(product._id, product.place_id, product.reduced_price, product.expiry_date, product.place_name, product.product_name), saveState(state))}>
              <Icon name='minus' />
            </Button>
          </Grid.Column>
          <Grid.Column width={4}>
            <Button icon disabled={product.quantity_available > 0 ? '' : 'disabled'} onClick={() => (addToCart(product._id, product.place_id, product.reduced_price, product.expiry_date, product.place_name, product.product_name), saveState(state))}>
              <Icon name='plus' />
            </Button>
          </Grid.Column>
      </Grid>
    )
  }
 
  return (
    <ProductsList name="Products">
          {products.map(product =>
            productId === product._id ?
              <div>
                <p>{product_detail_qty_choose} ({product.quantity_available} {product_detail_qty_available}):</p>
                <ReactReduxContext.Consumer>
                {({ store }) => (
                  getCardAddedIds(store.getState(), product)
                  
                  
                  
                )}
                </ReactReduxContext.Consumer>
              </div>
            : null
          )
        }
      
    </ProductsList>
  )
}

ProductsContainerSingleItem.propTypes = {
  products: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    place_id: PropTypes.string.isRequired,
    reduced_price: PropTypes.number.isRequired,
    quantity_available: PropTypes.number.isRequired
  }).isRequired,
  onAddToCartClicked: PropTypes.func.isRequired
}
const mapStateToProps = (state, props) => ({
  //products: state.products.items
  products: getVisibleProducts(state),
  //product: getProduct(state, _id),
  /* productId: this.props.productId,
  productName: this.props.productName */
})

export default connect(
  mapStateToProps,
  { addToCart, removeFromCart }
)(ProductsContainerSingleItem)
