import React, { Component } from "react";
import MapPageItemsDetails from '../components/MapPageItemsDetails';
import "./home.css";

export default class Home extends Component {

  render() {
    return (
      <div>
        <MapPageItemsDetails />
      </div>
    );
  }
}
