import React from 'react';
import { useAuth0 } from "../../react-auth0-spa";
import LocalizedStrings from 'react-localization';
import { connect, ReactReduxContext } from 'react-redux';
import CheckoutFormClass from './CheckoutFromClass';
import { setCartToPaid } from '../../reducers/productActions';
import {ElementsConsumer} from '@stripe/react-stripe-js';

const InjectedCheckoutForm = ({setCartToPaid}) => {
    const { user } = useAuth0();
    let total2 = [0];
    const reducer = (accumulator, currentValue) => accumulator + currentValue;

    const strings = new LocalizedStrings({
    en:{
        stripe_enter_card_details: "Please enter your card details",
        stripe_but_text: "Make Payment",
        stripe_success_pay_thank_you: "Thank you! Please click Order Now button.",
        notifyStripePayment: "Stripe successful payment request",
        notifyStripeCheckPayment: "Stripe Checking payment...",
        notifyStripeErrorInfo: "Problem, please call Stripe Support.",
        notifyStripeErrorInfo2: "Payment was processed, but there is a problem with your account. Please call Stripe Support.",
        notifyStripeCheckPaymentDone: "Stripe Payment is done, thank you!",
        notifyError: "Something went wrong!",

    },
    bg: {
        stripe_enter_card_details: "Моля, въведете данните на вашата карта",
        stripe_but_text: "Направи плащане",
        stripe_success_pay_thank_you: "Благодарим Ви! Моля, натиснете бутона за поръчка.",
        notifyStripePayment: "Успешно плащане със Stripe",
        notifyStripeCheckPayment: "Проверяване на плащане със Stripe...",
        notifyStripeErrorInfo: "Възникна проблем, моля свържете с FoodChain поддръжка.",
        notifyStripeErrorInfo2: "Плащането беше заявено, но има проблем с вашия банков акаунт. Моля, свържете с вашата банка.",
        notifyStripeCheckPaymentDone: "Stripe Payment is done, thank you!",
        notifyError: "Нещо се обърка!",
    }
   });

   const _setLanguage = () => {
    //console.log(lang)
    strings.setLanguage(user["https://fc-demo/language"])
  }

  _setLanguage();

    const loadState = () => {
        //console.log('loadState: ', loadState)
        try {
            const serializedState = localStorage.getItem('cart');
            //console.log('serializedStateLoadState: ', serializedState)
            if (serializedState === null) {
            return undefined;
            }
            return JSON.parse(serializedState);
        } catch (err) {
            return undefined;
        }
    };

    const loadStateTotal = () => {
        //console.log('loadState: ', loadState)
        try {
                const serializedState = localStorage.getItem('cart');
                //console.log('serializedStateLoadState: ', serializedState)
                if (serializedState === null) {
                return false;
                } 
                return JSON.parse(serializedState);
        } 
            catch (err) {
            return undefined;
            }
    };

    const totalLocalStorage = loadStateTotal() ? (
        Object.entries(loadStateTotal().quantityById).map(item => {
            //console.log('item_price: ', item)
            //console.log(item[1].item_price)
            total2.push(item[1].item_price*item[1].quantity);
            //console.log('total2', total2.reduce(reducer).toFixed(2))
        }
        )
    ) : null

    return (         
        <ElementsConsumer>
        {({stripe, elements}) => (
            <ReactReduxContext.Consumer>
            {({ store }) => (
                <CheckoutFormClass 
                    stripe={stripe} 
                    elements={elements} 
                    storeRedux={store.getState()}
                    totalLocalStorageToStripe={total2.reduce(reducer).toFixed(2)}
                    cartLocalStorageToStripe={loadState()}
                    user={user}
                    setCartToPaid={() => setCartToPaid()}
                    stripe_enter_card_details= {strings.stripe_enter_card_details}
                    stripe_but_text= {strings.stripe_but_text}
                    stripe_success_pay_thank_you= {strings.stripe_success_pay_thank_you}
                />
            )}
        </ReactReduxContext.Consumer>
        )}
        </ElementsConsumer>
        
    );
}

export default connect('', { setCartToPaid })(InjectedCheckoutForm)
