import React, { Component } from "react";
import axios from "axios";
import { Icon, Table, Button, Modal, Segment, Header, Form, Dimmer, Loader, Image } from "semantic-ui-react";
import food_bag from "./food_bag.svg";

const unitsDropDown = [
  { key: 'gr', text: 'gr', value: 'gr' },
  { key: 'kg', text: 'kg', value: 'kg' },
  { key: 'l', text: 'liter', value: 'liter' },
  { key: 'ml', text: 'ml', value: 'ml' },
];

export default class ProductsTableDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      products: [],
      open: false,
      open1: false,
      newProductName: "",
      newProductAller: "",
      newProductIngredients: "",
      newProductImage: food_bag,
      newProductAgreeTerms: false,
      editProductId: "",
      editProductName: "",
      newProductUnits: "",
      newProductEco: "Eco 1, Eco 2"
    };
  }  

  getProductsDataFromDbByAccountId = async (userid, user_type) => {
    const findPipe = userid.indexOf("|");
    const id = userid.substr(findPipe+1);
    let url = user_type === "ADMIN" ? 'https://api.dontwaste.me/products/' : `https://api.dontwaste.me/products/accounts/${id}`
    if (this.state.loading) {
      await axios.get(`${url}`, {})
        .then(products => (this.setState({ products: products.data.products, loading: false})) )
        .catch(err => {console.log(err);});
    }
  }

  async saveProductToDB(userid, name, allergens, ingredients, units) {
    const findPipe = userid.indexOf("|");
    const id = userid.substr(findPipe+1);
    this.setState({ open: false });
    this.setState({ newProductAgreeTerms: false });
    await axios.post("https://api.dontwaste.me/products/", {
      account_id: id,
      name: name,
      image: this.state.newProductImage,
      allergens: allergens,
      eco: this.state.newProductEco,
      ingredients: ingredients,
      units: units
    },
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );

    this.setState({ loading: true });
  };

  async editProduct(name, ingredients, allergens, units) {
    //console.log(this.state.editProductId)
    this.setState({ open: false, open1: false })
    await axios.put(`https://api.dontwaste.me/products/${this.state.editProductId}`, {
      name: name,
      allergens: allergens,
      ingredients: ingredients,
      units: units
    });
    this.setState({ loading: true });
  }

  closeConfigShow = (closeOnEscape, closeOnDimmerClick) => () => {
    this.setState({ closeOnEscape, closeOnDimmerClick, open: true })
  }

  closeConfigShow1 = (closeOnEscape1, closeOnDimmerClick1, id, name, ingredients, allergens, units) => () => {
    //console.log(id)
    this.setState({ closeOnEscape1, closeOnDimmerClick1, editProductId: id, editProductName: name, newProductIngredients: ingredients, newProductAller: allergens, newProductUnits: units, open1: true })
  }

  handleChangeCheckBox = e => this.setState(({ newProductAgreeTerms }) => ({ newProductAgreeTerms: !newProductAgreeTerms }))

  handleChange = (e, { value }) => this.setState({ newProductUnits: value })
  
  close = () => this.setState({ open: false, open1: false })

  render() {
    const {
      products,
      open,
      closeOnEscape,
      closeOnDimmerClick,
      loading,
      closeOnEscape1,
      closeOnDimmerClick1,
      open1
    } = this.state;
    
    return (
          <div>
            <Dimmer active={loading} inverted data={this.getProductsDataFromDbByAccountId(this.props.userid, this.props.user_type)}>
              <Loader inverted content="Loading" />
            </Dimmer>
            <Table basic="very" celled selectable style={{ marginTop: "0px" }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell textAlign="center" >{this.props.dashboard_segment_products_image}</Table.HeaderCell>
                  <Table.HeaderCell>{this.props.dashboard_segment_products_name}</Table.HeaderCell>
                  <Table.HeaderCell>{this.props.dashboard_segment_products_desc}</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {products.length <= 0 ? (
                  <Table.Row>
                    <Table.Cell>{this.props.dashboard_segment_products_no_products}</Table.Cell>
                  </Table.Row>
                ) : (
                  Object.values(products).map((product, i) =>
                    i <= 10 ? (
                      product.isMarkedForDeletion !== true ? (
                        <Table.Row key={product._id} disabled={false}>
                          <Table.Cell width = "3" key={i + 2} style = {{display: 'flex', justifyContent: "center", marginLeft: 'auto', marginRight: 'auto'}}>
                            <Image
                              src={
                                product.image
                                  ? product.image
                                  : this.state.newProductImage
                              }
                              size="mini"
                            />
                          </Table.Cell>
                          <Table.Cell width = "5" key={i + 3}>{product.name}</Table.Cell>
                          <Table.Cell width = "5"  key={i + 4}>
                            {product.description
                              ? product.description
                              : "Product description"}
                          </Table.Cell>
                          <Table.Cell width = "3" key={i + 5}>
                            <Modal
                              trigger={
                                <Button
                                  onClick={this.closeConfigShow1(
                                    true,
                                    false,
                                    product._id,
                                    product.name,
                                    product.ingredients,
                                    product.allergens,
                                    product.units
                                  )}
                                >
                                  {this.props.dashboard_segment_products_but_edit}
                                </Button>
                              }
                              centered={false}
                              open={open1}
                              closeOnEscape={closeOnEscape1}
                              closeOnDimmerClick={closeOnDimmerClick1}
                              onClose={this.close}
                              closeIcon
                            >
                              <Modal.Header>Edit product</Modal.Header>
                              <Modal.Content image>
                                <Segment placeholder style={{ margin: "2%" }}>
                                  <Header icon>
                                    <Icon name="file image outline" />
                                    Upload image for your product.
                                  </Header>
                                  <Button primary>Add Image</Button>
                                </Segment>
                                <Modal.Description>
                                  <Header>Product details</Header>
                                  <Form>
                                    <Form.Group unstackable widths={2}>
                                      <Form.Input
                                        label="Product name"
                                        placeholder={this.state.editProductName}
                                        type="text"
                                        onChange={e =>
                                          this.setState({
                                            newProductName: e.target.value
                                          })
                                        }
                                      />
                                      <Form.Input
                                        label="Product Ingredients"
                                        placeholder={
                                          this.state.newProductIngredients
                                        }
                                        type="text"
                                        onChange={e =>
                                          this.setState({
                                            newProductIngredients:
                                              e.target.value
                                          })
                                        }
                                      />
                                      <Form.Input
                                        label="Product Allergens"
                                        placeholder={this.state.newProductAller}
                                        type="text"
                                        onChange={e =>
                                          this.setState({
                                            newProductAller: e.target.value
                                          })
                                        }
                                      />
                                      <Form.Input
                                        label="Product Units"
                                        placeholder={this.state.newProductUnits}
                                        type="text"
                                        onChange={e =>
                                          this.setState({
                                            newProductUnits: e.target.value
                                          })
                                        }
                                      />
                                    </Form.Group>
                                    <Form.Checkbox
                                      label="I agree to the Terms and Conditions"
                                      onChange={this.handleChangeCheckBox}
                                    />
                                    <Modal
                                      trigger={
                                        <Button
                                          type="submit"
                                          secondary
                                          disabled={
                                            !this.state.newProductAgreeTerms
                                          }
                                        >
                                          Update product
                                        </Button>
                                      }
                                      basic
                                      size="small"
                                      closeOnEscape={false}
                                      closeOnDimmerClick={false}
                                      onClose={this.close}
                                    >
                                      <Header
                                        icon="birthday cake"
                                        content="Adding new product"
                                      />
                                      <Modal.Content>
                                        <p>
                                          Are you ready to update the product?
                                        </p>
                                      </Modal.Content>
                                      <Modal.Actions>
                                        <Button
                                          basic
                                          color="red"
                                          inverted
                                          onClick={this.close}
                                        >
                                          <Icon name="remove" /> No
                                        </Button>
                                        <Button
                                          color="green"
                                          inverted
                                          onClick={() =>
                                            this.editProduct(
                                              this.state.newProductName,
                                              this.state.newProductIngredients,
                                              this.state.newProductAller,
                                              this.state.newProductUnits
                                            )
                                          }
                                        >
                                          <Icon name="checkmark" /> Yes
                                        </Button>
                                      </Modal.Actions>
                                    </Modal>
                                  </Form>
                                </Modal.Description>
                              </Modal.Content>
                            </Modal>
                          </Table.Cell>
                        </Table.Row>
                      ) : null
                    ) : null
                  )
                )}
              </Table.Body>

              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="12">
                    <Modal
                      trigger={
                        <Button
                          secondary
                          onClick={this.closeConfigShow(true, false)}
                        >
                          <Icon name="plus" /> {this.props.dashboard_segment_products_but_add}
                        </Button>
                        
                      }
                      centered={false}
                      open={open}
                      closeOnEscape={closeOnEscape}
                      closeOnDimmerClick={closeOnDimmerClick}
                      onClose={this.close}
                      closeIcon
                    >
                      <Modal.Header>Add new Product</Modal.Header>
                      <Modal.Content image>
                        <Segment placeholder style={{ margin: "2%" }}>
                          <Header icon>
                            <Icon name="file image outline" />
                            Upload image for your product.
                          </Header>
                          <Button primary>Add Image</Button>
                        </Segment>
                        <Modal.Description>
                          <Header>Product details</Header>
                          <Form>
                            <Form.Group unstackable widths={2}>
                              <Form.Input
                                label="Product name"
                                placeholder="Product name"
                                type="text"
                                onChange={e =>
                                  this.setState({
                                    newProductName: e.target.value
                                  })
                                }
                              />
                              <Form.Input
                                label="Ingredients"
                                placeholder="Ingredients"
                                type="text"
                                onChange={e =>
                                  this.setState({
                                    newProductIngredients: e.target.value
                                  })
                                }
                              />
                              <Form.Input
                                label="Allergens"
                                placeholder="Allergens"
                                type="text"
                                onChange={e =>
                                  this.setState({
                                    newProductAller: e.target.value
                                  })
                                }
                              />
                              <Form.Select
                                fluid
                                selection
                                label="Units"
                                options={unitsDropDown}
                                placeholder="Units"
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                            <Form.Checkbox
                              label="I agree to the Terms and Conditions"
                              onChange={this.handleChangeCheckBox}
                            />
                            <Modal
                              trigger={
                                <Button
                                  type="submit"
                                  secondary
                                  disabled={!this.state.newProductAgreeTerms}
                                >
                                  Add product
                                </Button>
                              }
                              basic
                              size="small"
                              closeOnEscape={false}
                              closeOnDimmerClick={false}
                              onClose={this.close}
                            >
                              <Header
                                icon="birthday cake"
                                content="Adding new product"
                              />
                              <Modal.Content>
                                <p>
                                  Are you ready to add your new product to your
                                  Company?
                                </p>
                              </Modal.Content>
                              <Modal.Actions>
                                <Button
                                  basic
                                  color="red"
                                  inverted
                                  onClick={this.close}
                                >
                                  <Icon name="remove" /> No
                                </Button>
                                <Button
                                  color="green"
                                  inverted
                                  onClick={() =>
                                    this.saveProductToDB(
                                      this.props.userid,
                                      this.state.newProductName,
                                      this.state.newProductAller,
                                      this.state.newProductIngredients,
                                      this.state.newProductUnits
                                    )
                                  }
                                >
                                  <Icon name="checkmark" /> Yes
                                </Button>
                              </Modal.Actions>
                            </Modal>
                          </Form>
                        </Modal.Description>
                      </Modal.Content>
                    </Modal>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </div>
    );
  }
}
