/* eslint-disable max-len */
// For this screen we need to consider pending orders, completed orders, inventories
import React, { Component } from 'react'
import { Segment, Statistic, Dimmer, Loader, Grid, Item, Image, Header } from 'semantic-ui-react'
import axios from 'axios';
import { css } from "emotion";
import OrdersTableDashboard from "./../Orders/ordersDashboardTable";
import FoodDataRow from './../Purchases/FoodDataRow'
import dog from "./../avatars/dog.png"
import cat from "./../avatars/cat.png"
import chick from "./../avatars/chick.png"
import rabbit from "./../avatars/rabbit.png"
import fish from "./../avatars/fish.png"
import parrot from "./../avatars/parrot.png"
import revenue from "./../images/dashboard/graph.svg"

var avatars = [dog, cat, chick, rabbit, fish, parrot];

const ui = css`
    .ui
    { 
    .statistic {
        display: flex;
        align-items: center;
    .label {
        margin-bottom: 1em;
    }
}}
`;

export default class ContainerExampleContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            orders: [],
            accounts: [],
            loading: true,
            loadingProducts: true,
            loadingOrders: true,
            loadingAccounts: true,
            avatar: null,
            sumValue: [],
            sumClients: [],
        }
    }

    componentDidMount() {
        this.setState({ avatar: this.chooseRandom(avatars) })
    }

    chooseRandom(list) {
        var rand = list[Math.floor(Math.random() * list.length)];
        return rand
    }

    getData(userid) {
        const findPipe = userid.indexOf("|");
        const id = userid.substr(findPipe + 1);
        if (this.state.loading) {
            this.getOrdersByToken(id)
            //this.getProductsDataFromDbByAccountId(userid)
            this.setState({ loading: false })
        }
    };

    getProductsDataFromDbByAccountId = (userid) => {
        axios.get(`https://api.dontwaste.me/products/accounts/${userid}`, {})
            .then(products => { this.setState({ products: products.data.products }) })
            .catch(err => { console.log(err); });
    };

    getOrdersByToken = (userid) => {
        axios.get(`https://api.dontwaste.me/orders/accounts/${userid}`, {})
            .then(orders => (this.setState({ orders: orders.data.orders }), console.log('orders: ', orders.data.orders)))
            .catch(err => { console.log(err); });
    };

    render() {
        const { loading, avatar } = this.state
        return (
            <div className={ui} >
                <Dimmer active={loading} inverted data={this.getData(this.props.user.sub)} console={console.log('Refresh in Dimmer')}>
                    <Loader inverted>Loading</Loader>
                </Dimmer>
                {console.log('Refresh outside Dimmer')}
                <Item style={{ display: 'flex', marginTop: "2%", marginBottom: '3%' }}>
                    <Item.Image size='tiny' src={avatar} style={{ marginRight: "3%" }} />

                    <Item.Content>
                        <Item.Header as='h2'>Welcome back, {this.props.user.nickname ? this.props.user.nickname : 'FoodChain Hero'} </Item.Header>
                        <Item.Description>
                            This is your FoodChain dashboard. View your purchases and food savings in a glance!
                                </Item.Description>
                    </Item.Content>
                </Item>

                <Grid stackable style={{ textAlign: 'center' }}>
                    <FoodDataRow userid={this.props.user.sub} />
                    <Grid.Row stretched>
                        <Grid.Column width={16}>
                            <Segment raised>
                                <Header as="h3" textAlign='left' >
                                    <Header.Content>
                                        Purchases
                                            <Header.Subheader>View your latest purchases and let us know if there is a problem</Header.Subheader>
                                    </Header.Content>
                                </Header>
                                <OrdersTableDashboard userid={this.props.user.sub} />
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

            </div>


        )
    }
}
