import React, { useEffect }  from "react";

import GMap from "react-rainbow-components/components/GMap";
import MapMarker from "react-rainbow-components/components/MapMarker";

const RainbowMap = ({ latitude, longitude, zoom, label, description }) => {
  const [local_latitude, setLatitude] = React.useState()
  const [local_longitude, setLongitude] = React.useState()
  const [local_zoom, setZoom] = React.useState()
  const [local_label, setLabel] = React.useState('')
  const [local_description, setDescription] = React.useState('')
    useEffect(()=>{
        console.log('latitude in rainbow', label)
        setLatitude(latitude)
        setLongitude(longitude)
        setZoom(zoom)
        setDescription(description)
        setLabel(label)
    })
    return (
        <GMap
            apiKey={"AIzaSyDfeHv6QM9PqtzE_MM5O_X81ZUjwU_jR0Q"}
            zoom={zoom}
            latitude={latitude}
            longitude={longitude}
        >
            <MapMarker
                latitude={latitude}
                longitude={longitude}
                label={label}
                description={description}
            />
        </GMap>)
}

export default RainbowMap