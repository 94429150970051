import { 
    ADD_TO_CART,
    REMOVE_FROM_CART,
    SUBTRACT_FROM_CART,
    CLEAR_CART,
    CHECKOUT_REQUEST,
    CHECKOUT_FAILURE,
    CHOOSE_PAYMENT_PROVIDER,
    CARD_PAID_SUCCESSFULLY,
    SET_LANGUAGE
} from './productActions'

const initialState = {
    addedIds: [],
    quantityById: {},
    addedIds_placeIds: [],
    isCartPaid: 'no',
    payment_provider: 'none',
    language: 'en'
  }

  export const PaymentProvider = (state = initialState.payment_provider, action) => {
    switch (action.type) {
      case CHOOSE_PAYMENT_PROVIDER:
        return action.payload
      default:
        return state
    }
  }

  export const setLanguage = (state = initialState.language, action) => {
    switch (action.type) {
      case SET_LANGUAGE:
        return action.payload
      default:
        return state
    }
  }

  //it is imported in the rootReducer too, so it has two copies in the state, check console
  export const isCartPaid = ( state = initialState.isCartPaid, action ) => {
    switch (action.type) {
      case CARD_PAID_SUCCESSFULLY:
        return action.payload;
      default:
        return state;
    }
  }

  const addedIds = (state = initialState.addedIds, action) => {
    switch (action.type) {
      case ADD_TO_CART:
        if (state.indexOf(action.productId) !== -1) {
          return state
        }
        return [ ...state, action.productId ]
      case REMOVE_FROM_CART:
          if (state.indexOf(action.productId) !== -1) {
            state.splice(action.productId, 1)
            return state
          }
          return state
      default:
        return state
    }
  }

  const addedIds_placeIds = (state = initialState.addedIds_placeIds, action) => {
    switch (action.type) {
      case ADD_TO_CART:
        if (state.indexOf(action.placeId) !== -1) {
          return state
        }
        return [ ...state, action.placeId ]
      case REMOVE_FROM_CART:
          if (state.indexOf(action.placeId) !== -1) {
            state.splice(action.placeId, 1)
            return state
          }
          return state
      default:
        return state
    }
  }
  
  const quantityById = (state = initialState.quantityById, action) => {
    const { productId, placeId, itemPrice, expDate, placeName, productName } = action
    switch (action.type) {
      case ADD_TO_CART:
        
        return { ...state,
          [productId]: 
            {
              "quantity": !state[productId] ? 1 : state[productId].quantity + 1, 
              "place_id": placeId, 
              "item_price": itemPrice, 
              "exp_date": expDate,
              "place_name": placeName,
              "product_name": productName
            }
          
        }
      case SUBTRACT_FROM_CART:
        return { ...state,
          [productId]: 
            {
              "quantity": state[productId].quantity - 1, 
              "place_id": placeId, 
              "item_price": itemPrice, 
              "exp_date": expDate,
              "place_name": placeName,
              "product_name": productName
            }
          
        }   
      case REMOVE_FROM_CART:
          console.log('state: ',state)
          console.log('state[action.productId]: ',state[action.productId])
          console.log('action:', action)
          console.log('action.productId: ',action.productId)
          console.log('typeof(state): ',typeof(state));
          delete state[action.productId]
          console.log('state after delete: ',state)
          return state
          //return state.filter(({ id }) => id !== action.productId);
      default:
        return state
    }
  }
  
  export const getQuantity = (state, productId) => {
    //console.log('state.quantityById[productId].quantity', state.quantityById[productId].quantity)
    if (state.quantityById[productId] === undefined) {
      //console.log('undefined')
      return 0
    }
    //console.log('getQuantity productId', productId)
    //console.log('state.quantityById[productId].quantity', state.quantityById[productId].quantity)
    return state.quantityById[productId].quantity || 0
  }

    
  
  export const getAddedIds = state => state.addedIds

  export const getAddedIds_placeIds = state => state.addedIds_placeIds

  const cart = (state = [], action) => {
    switch (action.type) {
      case CHECKOUT_REQUEST:
        return initialState
      case CLEAR_CART:
        return initialState
      case CHECKOUT_FAILURE:
        return action.cart
      default:
        return {
          addedIds: addedIds(state.addedIds, action),
          quantityById: quantityById(state.quantityById, action),
          addedIds_placeIds: addedIds_placeIds(state.addedIds_placeIds, action),
          isCartPaid: isCartPaid(state.isCartPaid, action)
        }
    }
  }

export default cart
