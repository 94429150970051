import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { Icon, Button, Grid, Statistic, Modal, Segment, Header, Form, Dimmer, Loader, Card, List, Placeholder, Image, Label, Item, TextArea, Progress, Divider } from 'semantic-ui-react';
import LocalizedStrings from 'react-localization';
import firebase from "firebase";
import Autocomplete from 'react-google-autocomplete';
import Geocode from 'react-geocode';
import CustomUploadButton from 'react-firebase-file-uploader/lib/CustomUploadButton';
import store from "../images/dashboard/store.svg";
import no_people from "../images/empty/no_people.png";
import "./styles.css";
//const MAX_SIZE = 2000000;
const GOOGLE_KEY = "AIzaSyDfeHv6QM9PqtzE_MM5O_X81ZUjwU_jR0Q"
export default class StoresPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      places: [],
      open: false,
      open1: false,
      confirmDelete: false,
      intervalIsSet: false,
      loading: true,
      isUploading: false,
      progress: 0,
      uploadError: '',
      loadingFirstTime: true,
      selectedPlaceId: [],
      openActionDelete: false,
      newStoreName: '',
      newStoreAddress: {
        coordinates: [],
        id: '',
        type: 'Point'
      },
      placeLat: '',
      placeLng: '',
      newStoreContactPerson: '',
      newStorePhoneNumber: '',
      newStoreImage: '',
      newStoreAgreeWithTerms: false,
      editPlaceId: '',
      editPlaceName: '',
      newStoreImageURL: 'https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/places%2Fmixkit-freshly-picked-lemons-391-desktop-wallpaper.png?alt=media&token=fcca43fd-1f93-46d7-b2bc-4fae0473a598',
      newStoreImageName: 'default',
      newStoreDescription: '',
      newPlaceWorkDaysDropDown: [
        {
          key: 'WEEKDAYS',
          text: 'Weekdays',
          value: 'WEEKDAYS'
        },
        {
          key: 'WEEKEND',
          text: 'Weekend',
          value: 'WEEKEND'
        },
        {
          key: 'ALLDAYS',
          text: 'All days',
          value: 'ALLDAYS'
        }
      ],
      newPlaceWorkDaysDropDownBG: [
        {
          key: 'WEEKDAYS',
          text: 'Седмични дни',
          value: 'WEEKDAYS'
        },
        {
          key: 'WEEKEND',
          text: 'Събота и Неделя',
          value: 'WEEKEND'
        },
        {
          key: 'ALLDAYS',
          text: 'Всички дни',
          value: 'ALLDAYS'
        }
      ],
      newPlaceWorkHoursDropDown: [
        {
          key: '24hours',
          text: '24 Hours',
          value: '24hours'
        },
        {
          key: '12hours',
          text: '12 Hours',
          value: '12hours'
        },
        {
          key: '8hours',
          text: '8 Hours',
          value: '8hours'
        }
      ],
      newPlaceWorkHoursDropDownBG: [
        {
          key: '24hours',
          text: '24 Часа',
          value: '24hours'
        },
        {
          key: '12hours',
          text: '12 Часа',
          value: '12hours'
        },
        {
          key: '8hours',
          text: '8 Часа',
          value: '8hours'
        }
      ],
      newPlaceWorkDays: '',
      newPlaceWorkTime: '',
      user_geo_lat: Number,
      user_geo_lng: Number,
    };
    this.deletePlace = this.deletePlace.bind(this);
  }

  componentDidMount() {
    navigator.geolocation.getCurrentPosition(
      this.geo_lat,
      this.geo_error,
      this.geo_options
    );
    navigator.geolocation.getCurrentPosition(
      this.geo_lng,
      this.geo_error,
      this.geo_options
    );
    navigator.geolocation.getCurrentPosition(
      this.geo_success,
      this.geo_error,
      this.geo_options
    );
  }

  strings = new LocalizedStrings({
    en:{
      header: "Places",
      header_desc: "Do you own a food truck? Simply change the location associated with your store and you can start saving food wherever you are!",
      header_desc_extra: "These are all of the stores that belong to your Company.",
      pl_box: "Places",
      pl_box_s: "Place",
      no_pl: "You have no places!",
      but_edit: "Edit",
      but_del: "Delete",
      but_add: "Add Store",
      add_header: "Add new store",
      pl_det: "Place details",
      store_name: "Store name",
      store_name_plh: "Store name",
      cont_per: "Contact person",
      cont_per_plh: "Contact person",
      ph_num: "Phone number",
      ph_num_plh: "0888 777 888",
      pl_desc: "Place description",
      pl_desc_plh: "Describe your place with a couple of sentences.",
      pl_worktime_plh: "Working time",
      pl_worktime_h_plh: "Working hours",
      location: "Location",
      location_plh: "Enter a location",
      image_size: "2MB max upload size",
      image_but: "Upload an image of your place",
      upload_successful: "Upload successful",
      longitude: "Longitude",
      latitude: "Latitude",
      submit_changes: "Submit changes",
      are_you_sure_del: "Are you sure you want to delete this place?",
      no: "No",
      yes: "Yes",
      notifyFirstPlaceCreated:"Success! By default Your First Place is created.",
      notifyPlaceCreated:"Success! Your Place is created.",
      notifyPlaceUpdate:"Success! Your Place is updated.",
      notifyPlaceDeleted:"Place DELETED!",
      notifyError:"Something went wrong!",
      worktime_days_weekdays: 'Weekdays',
      worktime_days_weekend: 'Weekend',
      worktime_days_alldays: 'All days',
      worktime_hours_24hours: '24 hours',
      worktime_hours_12hours: '12 hours',
      worktime_hours_8hours: '8 hours'
    },
    bg: {
      header: "Обекти",
      header_desc: "Всички тези обекти са собственост на вашата компания. Имате транспорт? Само променете локацията на вашия обекти и ще можете да продавате от ново място.",
      header_desc_extra: "Или добавете нов допълнителен обект!",
      pl_box: "Обекти",
      pl_box_s: "Обект",
      no_pl: "Нямате обекти!",
      but_edit: "Промени",
      but_del: "Изтрий",
      but_add: "Добави Обект",
      add_header: "Добавяне на нов обект",
      pl_det: "Детайли на обекта",
      store_name: "Име на обекта",
      store_name_plh: "Име на обекта",
      cont_per: "Лице за контакти",
      cont_per_plh: "Лице за контакти",
      ph_num: "Телефонен номер",
      ph_num_plh: "0888 777 888",
      pl_desc: "Описание на обекта",
      pl_desc_plh: "Опишете с няколко изречения вашият обект.",
      pl_worktime_plh: "Работно време",
      pl_worktime_h_plh: "Работни часове",
      location: "Локация",
      location_plh: "Напишете локация",
      image_size: "2MB макс. размер на снимката",
      image_but: "Добавете снимка на вашият обект",
      upload_successful: "Снимката запазена",
      longitude: "Геогр. дължина",
      latitude: "Геогр. ширина",
      submit_changes: "Запази промените",
      are_you_sure_del: "Сигурни ли сте, че искате да изтриете този обект?",
      no: "Не",
      yes: "Да",
      notifyFirstPlaceCreated:"Успешно! Системата създаде вашият първи обект.",
      notifyPlaceCreated:"Успешно! Вашият първи обект е създаден.",
      notifyPlaceUpdate:"Успешно! Вашият обект е променен.",
      notifyPlaceDeleted:"Обекта е изтрит от системата!",
      notifyError:"Нещо се обърка!",
      worktime_days_weekdays: 'Седмични дни',
      worktime_days_weekend: 'Събота и Неделя',
      worktime_days_alldays: 'Всички дни',
      worktime_hours_24hours: '24 часа',
      worktime_hours_12hours: '12 часа',
      worktime_hours_8hours: '8 часа'
    }
   });

   _setLanguage = (lang) => {
    //console.log(lang)
    this.strings.setLanguage(lang)
  }

  geo_lat = position => {
    this.setState({ user_geo_lat: position.coords.latitude })
    //console.log("geo_lat= ", position.coords.latitude);
  };

  geo_lng = position => {
    this.setState({ user_geo_lng: position.coords.longitude })
    //console.log("geo_lng= ", osition.coords.longitude);
  };

  async geo_success(position) {
    return (
      position.coords.latitude,
      position.coords.longitude
      //console.log("lat= ", position.coords.latitude, "lon= ", position.coords.longitude)
    );
  }

  geo_error() {
    alert("Sorry, no position available.");
  }

  geo_options(enableHighAccuracy, maximumAge, timeout) {
    return (
      (enableHighAccuracy = false), (maximumAge = 30000), (timeout = 27000)
    );
  }

  getPlacesDataFromDbByAccountId = (userid) => {
    const findPipe = userid.indexOf("|");
    const id = userid.substr(findPipe + 1);
    if (this.state.loading) {
      fetch(`https://api.dontwaste.me/places/accounts/${id}`)
        .then(res => res.json())
        .then(res => this.setState({ places: res.places, loading: false }))
        .catch(err => {
          console.log(err);
          this.notifyError()
        })
    }
  };

  getPlacesDataFromDbByAccountIdLocal = (userid) => {
    const findPipe = userid.indexOf("|");
    const id = userid.substr(findPipe + 1);
    if (this.state.loading) {
      fetch(`http://localhost:3004/accounts/${id}`)
        .then(res => res.json())
        .then(res => this.setState({ places: res.places, loading: false }))
        .catch(err => {
          console.log(err);
          this.notifyError()
        })
    }
  };

  getPlacesData = () => {
    if (this.state.loading) {
      fetch(`https://api.dontwaste.me/places/`)
        .then(res => res.json())
        .then(res => this.setState({ places: res.places, loading: false }))
        .catch(err => {
          console.log(err);
          this.notifyError()
        })
    }
  };

  async savePlaceToDB(account_id, name, person, lat, lng, geolocation, contact_name, contact_number, description, image_url, image_name, workdays, worktime ) {
    const findPipe = account_id.indexOf("|");
    const id = account_id.substr(findPipe + 1);
    console.log('savePlaceToDB: ', id, name, person, lat, lng, geolocation)
    //Note that longitude comes first in a GeoJSON coordinate array, not latitude.
    //ELASTIC IS latitude & longitude !!!
    this.setState({ open: false });
    await axios.post("https://api.dontwaste.me/places/", {
      account_id: id,
      name: name,
      location: {
        coordinates: [lat, lng],
        geolocation: geolocation,
        id: id,
        type: 'Point'
      },
      contact: {
        name: contact_name,
        phone_number: contact_number
      },
      hours: {
        time: worktime,
        days: workdays
      },
      // place_image_url: image_url,
      // place_image_name: place_image_name,
      // description: description,
      created_by: person
    },
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
      .then(res => res.status === 201 ? this.notifyPlaceCreated() : null)
      .catch(err => {
        console.log(err);
        this.notifyError()
      })
    this.setState({ loading: true })
  };

  async savePlaceToDBLocal(account_id, name, person, lat, lng, geolocation, contact_name, contact_number, description, image_url, image_name, workdays, worktime ) {
    const findPipe = account_id.indexOf("|");
    const id = account_id.substr(findPipe + 1);
    console.log('savePlaceToDB: ', id, name, person, lat, lng, geolocation)
    //Note that longitude comes first in a GeoJSON coordinate array, not latitude.
    //ELASTIC IS latitude & longitude !!!
    this.setState({ open: false });
    await axios.post("http://localhost:3004/", {
      account_id: id,
      name: name,
      location: {
        coordinates: [lat, lng],
        geolocation: geolocation,
        id: id,
        type: 'Point'
      },
      contact: {
        name: contact_name,
        phone_number: contact_number
      },
      hours: {
        time: worktime,
        days: workdays
      },
      // place_image_url: image_url,
      // place_image_name: place_image_name,
      // description: description,
      created_by: person
    },
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
      .then(res => res.status === 201 ? this.notifyPlaceCreated() : null)
      .catch(err => {
        console.log(err);
        this.notifyError()
      })
    this.setState({ loading: true })
  };

  async deletePlace(storeid) {
    //this.setState({ openActionDelete: false });
    //console.log(storeid)
    await axios.delete(`https://api.dontwaste.me/places/${storeid}`)
      .then(res => res.status === 204 ? this.notifyPlaceDeleted() : null)
      .catch(err => {
        console.log(err);
        this.notifyError()
      })
    this.setState({ loading: true })
  }

  async editPlace( account_id, name, person, lat, lng, geolocation, contact_name, contact_number, description, image_url, image_name) {
    const findPipe = account_id.indexOf("|");
    const id = account_id.substr(findPipe + 1);
    console.log('place id ',this.state.editPlaceId)
    this.setState({ open: false, open1: false })
    await axios.put(`https://api.dontwaste.me/places/${this.state.editPlaceId}`, {
      name: name,
      location: {
        coordinates: [lat, lng],
        geolocation: geolocation,
        id: id,
        type: 'Point'
      },
      contact: {
        name: contact_name,
        phone_number: contact_number
      },
      //place_image_url: image_url,
      //place_image_name: image_name,
      //description: description,
      //created_by: person
    })
      .then(res => res.status === 200 ? this.notifyPlaceUpdate() : null)
      .catch(err => {
        console.log(err);
        this.notifyError()
      })
    this.setState({ loading: true, newStoreName: '', placeLat: '', placeLng: '',
    newStoreDescription: '', newStoreImageURL: '', newStoreContactPerson: '', newStorePhoneNumber: ''})
  }

  closeConfigShow = (closeOnEscape, closeOnDimmerClick) => () => {
    this.setState({ closeOnEscape, closeOnDimmerClick, open: true })
  }

  editModalConfig = (place) => () => {
    console.log('place',place)
    this.setState({ editPlaceId: place._id })
    this.setState({newStoreName: place.name, placeLat: place.location.coordinates[0], placeLng: place.location.coordinates[1]})
    place.description && this.setState({newStoreDescription: place.description});
    place.place_image_url && this.setState({newStoreImageURL: place.place_image_url});
    place.contact && place.contact.name && this.setState({newStoreContactPerson: place.contact.name});
    place.contact && place.contact.phone_number && this.setState({newStorePhoneNumber: place.contact.phone_number});
    this.setState({open1: true})
   
  }

  closeConfigShowActionDelete = (dimmer) => () => {
    this.setState({ dimmer, openActionDelete: true })
  }

  handleChange = (e, { value }) => this.setState({ value })
  show = dimmer => () => this.setState({ dimmer, open: true })
  close = () => this.setState({ open: false, open1: false, confirmDelete: false })
  openConfirmDeleteModal = () => this.setState({confirmDelete: true })
  closeActionDelete = () => this.setState({ openActionDelete: false })

  disableItem = (placeId) => {
    this.setState({ selectedPlaceId: this.state.selectedPlaceId.concat(placeId) })
    console.log(this.state.selectedPlaceId)
    console.log(typeof (this.state.selectedPlaceId))
  };

  convertPlaceToGeoCode = (place1) => {
    this.setState({ placeAddressByGoogle: place1 });
    console.log(this.state.placeAddressByGoogle);
    Geocode.setApiKey(GOOGLE_KEY);
    Geocode.enableDebug();
    Geocode.fromAddress(place1.formatted_address).then(
      response => {
        console.log('Geocode: ', response)
        //destruction variables from response
        const { lat, lng } = response.results[0].geometry.location;
        const { formatted_address } = response.results[0];
        this.setState({ placeLat: lat, placeLng: lng, geolocation: formatted_address })
        //console.log(lat, lng);
        //console.log('state.lat= ', this.state.placeLat)
        //console.log('state.lng= ', this.state.placeLng)
      },
      error => {
        console.error(error);
      }
    );
  }

  handleUploadStart = (file) => (
    console.log('firebase file', file),
    this.setState({ isUploading: true, progress: 1, uploadError: '' }));
  handleProgress = progress => this.setState({ progress });
  handleUploadError = error => {
    this.setState({ isUploading: false, uploadError: 'There was a problem with your upload. Please try again.' });
    console.error(error);
  };
  handleUploadSuccess = filename => {
    this.setState({ newStoreImageName: filename, progress: 100, isUploading: false });
    firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL()
      .then(url => this.setState({ newStoreImageURL: url }));
  };

  handleWorkDaysChange = (e, { value }) => {
    this.setState({ newPlaceWorkDays: value })
  }

  handleWorkHoursChange = (e, { value }) => {
    this.setState({ newPlaceWorkTime: value })
  }

  notifyFirstPlaceCreated = () => toast.success(this.strings.notifyFirstPlaceCreated);
  notifyPlaceCreated = () => toast.success(this.strings.notifyPlaceCreated);
  notifyPlaceUpdate = () => toast.info(this.strings.notifyPlaceUpdate);
  notifyPlaceDeleted = () => toast.warn(this.strings.notifyPlaceDeleted);
  notifyError = () => toast.error(this.strings.notifyError);

  showWorkTimeDays = (days) => {
    if(days === 'WEEKDAYS') {
      return this.strings.worktime_days_weekdays
    } else if(days === 'WEEKEND') {
      return this.strings.worktime_days_weekend
    } else if(days === 'ALLDAYS') {
      return this.strings.worktime_days_alldays
    } else {
      return ''
    }
  }

  showWorkTimeHours = (hours) => {
    if(hours === '24hours') {
      return this.strings.worktime_hours_24hours
    } else if(hours === '12hours') {
      return this.strings.worktime_hours_12hours
    } else if(hours === '8hours') {
      return this.strings.worktime_hours_8hours
    } else {
      return ''
    }
  }

  render() {
    const {
      places,
      open,
      closeOnEscape,
      closeOnDimmerClick,
      loading,
      open1,
      confirmDelete
    } = this.state;
    return (
          <div>
          {this._setLanguage(this.props.user["https://fc-demo/language"])}
            <ToastContainer />
            <Dimmer 
              active={loading} 
              inverted 
              data={
                this.props.user["https://fc-demo/role"] === "ADMIN" ?
                this.getPlacesData()
                : this.getPlacesDataFromDbByAccountId(this.props.user.sub)
                }
            >
              <Loader inverted content='Loading' />
            </Dimmer>
            <Grid stackable className="header_wrapper">
              <Grid.Column width={10}>
                <Item style={{ display: 'flex', marginBottom: '2rem' }}>
                  <Item.Content>
                    <Item.Header as='h2'>{this.strings.header}</Item.Header>
                    <Item.Description style={{ maxWidth: '600px', color: "#4f5765" }}>
                      {this.strings.header_desc_extra}
                                </Item.Description>
                    <Item.Extra>
                      {this.strings.header_desc}
                                </Item.Extra>
                  </Item.Content>
                </Item>
              </Grid.Column>

              <Grid.Column width={6}>
                <Segment placeholder raised className="stats_wrapper">
                  <Statistic horizontal>
                    <Statistic.Label style={{ marginRight: '1rem', marginLeft: '1rem' }}><Image src={store} size="tiny" floated="left" /></Statistic.Label>
                    {
                      this.state.loading ?
                        <Loader active={true} size='medium' />
                        :
                        <Statistic.Value>{this.state.places.length}</Statistic.Value>
                    }
                    <Statistic.Label>{this.state.places.length > 1 ? this.strings.pl_box : this.strings.pl_box_s}</Statistic.Label>
                  </Statistic>
                </Segment>
              </Grid.Column>
            </Grid>
            <Segment raised padded>
              <Card.Group doubling itemsPerRow={3} stackable className={places.length === 0 ? "no_results" : null}>
                {places.length <= 0
                  ?
                  (<>
                    <Image src={no_people} centered verticalAlign="middle" size="medium" className="empty_state_image" />
                    <Header as="h3" textAlign="center">{this.strings.no_pl}</Header>
                    <Divider style={{ display: 'flex', marginLeft: 'auto', marginRight: 'auto', width: '90%' }} />
                  </>
                  )
                  :
                  Object.values(places).map(
                    (place, i) => (
                      place.status !== 'FOR_DELETION' ?

                        <Card key={place.id}>
                          {loading ? (
                            <Placeholder>
                              <Placeholder.Image square />
                            </Placeholder>
                          ) : (
                              place.place_image_url ?
                                <Image size='medium' src={place.place_image_url} ui={false} centered className="center_crop_card" />
                                :
                                <Image size='medium' src={'https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/places%2Fmixkit-freshly-picked-lemons-391-desktop-wallpaper.png?alt=media&token=fcca43fd-1f93-46d7-b2bc-4fae0473a598'}
                                  ui={false} centered className="center_crop_card" />
                            )}
                          <Card.Content>
                            {loading ? (
                              <Placeholder>
                                <Placeholder.Header>
                                  <Placeholder.Line length='very short' />
                                  <Placeholder.Line length='medium' />
                                </Placeholder.Header>
                                <Placeholder.Paragraph>
                                  <Placeholder.Line length='short' />
                                </Placeholder.Paragraph>
                              </Placeholder>
                            ) : (
                                <Fragment>
                                  <Card.Header>{place.name}</Card.Header>
                                  {
                                    place.description && <Card.Meta>{place.description}</Card.Meta>
                                  }
                                  <Card.Description>
                                    <List>
                                      {place.contact &&
                                        (<List.Item>
                                          <List.Icon name='user circle' />
                                          <List.Content>{place.contact.name ? place.contact.name : ''}</List.Content>
                                        </List.Item>)}

                                      {place.contact &&
                                        (<List.Item>
                                          <List.Icon name='phone' />
                                          <List.Content>{place.contact.phone_number ? place.contact.phone_number : ''}</List.Content>
                                        </List.Item>)}

                                      {place.location.geolocation &&
                                        (<List.Item>
                                          <List.Icon name='marker' />
                                          <List.Content>{place.location.geolocation}</List.Content>
                                        </List.Item>)}
                                        
                                      {place.hours &&
                                        (<List.Item>
                                        <label>{this.strings.pl_worktime_plh}</label>
                                          <List.Icon name='time' />
                                          <List.Content>{this.showWorkTimeHours(place.hours.time)}</List.Content>
                                          <List.Content>{this.showWorkTimeDays(place.hours.days)}</List.Content>
                                        </List.Item>)}
                                    </List>
                                  </Card.Description>
                                </Fragment>
                              )}
                          </Card.Content>

                          <Card.Content extra>

                            <Modal
                              trigger={<Button onClick={this.editModalConfig(place)}>{this.strings.but_edit}</Button>}
                              centered={false}
                              open={open1}
                              closeOnEscape={true}
                              closeOnDimmerClick={true}
                              onClose={this.close}
                              closeIcon
                            >
                              <Modal.Header>{this.strings.but_edit}</Modal.Header>
                              <Modal.Content image>
                                <Segment placeholder style={{ margin: '2%' }}>
                                  <Image centered src={this.state.newStoreImageURL} size="tiny" />
                                  <div style={{ textAlign: "center", marginTop: '1rem' }}>
                                    <label>{this.strings.image_size}</label>
                                    <Icon name="asterisk" size="mini" style={{ verticalAlign: "top" }} color="red" />
                                  </div>
                                  <CustomUploadButton
                                    className="upload_button"
                                    style={{ margin: '1rem', backgroundColor: '#99D68F', color: 'white', padding: 10, borderRadius: 4, fontSize: '1rem', fontWeight: 'bold' }}
                                    required
                                    accept="image/*"
                                    name="place"
                                    randomizeFilename
                                    storageRef={firebase.storage().ref("images")}
                                    onUploadStart={this.handleUploadStart}
                                    onUploadError={this.handleUploadError}
                                    onUploadSuccess={this.handleUploadSuccess}
                                    onProgress={this.handleProgress}
                                    metadata={{ cacheControl: 'max-age=3600' }}
                                  >
                                    {this.strings.image_but}
                          </CustomUploadButton>
                                  {(this.state.progress > 0) &&
                                    <Progress percent={this.state.progress} progress
                                      success={this.state.progress === 100 && this.state.uploadError === ""}
                                      error={this.state.uploadError !== ""}>
                                      {this.state.uploadError && <p>{this.state.uploadError}</p>}
                                      {(this.state.progress === 100 && this.state.uploadError === "") && <p>{this.strings.upload_successful}</p>}
                                    </Progress>
                                  }
                                </Segment>
                                <Modal.Description>
                                  <Header>{this.strings.pl_det}</Header>
                                  <Form onSubmit={() => this.editPlace(this.props.user.sub, this.state.newStoreName, this.state.newStoreContactPerson, this.state.placeLat, this.state.placeLng, this.state.geolocation, this.state.newStoreContactPerson, this.state.newStorePhoneNumber, this.state.newStoreDescription, this.state.newStoreImageURL, this.state.newStoreImageName)}>
                                    <Form.Input required label={this.strings.store_name} placeholder={this.state.newStoreName} type='text' onChange={e => this.setState({ newStoreName: e.target.value })} />
                                    <Form.Group widths={2}>
                                      <Form.Input required label={this.strings.cont_per} placeholder={this.state.newStoreContactPerson ? this.state.newStoreContactPerson : this.strings.cont_per_plh} type='text' onChange={e => this.setState({ newStoreContactPerson: e.target.value })} />
                                      <Form.Input required label={this.strings.ph_num} placeholder={this.state.newStorePhoneNumber ? this.state.newStorePhoneNumber : this.strings.ph_num_plh }type='number' onChange={e => this.setState({ newStorePhoneNumber: e.target.value })} />
                                    </Form.Group>
                                    <Form.Field
                                      width="16"
                                      name="newPlaceDescription"
                                      control={TextArea}
                                      value={this.state.newStoreDescription}
                                      onChange={e => this.setState({ newStoreDescription: e.target.value })}
                                      label={this.strings.pl_desc}
                                      placeholder={this.state.newStoreDescription ? this.state.newStoreDescription : this.strings.pl_desc_plh}
                                    />
                                    <Form.Field required>
                                      <label>{this.strings.location}</label>
                                      <Autocomplete
                                        style={{ width: '100%' }}
                                        onPlaceSelected={(place1) => {
                                          this.convertPlaceToGeoCode(place1);
                                        }}
                                        types={['establishment', 'geocode']}
                                        componentRestrictions={{ country: "bg" }}
                                      />
                                      {console.log("place coord", this.state.newStoreAddress.coordinates)}
                                      {this.state.placeLat !== '' ?
                                        (<>
                                          <Image rounded centered style={{ marginBottom: '1rem', marginTop: '1rem' }} src={`https://maps.googleapis.com/maps/api/staticmap?zoom=15&size=500x250&markers=${this.state.placeLat},${this.state.placeLng}&key=${GOOGLE_KEY}`} />
                                          <Label.Group style={{ textAlign: 'center' }}>
                                            <Label><Icon name='map' />{this.strings.longitude}: {this.state.placeLng}</Label>
                                            <Label><Icon name='map' />{this.strings.latitude}: {this.state.placeLat}</Label>
                                          </Label.Group>
                                        </>
                                        )
                                        : null}
                                    </Form.Field>
                                    <Form.Button style={{ marginTop: "1rem" }} type='submit' primary>{this.strings.submit_changes}</Form.Button>
                                  </Form>
                                </Modal.Description>
                              </Modal.Content>
                            </Modal>
                            <Modal
                              trigger={
                                <Button onClick={this.openConfirmDeleteModal} negative>{this.strings.but_del}</Button>
                              }
                              open={confirmDelete}
                              basic
                              size='small'
                              closeOnEscape={false}
                              closeOnDimmerClick={false}
                              onClose={this.close}
                            >
                              <Header icon='remove circle' content='Delete a place' />
                              <Modal.Content>
                                <p>
                                  {this.strings.are_you_sure_del}
                                          </p>
                              </Modal.Content>
                              <Modal.Actions>
                                <Button basic color='green' inverted onClick={this.close}>
                                  <Icon name='remove' /> {this.strings.no}
                                          </Button>
                                <Button color='red' inverted onClick={() => this.deletePlace(place._id)}>
                                  <Icon name='checkmark' /> {this.strings.yes}
                                          </Button>
                              </Modal.Actions>
                            </Modal>
                          </Card.Content>
                        </Card>
                        : null
                    )
                  )
                }
                <Modal
                  trigger={
                    places.length > 0 ?
                      <Card onClick={this.closeConfigShow(true, false)} style={{ display: 'flex', alignItems: 'center' }}>
                        <Card.Content style={{ justifyContent: 'center', alignItems: 'center', verticalAlign: "middle !important", display: 'flex' }}>
                          <div>
                            <Icon circular inverted color="green" name="plus" size="huge" />
                            <Header as="h3" style={{ textAlign: "center" }}>{this.strings.but_add}</Header>
                          </div>
                        </Card.Content>
                      </Card>
                      :
                      <Item onClick={this.closeConfigShow(true, false)} style={{ display: 'flex', cursor: 'pointer' }}>
                        <Item.Content style={{ marginLeft: 'auto', marginRight: 'auto', padding: '1rem' }}>
                          <div>
                            <Icon circular inverted color="green" name="plus" size="huge" />
                            <Header as="h3" style={{ textAlign: "center" }}>{this.strings.but_add}</Header>
                          </div>
                        </Item.Content>
                      </Item>
                  }
                  centered={false}
                  open={open}
                  closeOnEscape={closeOnEscape}
                  closeOnDimmerClick={closeOnDimmerClick}
                  onClose={this.close}
                  closeIcon
                >

                  <Modal.Header>{this.strings.add_header}</Modal.Header>
                  <Modal.Content image>
                    <Segment placeholder style={{ margin: '2%' }}>
                      <Image centered src={this.state.newStoreImageURL} size="tiny" />
                      <div style={{ textAlign: "center", marginTop: '1rem' }}>
                        <label>{this.strings.image_size}</label>
                        <Icon name="asterisk" size="mini" style={{ verticalAlign: "top" }} color="red" />
                      </div>
                      <CustomUploadButton
                        className="upload_button"
                        style={{ margin: '1rem', backgroundColor: '#99D68F', color: 'white', padding: 10, borderRadius: 4, fontSize: '1rem', fontWeight: 'bold' }}
                        required
                        accept="image/*"
                        name="place"
                        randomizeFilename
                        storageRef={firebase.storage().ref("images")}
                        onUploadStart={this.handleUploadStart}
                        onUploadError={this.handleUploadError}
                        onUploadSuccess={this.handleUploadSuccess}
                        onProgress={this.handleProgress}
                        metadata={{ cacheControl: 'max-age=3600' }}
                      >
                        {this.strings.image_but}
                          </CustomUploadButton>
                      {(this.state.progress > 0) &&
                        <Progress percent={this.state.progress} progress
                          success={this.state.progress === 100 && this.state.uploadError === ""}
                          error={this.state.uploadError !== ""}>
                          {this.state.uploadError && <p>{this.state.uploadError}</p>}
                          {(this.state.progress === 100 && this.state.uploadError === "") && <p>{this.strings.upload_successful}</p>}
                        </Progress>
                      }

                    </Segment>
                    <Modal.Description>
                      <Header>{this.strings.pl_det}</Header>
                      <Form onSubmit={() => this.savePlaceToDB(this.props.user.sub, this.state.newStoreName, this.state.newStoreContactPerson, this.state.placeLat, this.state.placeLng, this.state.geolocation, this.state.newStoreContactPerson, this.state.newStorePhoneNumber, this.state.newStoreDescription, this.state.newStoreImageURL, this.state.newStoreImageName, this.state.newPlaceWorkDays, this.state.newPlaceWorkTime)}>
                        <Form.Input required label={this.strings.store_name} placeholder={this.strings.store_name_plh} type='text' onChange={e => this.setState({ newStoreName: e.target.value })} />
                        <Form.Group widths={2}>
                          <Form.Input required label={this.strings.cont_per} placeholder={this.strings.cont_per_plh} type='text' onChange={e => this.setState({ newStoreContactPerson: e.target.value })} />
                          <Form.Input required label={this.strings.ph_num} placeholder={this.strings.ph_num_plh} type='number' onChange={e => this.setState({ newStorePhoneNumber: e.target.value })} />
                          <Form.Dropdown
                                required
                                label={this.strings.pl_worktime_plh}
                                placeholder={this.strings.pl_worktime_plh}
                                fluid
                                search
                                selection
                                options={this.props.user["https://fc-demo/language"] === 'en' ? this.state.newPlaceWorkDaysDropDown : this.state.newPlaceWorkDaysDropDownBG}
                                value={this.state.newPlaceWorkDays}
                                onChange={this.handleWorkDaysChange}
                              />
                          <Form.Dropdown
                                required
                                label={this.strings.pl_worktime_h_plh}
                                placeholder={this.strings.pl_worktime_h_plh}
                                fluid
                                search
                                selection
                                options={this.props.user["https://fc-demo/language"] === 'en' ? this.state.newPlaceWorkHoursDropDown : this.state.newPlaceWorkHoursDropDownBG}
                                value={this.state.newPlaceWorkTime}
                                onChange={this.handleWorkHoursChange}
                              />
                        </Form.Group>
                        <Form.Field
                          width="16"
                          name="newPlaceDescription"
                          control={TextArea}
                          value={this.state.newStoreDescription}
                          onChange={e => this.setState({ newStoreDescription: e.target.value })}
                          label={this.strings.pl_desc}
                          placeholder={this.strings.pl_desc_plh}
                        />
                        
                        <Form.Field required>
                          <label>{this.strings.location}</label>
                          <Autocomplete
                            style={{ width: '100%' }}
                            onPlaceSelected={(place1) => {
                              this.convertPlaceToGeoCode(place1);
                            }}
                            types={['establishment', 'geocode']}
                            componentRestrictions={{ country: "bg" }}
                          />
                          {console.log("place coord", this.state.newStoreAddress.coordinates)}
                          {this.state.placeLat !== '' ?
                            (<>
                              <Image rounded centered style={{ marginBottom: '1rem', marginTop: '1rem' }} src={`https://maps.googleapis.com/maps/api/staticmap?zoom=15&size=500x250&markers=${this.state.placeLat},${this.state.placeLng}&key=${GOOGLE_KEY}`} />
                              <Label.Group style={{ textAlign: 'center' }}>
                                <Label><Icon name='map' />{this.strings.longitude}: {this.state.placeLng}</Label>
                                <Label><Icon name='map' />{this.strings.latitude}: {this.state.placeLat}</Label>
                              </Label.Group>
                            </>
                            )
                            : null}
                        </Form.Field>

                        <Form.Button style={{ marginTop: "1rem" }} type='submit' secondary disabled={!this.state.newStoreAddress.coordinates ||
                          !this.state.newStoreName || !this.state.newStorePhoneNumber} >{this.strings.but_add}</Form.Button>

                      </Form>
                    </Modal.Description>
                  </Modal.Content>
                </Modal>


              </Card.Group>
            </Segment>
          </div>
        )}
      
}
