import React from "react";
import { Tab } from "semantic-ui-react";
import MyProfile from "./MyProfile";

const panes = [
  {
    menuItem: { key: "myprofile", icon: "user", content: "My Profile" },
    render: () => (
      <Tab.Pane attached={false}>
        <MyProfile />
      </Tab.Pane>
    )
  }
];

const TabExamplePointing = () => (
  <Tab menu={{ pointing: true }} panes={panes} />
);

export default TabExamplePointing;
