import React from 'react';
import { ToastContainer } from 'react-toastify';
//import Stripe from 'stripe';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
//import { Button, Icon, Form } from 'semantic-ui-react';

import CheckoutForm from './CheckoutForm';
//import CheckoutFormClass from './CheckoutFromClass';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

/* 
Stripe Demo Api Keys
Publishable pk_test_16o0KaVABd1UsMRWvFHeZbhG00JFsLfHuW
Secret sk_test_4oO9HQAJyvgo1ZG5RKbInLAG00FGGWuJTy
*/

const StripeUI = (props) => {
  /* const stripe = new Stripe('sk_test_4oO9HQAJyvgo1ZG5RKbInLAG00FGGWuJTy', {
    apiVersion: '2020-03-02',
  }); */

  //pk_test_16o0KaVABd1UsMRWvFHeZbhG00JFsLfHuW -> dontwaste.me Our US Company
  //pk_test_51HEBrgLWJ2wuJk1aDNZmdnMTNZtURCX0kwGfqomwZQcFJWHuse7kIhlNs8NHGiCbA7y369kaHtG19sIUOKZeqbIv00VFfkkprd -> Veloxoft LTD Our BG Company
  const stripePromise = loadStripe('pk_test_51HEBrgLWJ2wuJk1aDNZmdnMTNZtURCX0kwGfqomwZQcFJWHuse7kIhlNs8NHGiCbA7y369kaHtG19sIUOKZeqbIv00VFfkkprd');

    return (
      <div>
      <ToastContainer />
        {/* <Button 
          color='blue'
          //onClick={() => createCustomer()}
        >
          <Icon name='payment' /> Stripe pay
        </Button> */}
        <Elements stripe={stripePromise}>
          {/* <CardElement
            options={{
              style: {
                base: {
                  fontSize: '16px',
                  color: '#424770',
                  '::placeholder': {
                    color: '#aab7c4',
                  },
                },
                invalid: {
                  color: '#9e2146',
                },
              },
            }}
          /> */}
          
          {/* <CheckoutFormClass totalLocalStorageToStripe={props.totalLocalStorageToStripe} cartLocalStorageToStripe={props.cartLocalStorageToStripe} user={props.user}/> */}
          {/* <CheckoutForm totalLocalStorageToStripe={props.totalLocalStorageToStripe} cartLocalStorageToStripe={props.cartLocalStorageToStripe} user={props.user}/> */}
          <CheckoutForm />
        </Elements>
      </div>
    )
}



export default StripeUI