import React, { Component } from "react";
import axios from "axios";
import { Segment, Image, Grid, Statistic } from "semantic-ui-react";
import water from "../images/dashboard/save-water.svg";

var waterSavedFactor = 3000;

export default class StoresWater extends Component {
    constructor(props) {
        super(props);
        this.state = {
        orders: [],
        loading: true
        };
    }  

    getOrdersByToken = (userid, user_type) => {
        const findPipe = userid.indexOf("|");
        const id = userid.substr(findPipe+1);
        let url = user_type === "ADMIN" ? 'https://api.dontwaste.me/orders/' : `https://api.dontwaste.me/orders/accounts/${id}`
        if (this.state.loading) {
            axios.get(`${url}`, {})
                .then(orders => { this.setState({ orders: orders.data.orders, loading: false }) })
                .catch(err => {console.log(err);});
        }
    };

    calculateWaterWaste() {
        //needs to be .nickname but not working
        var randWMultiplier = (13/10)
        var waterSaved = this.state.orders.length*waterSavedFactor*randWMultiplier.toFixed(1)
        //console.log('ccoSaved', waterSaved)
        return waterSaved
    }

    render() {
        return (
            <Grid.Column width={3} data={this.getOrdersByToken(this.props.userid, this.props.user_type)}>
                <Segment placeholder raised>
                    <Statistic>
                        <Statistic.Label>
                            <Image src={water}  size='mini' />
                        </Statistic.Label>
                        
                                <Statistic.Value>{this.calculateWaterWaste()} {this.props.dashboard_box_water_l}</Statistic.Value>
                        
                        <Statistic.Label>{this.props.dashboard_box_water}</Statistic.Label>
                    </Statistic>
                </Segment>
            </Grid.Column>
        );
    }
    }
