import {
  FETCH_PRODUCTS_BEGIN,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILURE,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  SUBTRACT_FROM_CART,
  GET_DEVICE_ID,
  SET_LANGUAGE
} from "./productActions";

const initialState = {
  items: [],
  deviceID: '',
  loading: false,
  error: null
};

export default function productReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case GET_DEVICE_ID:
        return {
          ...state,
          deviceID: action.payload
        };
    
    case FETCH_PRODUCTS_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_PRODUCTS_SUCCESS:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        loading: false,
        items: action.payload.products
      };

    case FETCH_PRODUCTS_FAILURE:
      // The request failed, but it did stop, so set loading to "false".
      // Save the error, and we can display it somewhere
      // Since it failed, we don't have items to display anymore, so set it empty.
      // This is up to you and your app though: maybe you want to keep the items
      // around! Do whatever seems right.
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: []
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}

const products = (state, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      return {
        ...state,
        quantity_available: state.quantity_available - 1
      }
    case REMOVE_FROM_CART:
      return {
        ...state,
        quantity_available: state.quantity_available + 1
      }
    case SUBTRACT_FROM_CART:
    return {
      ...state,
      quantity_available: state.quantity_available + 1
    }
    default:
      return state
  }
}

export const byId = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        ...action.payload.products.reduce((obj, product) => {
          obj[product._id] = product
          return obj;
        }, {})
      }
    default:
      const { productId } = action
      if (productId) {
        return {
          ...state,
          [productId]: products(state[productId], action)
        }
      }
      return state
  }
}

export const visibleIds = (state = [], action) => {
  switch (action.type) {
    case FETCH_PRODUCTS_SUCCESS:
      return action.payload.products.map(
        (product) => (
            product._id
        )
      )
    default:
      return state
  }
}

/* export const visibleIds_placeIds = (state = [], action) => {
  switch (action.type) {
    case FETCH_PRODUCTS_SUCCESS:
      return action.payload.products.map(product => product.place_id)
    default:
      return state
  }
} */

export const getProduct = (state, _id) => (
  state.byId[_id]
)

export const getVisibleProducts = state =>
  state.visibleIds.map(
    (_id) => (
      getProduct(state, _id)
    )
  )

/* export const getVisiblePlace = state =>
  state.visibleIds_placeIds.map(
    (place_id) => (
      console.log('placeReducer: ', place_id),
      getProduct(state, place_id)
    )
  ) */

