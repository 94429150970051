import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { ReactReduxContext } from 'react-redux';
import { fetchProductsAfterUpdateItem } from "../../reducers/productActions";
import { Icon, Button, Modal, Segment, Header, Form, Card, List, Placeholder, Image, Divider, Loader } from 'semantic-ui-react';
import * as moment from 'moment';
import _, { replace } from "lodash";
import QRCode from 'qrcode';
import "./styles.css"

export default class ItemCard extends Component {
    constructor(props) {
        super(props);
        this.state= {
            open1: false,
            open:false,
            newItemAgreeTerms: false,
            editItemId: '',
            newItemQyAv: 0,
            newItemRedPrice: 0,
            newItemExDate: '',
            currentProductLoading: false,
            currentProduct: [],
            currentPlace: [],
            currentPlaceLoading: false,
            fetch_needed: false,
            confirmDelete: false,
            confirmPrint: false,
            confirmQR: false,
            qr_code_state: '',
            qr_link: ''
        };
    }

    componentDidMount() {
        console.log('item', this.props.item)
        console.log('item id', this.props.item.product_id)
        this.getProductDetails(this.props.item.product_id)
        this.getPlaceDetails(this.props.item.place_id)
    }

    componentDidUpdate(prevProps) {
        if (this.props.item !== prevProps.item) {
            console.log('different item!')
            this.getProductDetails(this.props.item.product_id)
            this.getPlaceDetails(this.props.item.place_id)
        }
    }

    async editItem(newItemQyAv, newItemRedPrice, itemid) {
        // console.log(this.state.editItemId)
        this.setState({ open: false, open1: false })
        await axios.put(`https://api.dontwaste.me/inventories/${this.state.editItemId}`, {
            quantity_available: newItemQyAv,
            reduced_price: newItemRedPrice,
            // expiry_date: newItemExDate,
        })
        .then( res => res.status === 200 ? this.setState({ fetch_needed: true }) : null)
        .then( this.props.updateCallback(itemid, newItemRedPrice, newItemQyAv))
        .catch(err => {
            console.log(err);
            this.props.errorCallback();
        })
        // this.setState({ loading: true });
    }

    async deleteItem(itemid) {
        //this.setState({ openActionDelete: false });
        //console.log(itemid)
        await axios.delete(`https://api.dontwaste.me/inventories/${itemid}`)
        .then(this.props.deleteCallback(itemid), console.log('update id: ', itemid))
        .catch(err => {
            console.log(err);
            this.props.errorCallback();
        });
        // this.setState({ loading: true });
    }

    async generateQRforItem(itemid) {
        //console.log(itemid)
        this.setState({ confirmQR: false})
        //console.log('QRCode.toString(itemid): ', QRCode.toString(itemid))
        console.log('QRCode.toDataURL(itemid): ', QRCode.toDataURL(`https://app.traceground.com/items_details/${itemid}`))
        //console.log('QRCode.toFile(itemid): ', QRCode.toFile(itemid))
        await QRCode.toDataURL(`https://app.traceground.com/items_details/${itemid}`)
        .then(
                QRCode.toDataURL(`https://app.traceground.com/items_details/${itemid}`).then(url => {
                    this.setState({ qr_code_state: url })
                    }
                )
                /* this.setState({ qr_code_state: QRCode.toString(itemid) }) */
            )
        .then( this.props.updateCallback(itemid))
        .catch(err => {
            console.log(err);
            this.props.errorCallback();
        })
        //let qr_code_promise = await QRCode.toString(itemid);
        //console.log('this.state.qr_code_state: ', this.state.qr_code_state)

        await axios.put(`https://api.dontwaste.me/inventories/${itemid}`, {
        //await axios.put(`http://localhost:3004/${itemid}`, {
            qr_code: this.state.qr_code_state
        })
        .then( res => res.status === 200 ? this.setState({ fetch_needed: true }) : null)
        .catch(err => {
            console.log(err);
            this.props.errorCallback();
        });

        // this.setState({ loading: true });
    }

    /* qrcodeES6(qrcode) {
        QRCode.toDataURL(qrcode)
        .then(url => {
            console.log('qrcodeURL: ', url)
        })
        .catch(err => {
            console.error(err)
        })
    } */

    /* autoCorrect(qrcode) {
        this.setState({ qr_link: qrcode.replace('&lt;','X').replace('&gt;','Y') });
    } */

    async printItem(newItemQyAv, newItemRedPrice, itemid) {
        // console.log(this.state.editItemId)
        this.setState({ open: false, open1: false })
        await axios.put(`https://api.dontwaste.me/inventories/${this.state.editItemId}`, {
            quantity_available: newItemQyAv,
            reduced_price: newItemRedPrice,
            // expiry_date: newItemExDate,
        })
        .then( res => res.status === 200 ? this.setState({ fetch_needed: true }) : null)
        .then( this.props.updateCallback(itemid, newItemRedPrice, newItemQyAv))
        .catch(err => {
            console.log(err);
            this.props.errorCallback();
        })
        // this.setState({ loading: true });
    }

    close = () => this.setState({ open1: false , confirmDelete: false , confirmPrint: false , confirmQR: false})

    getProductDetails(value) {
        console.log('getting product data')
        fetch(`https://api.dontwaste.me/products/${value}`)
        .then(res => res.json())
        .then(res => this.setState({ currentProduct: res.product }))
    }

    getPlaceDetails(value) {
            console.log('getting place data')
    fetch(`https://api.dontwaste.me/places/${value}`)
    .then(res => res.json())
    .then(res => this.setState({ currentPlace: res.place }))
    
    }

    openConfirmDeleteModal = () => this.setState({confirmDelete: true })
    openConfirmPrintModal = () => this.setState({confirmPrint: true })
    openConfirmQRModal = () => this.setState({confirmQR: true })

    getDateFromStamp (timestamp) {
        const divided = timestamp;
        var date = moment.unix(divided).format()
        //var date = moment.unix(divided).format("dddd, MMMM Do YYYY, h:mm:ss a")
        //const date = new Date(timestamp/1000).toString()

        return date
    }
    calculateTimeRemaining (timestamp) {
        const divided = timestamp/1000;
        var date = moment.unix(divided).fromNow()
        //var date = moment.unix(divided).format("dddd, MMMM Do YYYY, h:mm:ss a")
        //const date = new Date(timestamp/1000).toString()
        return date
    }
    getCreatedDateFromStamp (timestamp) {
        //console.log("timestamp in received:", timestamp)
       //const date = new Date(timestamp*1000).toString()
        //console.log("date in received:", date)
        //var date = moment.unix(timestamp).format("dddd, MMMM Do YYYY, h:mm:ss a")
        var date = moment.unix(timestamp).fromNow()
        return date
    }

    closeConfigShow1 = (closeOnEscape1, closeOnDimmerClick1, id, quantity_available, reduced_price, expiry_date) => () => {
        this.setState({ closeOnEscape1, closeOnDimmerClick1, editItemId: id, newItemQyAv: quantity_available, newItemRedPrice: reduced_price, newItemExDate: expiry_date, open1: true })
    }

    handleChangeCheckBox = e => this.setState(({ newItemAgreeTerms }) => ({ newItemAgreeTerms: !newItemAgreeTerms }))

    render() {
        const {
            closeOnEscape1,
            closeOnDimmerClick1,
            open1,
            currentProductLoading,
            currentProduct,
            currentPlace,
            currentPlaceLoading,
            fetch_needed,
            confirmDelete,
            confirmPrint,
            confirmQR
        } = this.state;
        return (
                <Card key={this.props.item._id}>
                {
                    fetch_needed ? 
                        <ReactReduxContext.Consumer>
                            {({ store }) => {
                            console.log('ReactReduxContextItem')
                            store.dispatch(fetchProductsAfterUpdateItem())
                            this.setState({ fetch_needed: false })
                            }}
                        </ReactReduxContext.Consumer>
                    : null
                }    
                            {(this.props.loading && currentProductLoading && currentPlaceLoading)? (
                                <Placeholder>
                                <Placeholder.Image square />
                                </Placeholder>
                            ) : (
                                <Image size='medium' src={currentProduct.image} ui={false} centered className="center_crop_card" />
                                )}

                            <Card.Content>
                                
                                {(this.props.loading && currentProductLoading && currentPlaceLoading) ? (
                                <Placeholder>
                                    <Placeholder.Header>
                                    <Placeholder.Line length='very short' />
                                    <Placeholder.Line length='medium' />
                                    </Placeholder.Header>
                                    <Placeholder.Paragraph>
                                    <Placeholder.Line length='short' />
                                    </Placeholder.Paragraph>
                                </Placeholder>
                                ) : (
                                    <Fragment>
                                    <Card.Header>{currentProduct.name}</Card.Header>
                                    <Card.Meta><Icon name='marker' />{currentPlace.name ? currentPlace.name : 'Something wrong happened'}</Card.Meta>
                                    <Card.Description>
                                        <List>
                                        
                                        <List.Item>
                                            <List.Icon name='tags' />
                                            <List.Content>{this.props.available_qty}: {this.props.item.quantity_available}</List.Content>
                                        </List.Item>
                                        {/* <List.Item>
                                            <List.Icon name='money bill alternate outline' />
                                            <List.Content>{this.props.org_price}: {this.props.item.original_price} {this.props.currency}</List.Content>
                                        </List.Item>
                                        <List.Item>
                                            <List.Icon name='percent' />
                                            <List.Content>{this.props.reduced_price}: {this.props.item.reduced_price} {this.props.currency}</List.Content>
                                        </List.Item> */}
                                        <List.Item>
                                            <List.Icon name='calendar plus outline' />
                                            <List.Content>
                                            {this.props.created} {this.getCreatedDateFromStamp(this.props.item.created)}
                                            </List.Content>
                                        </List.Item>
                                        <List.Item>
                                            <List.Icon name='building outline' />
                                            <List.Content>
                                            {this.props.deal_type} {this.props.item.deal_type === 'PERSONAL' ? this.props.deal_personal : this.props.deal_business}
                                            </List.Content>
                                        </List.Item>                        
                                        <List.Item>
                                            <List.Icon name='hourglass end' />
                                            <List.Content>
                                            {this.props.expires} {this.calculateTimeRemaining(this.props.item.expiry_date)}
                                            </List.Content>
                                        </List.Item>
                                        <List.Item>
                                            {
                                                this.props.item.qr_code.length > 0 ? <Image size='small' src={this.props.item.qr_code} ui={false} centered className="center_crop_card" /> : <Image size='small' src="https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/kaufland%2Fno-qr-code-sign-isolated-white-background-vector-illustration-234692015.png?alt=media&token=a75e5c7b-bd93-48e4-95cf-8ab6b34c918f" ui={false} centered className="center_crop_card" />
                                            }
                                            
                                            {/* <img alt='123' src={this.props.item.qr_code} /> */}
                                        </List.Item>
                                        {/* <List.Item>
                                            <List.Icon name='barcode' />
                                            <List.Content>
                                                Blockchain Txs: 0x8522222222
                                            </List.Content>
                                        </List.Item> */}
                                        </List>
                                    </Card.Description>
                                    </Fragment>
                                )}
                            </Card.Content>

                            <Card.Content extra>
                                    <Modal
                                        trigger={
                                            <Button color='orange' onClick={this.openConfirmQRModal} >QR Код</Button>
                                        }
                                        open={confirmQR}
                                        basic
                                        size='small'
                                        closeOnEscape={false}
                                        closeOnDimmerClick={false}
                                        onClose={this.close}
                                    >
                                        <Header icon='label' content='Искате ли да генерирате QR Код?' />
                                        <Modal.Content>
                                            <p>
                                            {this.props.are_you_qr}
                                                    </p>
                                        </Modal.Content>
                                        <Modal.Actions>
                                            <Button basic color='red' inverted onClick={this.close}>
                                            <Icon name='remove' /> {this.props.no}
                                                    </Button>
                                            <Button color='green' inverted onClick={() => this.generateQRforItem(this.props.item._id)}>
                                            <Icon name='checkmark' /> {this.props.yes}
                                                    </Button>
                                        </Modal.Actions>
                                    </Modal>

                                <Modal
                                    trigger={<Button onClick={this.closeConfigShow1(true, false, this.props.item._id, this.props.item.quantity_available, this.props.item.reduced_price, this.props.item.expiry_date)}>Етикет</Button>}
                                    centered={false}
                                    open={open1}
                                    closeOnEscape={closeOnEscape1}
                                    closeOnDimmerClick={closeOnDimmerClick1}
                                    onClose={this.close}
                                    closeIcon
                                >
                                <Modal.Header>Етикет Номер 1234</Modal.Header>
                                <Modal.Content image>
                                    <Segment placeholder style={{ margin: '2%' }}>
                                    <Header icon>
                                        <Image size="massive" src={this.props.item.qr_code} style={{width:'100%'}}></Image>
                                        {/* <Icon name='file image outline' /> */}
                                        {/* {this.props.upload_text} */}
                                    </Header>
                                    {/* <Button primary>Принтирай</Button> */}
                                    </Segment>
                                    <Modal.Description>
                                    <Header>Информация от производителя</Header>
                                    <Form>
                                        <Form.Group unstackable widths={2}>
                                        <Form.Input label='Информация 1' placeholder={this.state.newItemQyAv} type='text' onChange={e => this.setState({ newItemQyAv: e.target.value })} />
                                        <Form.Input label='Информация 2' placeholder={this.state.newItemRedPrice} type='text' onChange={e => this.setState({ newItemRedPrice: e.target.value })} />
                                        {/* <Form.Input label='Item Expiry Date' placeholder={this.state.newItemExDate} type='text' onChange={e => this.setState({ newItemExDate: e.target.value })} /> */}
                                        </Form.Group>
                                        <Form.Checkbox
                                        label='Проследяемост и сигурност на хранителните продукти'
                                        onChange={this.handleChangeCheckBox}
                                        />
                                        <Button type='submit' secondary disabled={!this.state.newItemAgreeTerms} onClick={() => window.print()}>TraceGround</Button>
                                        {/* <Modal
                                        trigger={
                                            <Button type='submit' secondary disabled={!this.state.newItemAgreeTerms} onClick={() => window.print()}>Принтиране</Button>
                                        }
                                        basic
                                        size='small'
                                        closeOnEscape={false}
                                        closeOnDimmerClick={false}
                                        onClose={this.close}
                                        >
                                        <Header icon='birthday cake' content='Adding new item' />
                                        <Modal.Content>
                                            <p>
                                            {this.props.are_you_update}
                                            </p>
                                        </Modal.Content>
                                        <Modal.Actions>
                                            <Button basic color='red' inverted onClick={this.close}>
                                            <Icon name='remove' /> {this.props.no}
                                            </Button>
                                            <Button color='green' inverted onClick={() => window.print()}>
                                            <Icon name='checkmark' /> {this.props.yes}
                                            </Button>
                                        </Modal.Actions>
                                        </Modal> */}

                                    </Form>
                                    </Modal.Description>
                                </Modal.Content>
                            </Modal>
                            </Card.Content>

                            <Divider />

                            <Card.Content extra>

                                
                                <Modal
                                    trigger={
                                        <Button negative onClick={this.openConfirmDeleteModal} >{this.props.delete}</Button>
                                    }
                                    open={confirmDelete}
                                    basic
                                    size='small'
                                    closeOnEscape={false}
                                    closeOnDimmerClick={false}
                                    onClose={this.close}
                                >
                                    <Header icon='remove circle' content='Delete a place' />
                                    <Modal.Content>
                                        <p>
                                        {this.props.are_you_delete}
                                                </p>
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button basic color='green' inverted onClick={this.close}>
                                        <Icon name='remove' /> {this.props.no}
                                                </Button>
                                        <Button color='red' inverted onClick={() => this.deleteItem(this.props.item._id)}>
                                        <Icon name='checkmark' /> {this.props.yes}
                                                </Button>
                                    </Modal.Actions>
                                </Modal>
                                
                                
                                </Card.Content>

                                
                        </Card>
                
        )
    }
}