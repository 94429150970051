import React from "react";
//import loading from "../assets/loading.svg";
import logoFoodChain2 from "../assets/logoFoodChain2.svg"

const Loading = () => (
  <div className="spinner" style={{
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    backgroundColor: 'white',
    margin: 'auto',
    padding: 'auto'
  }}>
    <img src={logoFoodChain2} alt="Loading" />
  </div>
);

export default Loading;
