import React from 'react'
import LocalizedStrings from 'react-localization';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { checkout } from '../reducers/productActions'
import { getTotal, getCartProducts } from '../reducers/rootReducer'
import Cart from '../components/Cart/Cart'

const CartContainer = ({ products, total, checkout, user, cartLocalStorage, totalLocalStorage }) => {

  const strings = new LocalizedStrings({
    en:{
      cart_no_products: "Please add some products to cart.",
      cart_header: "Your Cart",
      cart_total: "Total",
      total_currency: "$",
      but_order: "Order now",
      but_back: "Back",
    },
    bg: {
      cart_no_products: "Моля, добавете продукти във вашата кошница.",
      cart_header: "Вашата кошница",
      cart_total: "Общо",
      total_currency: "лв.",
      but_order: "Потвърди",
      but_back: "Назад",
    }
   })

  const _setLanguage = () => {
    //console.log(lang)
    strings.setLanguage(user["https://fc-demo/language"])
  }

  _setLanguage();

  return (
    <div>
      <Cart
        products={products}
        total={total}
        cartLocalStorage={cartLocalStorage}
        totalLocalStorage={totalLocalStorage}
        onCheckoutClicked={() => checkout(products, user, cartLocalStorage, totalLocalStorage)}
        cart_no_products= {strings.cart_no_products}
        cart_header= {strings.cart_header}
        cart_total= {strings.cart_total}
        total_currency= {strings.total_currency}
        but_order= {strings.but_order}
        but_back= {strings.but_back}
      />
    </div>
  )
  
}

CartContainer.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.number.isRequired,
    //name: PropTypes.string.isRequired,
    reduced_price: PropTypes.number.isRequired,
    quantity_available: PropTypes.number.isRequired
  })).isRequired,
  total: PropTypes.string,
  checkout: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  products: getCartProducts(state),
  total: getTotal(state)
})

export default connect(
  mapStateToProps,
  { checkout }
)(CartContainer)
