import React, { Component } from "react";
import axios from "axios";
import { Segment, Image, Grid, Statistic } from "semantic-ui-react";
import product from "../images/dashboard/barcode.svg";

export default class ProductsCountDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      loading: true
    };
  }  

  getProductsDataFromDbByAccountId = (userid, user_type) => {
    const findPipe = userid.indexOf("|");
    const id = userid.substr(findPipe+1);
    let url = user_type === "ADMIN" ? 'https://api.dontwaste.me/products/' : `https://api.dontwaste.me/products/accounts/${id}`
    if (this.state.loading) {
      axios.get(`${url}`, {})
          .then(products => { this.setState({ products: products.data.products, loading: false }) })
          .catch(err => {console.log(err);});
    }
  };

  render() {
    return (
        <Grid.Column width={3} data={this.getProductsDataFromDbByAccountId(this.props.userid, this.props.user_type)}>
            <Segment raised placeholder>
                <Statistic>
                    <Statistic.Label><Image src={product} size="mini"/></Statistic.Label>
                    
                            <Statistic.Value>{this.state.products.length}</Statistic.Value>
                    
                    <Statistic.Label>{this.props.dashboard_box_products}</Statistic.Label>
                </Statistic>
            </Segment>
        </Grid.Column>
    );
  }
}
