
import React from 'react'
import VisualPickerOption from 'react-rainbow-components/components/VisualPickerOption';
import VisualPicker from 'react-rainbow-components/components/VisualPicker';
import {Image, Header} from 'semantic-ui-react'

import restaurant from "./restaurant.svg"
import supermarket from "./supermarket.svg"
import caffe from "./caffe.svg"
import producer from "./producer.svg"

import './styles.css'

const textStyles = {
    fontSize: 15,
    color: '#a4a7b5',
    marginTop: 4,
};

export default class MultipleVisualPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: ['BASIC'],
        };
        this.handleOnChange = this.handleOnChange.bind(this);
    }

    handleOnChange(value) {
        this.props.defineVendorType(value)
        return this.setState({ value });
    }

    render() {
        return (
            <>
            <Header as='h2' content={this.props.header_venue} textAlign="center" />
            <VisualPicker
                id="vendor-picker"
                value={this.state.value}
                onChange={this.handleOnChange}
                style = {{border: 'none'}}
            >
                <VisualPickerOption name="RESTAURANT">
                  <Image verticalAlign = "top" centered src = {restaurant} size = "tiny" style= {{marginBottom: '0.5em'}}/>
                    <h2 style={textStyles}>{this.props.icon_restaurant}</h2>
                </VisualPickerOption>
                <VisualPickerOption name="SUPERMARKET">
                <Image verticalAlign = "top" centered src = {supermarket} size = "tiny" style= {{marginBottom: '0.5em'}}/>
                    <h2 style={textStyles}>{this.props.icon_supermarket}</h2>
                </VisualPickerOption>
                <VisualPickerOption name="CAFFE">
                <Image verticalAlign = "top" centered src = {caffe} size = "tiny" style= {{marginBottom: '0.5em'}}/>
                    <h2 style={textStyles}>{this.props.icon_caffe}</h2>
                </VisualPickerOption>
                <VisualPickerOption name="PRODUCER">
                <Image verticalAlign = "top" centered src = {producer} size = "tiny" style= {{marginBottom: '0.5em'}}/>
                    <h2 style={textStyles}>{this.props.icon_producer}</h2>
                </VisualPickerOption>
            </VisualPicker>
            </>
        );
    }
}