import React from 'react'
import PropTypes from 'prop-types'
import { ReactReduxContext } from 'react-redux';
import Product from './Product'
import { Link } from 'react-router-dom'
import { Segment, Button } from 'semantic-ui-react'

const Cart  = ({ products, total, onCheckoutClicked, cartLocalStorage, totalLocalStorage, cart_no_products, cart_header, cart_total, total_currency, but_order, but_back }) => {
  console.log(products)
  //let total2 = [0];
  //const reducer = (accumulator, currentValue) => accumulator + currentValue;

  const hasProducts = products.length > 0;
  const hasProductsLocalStorage = cartLocalStorage ? Object.keys(cartLocalStorage.quantityById).length > 0 : null;
  const nodes = hasProducts ? (
    products.map(product =>
      <Product
        _id={product._id}
        name={product.name}
        place_name={product.place_name}
        reduced_price={product.reduced_price}
        quantity_available={product.quantity_available}
        key={product._id}
      />
    )
  ) : (
    <em>{cart_no_products}</em>
  )

  const nodesLocalStorage = hasProductsLocalStorage ? (
    Object.entries(cartLocalStorage.quantityById).map(product => 
      <Product 
        _id={product[0]}
        reduced_price={product[1].item_price}
        quantity_available={product[1].quantity}
        key={product[0]}
      />
    )
  ) : (
    <em>{cart_no_products}</em>
  )

 /*  const totalLocalStorage = hasProductsLocalStorage ? (
      Object.entries(cartLocalStorage.quantityById).map(item => {
        //console.log('item_price: ', item)
        //console.log(item[1].item_price)
        total2.push(item[1].item_price)
      }
    )
  ) : (
    null
  )
 */

  const getStateRedux = (store) => {
    if(store.cart.isCartPaid === 'no') {
      //console.log('isCartPaid if', store.cart.isCartPaid)
      //console.log('store.PaymentProvider', store.PaymentProvider)
      if(store.PaymentProvider === 'epaybg') {
        return false
      } else return true
    } else 
        //console.log('isCartPaid else', store.cart.isCartPaid)
        return false
  }

  const showCart = (store) => {
    if(store.cart.isCartPaid === 'no') {
      return 'block'
    } else 
        return 'none'
  }

  return (
    <div>
      <ReactReduxContext.Consumer>
      {({ store }) => (

        <div>
          <div style={{ display: showCart(store.getState()) }}>
            <h3>{cart_header}</h3>
            {
              hasProducts ? <div>{nodes}<p>{cart_total}: {total} {total_currency}</p></div> : <div>{nodesLocalStorage}<p>{cart_total}: {totalLocalStorage} {total_currency}</p></div>
            }
          </div>

          <Segment.Group horizontal>
                <Segment textAlign='left'><Link to={`/billing`}><Button secondary type='submit'>{but_back}</Button></Link></Segment>
                <Segment textAlign='right'>
                {
                  hasProducts || hasProductsLocalStorage ?
                          <Button primary onClick={onCheckoutClicked} disabled={getStateRedux(store.getState())}><Link to='/ordered'>{but_order}</Link></Button>
                    : <Button primary disabled={true}>{but_order}</Button>
                }
                </Segment>
          </Segment.Group>

        </div>
      )}
      </ReactReduxContext.Consumer>
    </div>
  )
}

Cart.propTypes = {
  products: PropTypes.array,
  total: PropTypes.string,
  onCheckoutClicked: PropTypes.func
}

export default Cart
