
import React, { PureComponent } from 'react';
import GMap from "react-rainbow-components/components/GMap";
import MapMarker from "react-rainbow-components/components/MapMarker";

export default class productMap extends PureComponent {

render () {
  return (<GMap
            apiKey={"AIzaSyDfeHv6QM9PqtzE_MM5O_X81ZUjwU_jR0Q"}
            zoom={10}
            latitude={this.props.latitude}
            longitude={this.props.longitude}
            className="rainbow_map"   
          >
            <MapMarker
              latitude={this.props.latitude}
              longitude={this.props.longitude}
              label={this.props.label}
              className="gmap"
            />
          </GMap>)
        }
}