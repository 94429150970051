import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import { clearCart, addToCart, removeFromCart } from '../reducers/productActions';
import { getTotal, getCartProducts, getPlaceAddedIds } from '../reducers/rootReducer';
import { Segment, Header, Icon, Button } from 'semantic-ui-react';

const CartPage = ({ products, total, places, cartLocalStorage, clearCart, addToCart, removeFromCart }) => {
  console.log(products)
  const hasProducts = products.length > 0;

  /* const saveState = (state) => {
    try {
      const serializedState = JSON.stringify(state);
      console.log('serializedStateSaveState: ', serializedState);
      localStorage.setItem('cart', serializedState);
    } catch {
      // ignore write errors
    }
  }; */

  const loadState = () => {
    //console.log('loadState: ', loadState)
    try {
      const serializedState = localStorage.getItem('persist:root');
      console.log('serializedStateLoadState: ', serializedState)
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (err) {
      return undefined;
    }
  };

  return (
    <div>
          <Header as='h2' icon textAlign='center'>
            {/* <Icon name='cart arrow down' circular /> */}
            <Segment style={{marginLeft: "1%", marginRight: "1%"}}>

              {
                  products.map(
                    product => (
                      product.quantity_available > 0 ?
                        <div>
                          <p>
                            {product.product_name} - {product.place_name} - BGN{parseFloat(product.reduced_price).toFixed(2)} x {product.quantity_available} pcs
                          </p>
                          <p></p>
                        </div>
                      : null
                    )
                  )
              }
              <p></p>
              {
                hasProducts ? <p>Total: &#36;{total}</p> 
                : 
                <div>{/* {nodesLocalStorage}<p>Total: &#36;{totalLocalStorage, total2.reduce(reducer).toFixed(2)}</p> */}</div>
              }
              
            </Segment>
          </Header>
    </div>
  )
}

CartPage.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string.isRequired,
    product_name: PropTypes.string.isRequired,
    place_name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    quantity_ordered: PropTypes.number.isRequired
  })).isRequired,
  total: PropTypes.string,
}

const mapStateToProps = (state) => ({
  products: getCartProducts(state),
  total: getTotal(state),
  places: getPlaceAddedIds(state)
})

export default connect(mapStateToProps, { clearCart, addToCart, removeFromCart })(CartPage)
