import React, { Component } from "react";
import axios from "axios";
import { Segment, Image, Grid, Statistic } from "semantic-ui-react";
import client from "../images/dashboard/network.svg";

export default class OrdersClientsDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
        orders: [],
        sumClients: [],
        loading: true
        };
    }  

    getOrdersByToken = (userid, user_type) => {
        const findPipe = userid.indexOf("|");
        const id = userid.substr(findPipe+1);
        let url = user_type === "ADMIN" ? 'https://api.dontwaste.me/orders/' : `https://api.dontwaste.me/orders/purchases/accounts/${id}`
        if (this.state.loading) {
            axios.get(`${url}`, {})
                .then(orders => { this.setState({ orders: orders.data.orders, loading: false }) })
                .catch(err => {console.log(err);});
        }
    };

    getTotalClients(value) {
        let counts = {};
        for(let i=0; i <= value; i++) {
            counts[value[i]] = 1 + (counts[value[i]] || 0)
        }
        //console.log('counts: ',counts)
    }

    getTotalClients2(value) {
        return new Set(value).size;
    }

    render() {
        return (
            <Grid.Column width={3} data={this.getOrdersByToken(this.props.userid, this.props.user_type)}>
                <Segment placeholder raised>
                    <Statistic>
                        <Statistic.Label>
                        <Image src={client} size="mini"/>
                        </Statistic.Label>
                                
                                <Statistic.Value>
                                        {
                                            this.state.orders.map(
                                                (order) => (
                                                    this.state.sumClients.push(order.account_id)
                                                )
                                            ),
                                            this.getTotalClients2(this.state.sumClients)
                                        }
                                </Statistic.Value>
                        
                        <Statistic.Label>{this.props.dashboard_box_clients}</Statistic.Label>
                    </Statistic>
                </Segment>
            </Grid.Column>
        );
    }
    }
