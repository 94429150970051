import React, { Component } from 'react'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Button, Header, Icon, Image, Modal } from 'semantic-ui-react'
import ImageImage from '../../pages/images/wireframe/image.png'
import ParagraphImage from '../../pages/images/wireframe/paragraph.png'

export default class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            loading: true,
            notifications: []
        }
    }
        
    show = dimmer => () => this.setState({ dimmer, open: true})
    close = () => this.setState({ open: false })

    notifySuccess = () => toast.success('Notification deleted');
    notifyError = () => toast.error('Notification error');

    getNotificationsByAccountId = (accountid) => {
        console.log("getNotificationsByAccountId")
        console.log(accountid)
        if (this.state.loading) {
            /* axios.get(`http://localhost:3004/${accountid}`) */
            axios.get(`https://api.dontwaste.me/info/${accountid}`)
                .then(res => this.setState({ notifications: res.data.notifications, loading: false }))
                .catch(err => {
                console.log(err);
                    this.notifyError()
                });
            }
    };

    getNotifications = () => {
        console.log('getNotifications')
        if (this.state.loading) {
            axios.get(`https://api.dontwaste.me/info/`)
                .then(res => this.setState({ notifications: res.data.notifications, loading: false }))
                .catch(err => {
                console.log(err);
                    this.notifyError()
                });
            }
    };

    deleteNotification = (id) => {
        console.log(`${id} is deleted`)
        axios.delete(`https://api.dontwaste.me/info/${id}`)
                .then(res => res.status = 204 ? this.notifySuccess() : null)
                .catch(err => {
                console.log(err);
                    this.notifyError()
                });
    }

    render() {
        const { open, dimmer,notifications } = this.state

        return (
            <Modal 
                trigger={
                    <Button onClick={this.show(true)} style={{backgroundColor: 'transparent', color: '#FFF'}}>
                        <Button.Content visible>
                            <Icon name='bell' size="large" color={notifications.length <= 0 ? "white" : "pink"} />
                        </Button.Content>
                    </Button>}
                dimmer={dimmer}
                open={open}
                onClose={this.close}
                closeIcon
            >
                <Modal.Header>Notification Center</Modal.Header>
                    <Modal.Content image scrolling>
                        {/* <Image size='medium' src={ImageImage} wrapped /> */}
                        <Modal.Description>
                            <Header>Notifications</Header>
                            {
                                this.props.user === "ADMIN" ? this.getNotifications() : this.getNotificationsByAccountId(this.props.userid)
                            }
                            {
                                notifications.length <= 0
                                    ?  
                                        (   <>
                                                <p>No notifications</p>
                                            </>
                                        )
                                    :
                                        Object.values(notifications).map(
                                            (notif, i) => (
                                                <p>{i+1}. {notif.content} <Icon link name="delete" onClick={() => (this.deleteNotification(notif._id), this.setState({ loading: true }))}></Icon></p>
                                            )
                                        )
                            }
                        </Modal.Description>
                    </Modal.Content>
                    {/* <Modal.Actions>
                        <Button primary onClick={this.close}>
                            Close <Icon name='chevron right' />
                        </Button>
                    </Modal.Actions> */}
            </Modal>
        )
    }
}