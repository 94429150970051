import React, { Component } from "react";
import axios from "axios";
import { Segment, Image, Grid, Statistic } from "semantic-ui-react";
import waste from "../images/dashboard/planet-earth.svg"

var coSavedFactor = 1

export default class StoresCO2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
        orders: [],
        loading: true
        };
    }  

    getOrdersByToken = (userid, user_type) => {
        const findPipe = userid.indexOf("|");
        const id = userid.substr(findPipe+1);
        let url = user_type === "ADMIN" ? 'https://api.dontwaste.me/orders/' : `https://api.dontwaste.me/orders/accounts/${id}`
        if (this.state.loading) {
            axios.get(`${url}`, {})
                .then(orders => { this.setState({ orders: orders.data.orders, loading: false }) })
                .catch(err => {console.log(err);});
        }
    };

    calculateFoodWaste() {
        //needs to be .nickname but not working
        var randFMultiplier = (12/8)
        var coSaved = this.state.orders.length*coSavedFactor*randFMultiplier.toFixed(2)
        //console.log('ccoSaved', coSaved)
        return coSaved
    }

    render() {
        return (
            <Grid.Column width={3} data={this.getOrdersByToken(this.props.userid, this.props.user_type)}>
                <Segment placeholder raised>
                    <Statistic>
                        <Statistic.Label>
                            <Image src={waste} size="mini"/>
                        </Statistic.Label>
                        
                                <Statistic.Value>{this.calculateFoodWaste()} {this.props.dashboard_box_co2_kg}</Statistic.Value>
                        
                        <Statistic.Label>{this.props.dashboard_box_co2}</Statistic.Label>
                    </Statistic>
                </Segment>
            </Grid.Column>
        );
    }
    }
