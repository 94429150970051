import React from "react";
import axios from 'axios';
import { ReactiveBase } from "@appbaseio/reactivesearch";
import { ReactiveGoogleMap } from "@appbaseio/reactivemaps";
import { Link } from "react-router-dom";
import { Grid, Card, Image, Label, Icon, Header, Modal, Segment, Button, Divider } from "semantic-ui-react";
import "./mapstyles.css";
import * as moment from "moment";
import HomeProductNoVendorItemsButton from "../components/HomeProduct/index_no_itemsVendorButton";
import no_results from "../components/images/empty/no_results.png";
import YouTube from 'react-youtube';

const providers = [
  {
    label: "Google Map",
    value: "googleMap"
  },
  {
    label: "OpenStreet Map",
    value: "openstreetMap"
  }
];

export default class MapPageItemsByVendor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mapProvider: providers[0],
      latitude: 42.069325,
      longitude: 25.606698,
      loading: true,
      loadingLat: true,
      loadingLng: true,
      noProducts: true,
      noResults: false,
      selectedProduct: [],
      productClicked: false, 
      account_info: [],
      product_info: [],
      place_info: [],
      inventory_info: [],
      dist: Number,
      PlaceID: '',
      isSetVendorId: false,
      vendorID: '',
    };
    this.setProvider = this.setProvider.bind(this);
  }

  componentDidMount() {
    navigator.geolocation.getCurrentPosition(
      this.geo_lat,
      this.geo_error,
      this.geo_options
    );
    navigator.geolocation.getCurrentPosition(
      this.geo_lng,
      this.geo_error,
      this.geo_options
    );
    navigator.geolocation.getCurrentPosition(
      this.geo_success,
      this.geo_error,
      this.geo_options
    );
    console.log("component did mount")
    fetch(`https://api.dontwaste.me/places/${this.getPlaceID()}`)
      .then(res => res.json())
      .then(res => (
        console.log('res', res.place),
        this.setState({ place_info: res.place, loading: false }, console.log('place info set state'))))
  }

  calculateTimeRemaining(timestamp, id) {
    const divided = timestamp / 1000;
    var date = moment.unix(divided)
    var displayDate = moment().isAfter(date) ? 
      (
        this.deleteItem(id)
      )
      : date.fromNow(true) + " remaining";
    return displayDate;
  }

  async deleteItem(itemid) {
    //this.setState({ openActionDelete: false });
    //console.log(itemid)
    await axios.delete(`https://api.dontwaste.me/inventories/exp/${itemid}`)
    //.then ( this.props.deleteCallback(itemid), console.log('update id: ', itemid))
    // this.setState({ loading: true });
  }

  calculatePickUpTime(timestamp) {
    const divided = timestamp / 1000;
    var date = moment.unix(divided).format("dddd, MMMM Do YYYY, h:mm:ss a");
    return date;
  }
  geo_lat = position => {
    this.setState({ latitude: position.coords.latitude, loadingLat: false });
    //console.log("geo_lat= ", this.state.latitude);
  };

  geo_lng = position => {
    this.setState({ longitude: position.coords.longitude, loadingLng: false });
    //console.log("geo_lng= ", this.state.longitude);
  };

  geo_success(position) {
    return (
      position.coords.latitude,
      position.coords.longitude
      //console.log("lat= ", position.coords.latitude, "lon= ", position.coords.longitude)
    );
  }

  geo_error() {
    alert("Sorry, no position available.");
  }

  geo_options(enableHighAccuracy, maximumAge, timeout) {
    return (
      (enableHighAccuracy = false), (maximumAge = 30000), (timeout = 27000)
    );
  }

  setProvider(mapProvider) {
    this.setState({
      mapProvider
    });
  }
  
  handleCardClick = (product) => {
    fetch(`https://api.dontwaste.me/inventories/${product.item_id}`)
          .then(res => res.json())
          .then(res => 
            this.setState({ inventory_info: res.item, product_info: res.itemProduct[0], place_info: res.itemPlace[0] },
            /* this.fetchProductInfo(res.item.product_id),
            this.fetchPlaceInfo(res.item.place_id),
            this.fetchAccountInfo(res.item.account_id), */
            this._findDistance(product.location)))
          .then(() => this.setState({ productClicked: true, selectedProduct: product }))
  };

  /* fetchProductInfo = (id) => {
    axios.get(`https://api.dontwaste.me/products/${id}`)
    .then((res) => this.setState({product_info: res.data.product}))
  }; */

  /* fetchPlaceInfo = (id) => {
    axios.get(`https://api.dontwaste.me/places/${id}`)
    .then((res) => this.setState({place_info: res.data.place}))
  }; */

  /* fetchAccountInfo = (id) => {
    axios.get(`https://api.dontwaste.me/accounts/${id}`)
    .then((res) => this.setState({ account_info: res.data.account}))
  }; */

  _findDistance(location) {
    var lat1 = this.state.latitude;
    var lon1 = this.state.longitude;
    var lat2 = location[1];
    var lon2 = location[0];
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    dist = dist * 1.609344;
    dist = dist.toFixed(1);
    this.setState({ dist: dist })
  }

  GoBack = () => {
    this.setState({ productClicked: false, product_info: [], place_info: [], account_info: [], inventory_info: [] })
  };
  
  defaultQuery() {
    return {
      query: {
        match: {
          "place_id": this.getPlaceID()
        }
      }
    }
  };

  getPlaceID = function () {
    console.log()
    let subUrl = window.location.pathname.substring(15)
    console.log('subUrl: ', subUrl)
    return subUrl
  }

  render() {
    const { selectedProduct, productClicked, loading } = this.state
    const { product_info, place_info, inventory_info } = this.state
    const { name, description, location, address, contact, hours, place_image_url } = this.state.place_info
    return (

      // window.location.pathname.includes("/itemsbyvendor/") ?
      !loading &&
        <ReactiveBase
          app="items2"
          url="https://el.veloxoft.com:444"
          credentials="pepo:Parolata123"
          theme={{
            colors: {
              primaryColor: "#e9707d"
            }
          }}
        >
        {console.log('props item by vendor', this.state.place_info)}
          <Grid className="home_wrapper" stackable>
            {/* <Grid.Column width={3} className="filters_wrapper">
              <Filters />
            </Grid.Column> */}
              <Grid.Column width={16} className="map_results_wrapper">
                <ReactiveGoogleMap
                  componentId="ItemsByVendor"
                  defaultQuery={
                    () => {
                      return {
                        query: {
                          match: {
                            "place_id": this.getPlaceID()
                          }
                        }
                      }
                    }
                  }
                  defaultMapStyle="Midnight Commander"
                  title="FoodChain Map"
                  dataField="location"
                  size={100}
                  defaultZoom={6}
                  defaultRadius={10}
                  unit="km"
                  showMapStyles
                  showMarkerClusters
                  showSearchAsMove
                  searchAsMove
                  innerClass={{
                    checkboxContainer: 'map_checkbox',
                    list: "map_list",
                    checkbox: "map_checkbox_other",
                    select: "map_select",
                    icon: "map_icon",
                    button: "map_button",
                    active: "map_active",
                    paginaiton: "map_pagination"
                  }}
                  
                
                  renderAllData={(
                    hits,
                    streamHits,
                    loadMore,
                    renderMap,
                    renderPagination
                  ) => {
                    return (

                      <div>
                        <Grid className="map_results" stackable>
                        <Grid.Column width={4} style={{marginTop:'1%'}}>
                          <Image src={place_image_url ? place_image_url : "https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/places%2F3580748650526e31e62cb43ce82ad2319f305e22.png?alt=media&token=7d025195-08e4-431a-9dd1-4de721a3f059"} />
                        </Grid.Column>
                        <Grid.Column width={9} style={{marginTop:'3%'}}>
                          <Header as='h1' style={{ margin: "0.5rem 0rem 1rem" }}>{name}
                              {description && <Header.Subheader as='h3' color='blue'><Icon name='location arrow' color="blue" />{description}</Header.Subheader>}
                            </Header>
                            <Header.Subheader>
                              <p>Looks like there are no food items around you :( </p>
                              FoodChain vendors are busy uploading their left-over food, so be sure to check again soon
                              FoodChain vendors are busy uploading their left-over food, so be sure to check again soon
                              FoodChain vendors are busy uploading their left-over food, so be sure to check again soon
                              FoodChain vendors are busy uploading their left-over food, so be sure to check again soon
                              FoodChain vendors are busy uploading their left-over food, so be sure to check again soon
                              FoodChain vendors are busy uploading their left-over food, so be sure to check again soon
                          </Header.Subheader>
                        </Grid.Column>
                        <Grid.Column width={3} style={{marginTop:'3%'}}>
                          <Image src={place_image_url ? place_image_url : "https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/places%2FQR-Kaufland-1.png?alt=media&token=420a6a0a-778d-47ed-95f8-b18fb1f549c6"}
                            style={{
                              width: "50%",
                              display: "flex",
                              marginLeft: "auto",
                              marginRight: "auto"
                            }} />
                        </Grid.Column>
                      </Grid>

                      <Divider horizontal>
                        <Header as='h4'>
                          <Icon name='food' />
                            Данни за продукта
                        </Header>
                      </Divider>

                      <Grid columns={3} divided style={{marginLeft: '1%'}}>
                        <Grid.Row>
                          <Grid.Column>
                            <Header as='h4' content='Номер партида' subheader='1234/01.08.2023' />
                          </Grid.Column>
                          <Grid.Column>
                            <Header as='h4' content='Дата на бране' subheader='01.08.2023' />
                          </Grid.Column>
                          <Grid.Column>
                            <Header as='h4' content='Пакетирано на' subheader='02.08.2023' />
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column>
                            <Header as='h4' content='Защита' subheader='Mancozeb' />
                          </Grid.Column>
                          <Grid.Column>
                            <Header as='h4' content='Хранителни вещества' subheader='Протеини: 0,88гр.' />
                          </Grid.Column>
                          <Grid.Column>
                            <Header as='h4' content='Локация на фермата' subheader='с. Лисичово, гр. Пазарджик' />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>

                      <Divider horizontal>
                        <Header as='h4'>
                          <Icon name='image' />
                            Галерия
                        </Header>
                      </Divider>
                        
                      <Grid stackable>
                        <Grid.Row columns={3} style={{textAlign: "-moz-center"}}>
                          <Grid.Column>
                            <Image src={place_image_url ? place_image_url : "https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/kaufland%2Fk-1.png?alt=media&token=ce05b008-3f7a-453f-9068-2c7be804414f"} />
                          </Grid.Column>
                          <Grid.Column>
                            <Image src={place_image_url ? place_image_url : "https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/kaufland%2Fk-2.png?alt=media&token=c6f9660c-ed3e-49a0-a7c0-890def975e2a"} />
                          </Grid.Column>
                          <Grid.Column>
                            <Image src={place_image_url ? place_image_url : "https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/kaufland%2Fk-3.png?alt=media&token=449871b6-d446-4bc0-94e7-e2d03b8281df"} />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>

                      <Divider horizontal>
                        <Header as='h4'>
                          <Icon name='video' />
                            Видео
                        </Header>
                      </Divider>

                      <Grid columns={2} stackable style={{textAlign: "-moz-center"}}>
                        <Grid.Column>
                          <YouTube className="embed-container" videoId="KURpVzltNAE" onReady={this._onReady} />
                        </Grid.Column>
                        <Grid.Column>
                          <YouTube className="embed-container" videoId="w-EGVDV22mI" onReady={this._onReady} />
                        </Grid.Column>
                      </Grid>

                      <Divider horizontal>
                        <Header as='h4'>
                          <Icon name='product hunt' />
                            Наши Продукти
                        </Header>
                      </Divider>

                      <Grid columns={2} stackable style={{textAlign: "-moz-center"}}>
                      <Grid.Column width={10} className="results_wrapper" >
                          <Header as="h1">
                        {/* Also on sale at the same place: */}
                              </Header>
                              <Card.Group doubling itemsPerRow={3} stackable style={{ marginLeft: '0px', marginRight: '0px' }}>
                              {hits.map(data => (
                                hits.length > 0 ?
                                          <Card
                                            raised
                                            link
                                            key={data.id}
                                            className="product_card"
                                            onClick={() => this.handleCardClick(data)}
                                          >
                                            <Image
                                              size="medium"
                                              src={data.image}
                                              ui={false}
                                              centered
                                              className="center_crop_small_card"
                                            />
                                            <Label attached="top right" color="red">
                                              <Icon name="hourglass end" />
                                              {this.calculateTimeRemaining(
                                                data.item_exp, data._id
                                              )}
                                            </Label>

                                            <Card.Content>
                                              <Card.Header className="card_small_header">
                                                {data.name}
                                              </Card.Header>
                                              <Card.Meta>
                                                <Icon name="globe" />
                                                {data.place_name}
                                              </Card.Meta>
                                              <Card.Description>
                                                <Label basic size="tiny">
                                                  Original: {data.original_price}$
                                                </Label>
                                                <Label color="green" size="medium">
                                                  Reduced: {data.reduced_price}$
                                                </Label>
                                              </Card.Description>
                                            </Card.Content>
                                            <Card.Content extra>
                                              <p>
                                                <Icon name="tags" />
                                                Quantity available:{" "}
                                                {data.quantity_available}
                                              </p>
                                              <p>
                                                <Icon name="clock" /> Collect before:{" "}
                                                {this.calculatePickUpTime(
                                                  data.item_exp
                                                )}
                                              </p>
                                            </Card.Content>
                                          </Card>
                                :
                                <>
                                  <Image src={no_results} size="large" centered verticalAlign="middle" className="no_results_image" />
                                  <Header as="h2" textAlign="center">No results
                                  <Header.Subheader>
                                    <p>Looks like there are no food items around you :( </p>
                                    FoodChain vendors are busy uploading their left-over food, so be sure to check again soon
                                  </Header.Subheader>
                                  </Header>
                                </>
                              ))}
                            </Card.Group>
                          
                          </Grid.Column>
                        
                  </Grid>
                </div>
                      )
                    }
                  }

                  
                  renderError={error => (
                    <div>
                      Something went wrong!
                      <br />
                      Error details
                      <br />
                      {error}
                    </div>
                  )}
                  
                />
              </Grid.Column>
          </Grid>
          <Modal className="product_modal" open={productClicked} onClose={() => this.GoBack()}>
            <HomeProductNoVendorItemsButton
              //console={console.log('inventory_info: ',inventory_info)}
              product={product_info}
              inventory={inventory_info}
              productClicked={productClicked}
              item={selectedProduct}
              place={place_info}
              dist={this.state.dist}
              handleGoBack={this.receiveGoBack}
              offers_time_remaining={this.props.offers_time_remaining}
              offers_area_org_price={this.props.offers_area_org_price}
              offers_area_red_price={this.props.offers_area_red_price}
              offers_area_qty={this.props.offers_area_qty}
              offers_area_timе={this.props.offers_area_timе}
              offers_area_no_res={this.props.offers_area_no_res}
              оffers_area_no_res_text={this.props.оffers_area_no_res_text}
              filters_price_range_currency={this.props.filters_price_range_currency}
              product_detail_qty_choose= {this.props.product_detail_qty_choose}
              product_detail_qty_available= {this.props.product_detail_qty_available}
              product_detail_cart_but_text= {this.props.product_detail_cart_but_text}
              product_detail_cart_sold_out= {this.props.product_detail_cart_sold_out}
              product_detail_report= {this.props.product_detail_report}
              product_detail_env_header= {this.props.product_detail_env_header}
              product_detail_env_co2= {this.props.product_detail_env_co2}
              product_detail_env_water= {this.props.product_detail_env_water}
              product_detail_away= {this.props.product_detail_away}
              product_detail_see_all= {this.props.product_detail_see_all}
              product_detail_buy_before= {this.props.product_detail_buy_before}
              product_detail_pick_up_before= {this.props.product_detail_pick_up_before}
              product_detail_desc= {this.props.product_detail_desc}
              product_detail_cat_type= {this.props.product_detail_cat_type}
              product_detail_delivery= {this.props.product_detail_delivery}
              product_detail_pick_by= {this.props.product_detail_pick_by}
              product_detail_sold_by= {this.props.product_detail_sold_by}
              product_detail_vendor_desc= {this.props.product_detail_vendor_desc}
              product_detail_place= {this.props.product_detail_place}
              product_detail_but_show_more= {this.props.product_detail_but_show_more}
              product_detail_but_show_less= {this.props.product_detail_but_show_less}
            />
          </Modal>
          {/* {console.log('clicked state in product: ', productClicked)} */}
        </ReactiveBase>
    )
  }
}
