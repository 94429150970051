import firebase from "firebase";
var firebaseConfig = {
    apiKey: "AIzaSyAzJBKcCTgX1u0RNBcC97AEEKo0GjsB0cA",
    authDomain: "foodchainapp-1531482755524.firebaseapp.com",
    databaseURL: "https://foodchainapp-1531482755524.firebaseio.com",
    projectId: "foodchainapp-1531482755524",
    storageBucket: "foodchainapp-1531482755524.appspot.com",
    messagingSenderId: "405510406126",
    appId: "1:405510406126:web:6c0be95a23cccca592d8ff"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);