import React, { Component } from "react";
//import update from 'immutability-helper';
import { ReactReduxContext } from 'react-redux';
import axios from "axios";
import LocalizedStrings from 'react-localization';
import { ToastContainer, toast } from 'react-toastify';
import { fetchProductsAfterUpdateItem } from "../../reducers/productActions";
import Colors from '../../constants/Colors'
import 'react-toastify/dist/ReactToastify.min.css';
import _ from "lodash";
import {
  Icon,
  Button,
  Modal,
  Header,
  Form,
  Card,
  Loader,
  Dimmer,
  Item,
  Grid,
  TextArea,
  Divider,
  Image,
  Statistic,
  Segment,
  Label
} from "semantic-ui-react";
import ItemCard from "./itemCard";
import DatePicker from "react-rainbow-components/components/DatePicker";
import TimePicker from "react-rainbow-components/components/TimePicker";
import Application from "react-rainbow-components/components/Application";
//import * as moment from 'moment'
import discount_tag from "../images/dashboard/discount-tag.svg";
import favorite from "../images/dashboard/favorite.svg";
import no_purchases from "../images/empty/no_purchases.png";
import "./styles.css";

// THERE IS BUG WITH CONTINOUS LOADING ON ACCOUNTS WITH NO PLACESS
const theme = {
  rainbow: {
    palette: {
      brand: Colors.fcDarkGreen,
    },
  },
};
export default class InventoriesBasedOnStores extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      places: [],
      places_ids: [],
      inventories: [],
      item: [],
      placesDropdown: [],
      dealtypeDropdown: [
        {
          key: 'PERSONAL',
          text: 'PERSONAL',
          value: 'PERSONAL',
        },
        {
          key: 'BUSINESS',
          text: 'BUSINESS',
          value: 'BUSINESS',
        }
      ],
      dealtypeDropdownBG: [
        {
          key: 'PERSONAL',
          text: 'ПОТРЕБИТЕЛ',
          value: 'PERSONAL',
        },
        {
          key: 'BUSINESS',
          text: 'БИЗНЕС',
          value: 'BUSINESS',
        }
      ],
      selectedProduct: null,
      selectedPlace: null,
      selectedPlaceValue: '',
      selectedDealTypeValue: 'BUSINESS',
      selectedProductloading: true,
      selectedProductValue: "",
      fetchedProducts: [],
      products: [],
      loading: true,
      loadingPlaces: true,
      loadingProducts: true,
      newItemAgreeTerms: false,
      newItemQyAv: 0,
      newItemSaveTemp: 0,
      newItemTransTemp: 0,
      newItemOrgPrice: 2,
      newItemRedPrice: 1,
      newItemExDate: new Date(),
      newItemPackDate: new Date(),
      newItemHarvDate: new Date(),
      newItemSaveDate: new Date(),
      newItemTransDate: new Date(),
      newItemExTime: "21:30",
      created: new Date(),
      place_id: "5d80bc09832f3587250335a8",
      product_id: "5d8c6dfb19aebf5e05ba6236",
      fetch_needed: false
    };
  }

  strings = new LocalizedStrings({
    en:{
      header: "Inventories",
      header_desc: "This is where the magic happens! Choose the store where you have left-over food, select the product, and set the reduced price and pick-up time for the customer. You can monitor the items that you have sold and adjust the price and quantity available if necessary.",
      inv_box: "Items on sale",
      perc_box: "of Items sold",
      no_inv: "You have no items on sale now!",
      button_edit: "Edit",
      button_delete: "Delete",
      button_add_inv: "Add Item",
      place: "Choose a place",
      place_label: "Where do you have left-over food?",
      prod: "Choose one of your existing products",
      prod_label: "What product is expiring?",
      prod_det: "Product details",
      weight: "Select the weight of your product",
      sh_desc: "Short description",
      desc: "This description will be shown to clients on our platform. Introduce your business in a couple of sentences and make it stand out!",
      aller: "Allergens",
      aller_pl: "Allergens...",
      off_det: "Offer details",
      prod_name: "Product name",
      price_org: "Original Price",
      part_number: "Part Number",
      price_org_hold: "10 BGN",
      price: "Reduced Price",
      price_hold: "5 BGN",
      qty: "Quantity Available",
      qty_hold: "Item quantity",
      deal: "Deal Type",
      deal_hold: "Deal Type",
      exp: "Choose the expiry date",
      time: "Choose the pick-up time",
      notifyItemCreated: "Success! Your Item is created.",
      notifyItemUpdate: "Success! Your Item is updated.",
      notifyItemDeleted: "Item DELETED!",
      notifyError: "Something went wrong!",
      available_qty: 'Available quantity',
      org_price: 'Original price',
      reduced_price: 'Reduced price',
      created: 'Created',
      deal_type: 'Deal type',
      expires: 'Expires in',
      currency: 'USD',
      edit_item: 'Edit item',
      upload_text: 'Upload image for your item',
      add_image: 'Add Image',
      item_details: 'Item details',
      are_you_update: 'Are you ready to update the item?',
      no: 'No',
      yes: 'Yes',
      delete: 'Delete',
      are_you_delete: 'Are you sure you want to delete this item?',
      edit_qty_label: 'Item Quantity Available',
      edit_price_label: 'Item Reduced Price',
      terms: 'I agree to the Terms and Conditions',
      update_button: 'Update Item',
      sell_item: 'Sell Item',
      deal_personal: 'PERSONAL',
      deal_business: 'BUSINESS'
    },
    bg: {
      header: "Инвентар",
      header_desc: "Тук се случва магията! Избере магазина, в който имате храна, изберете продукт и определете намалена цена заедно с време за взимане от клиента. Можете да следите стоките, които продавате и да променяте тяхната цена и количество.",
      inv_box: "Налични стоки за продажба",
      perc_box: "стоки продадени",
      no_inv: "Нямате стоки все още!",
      button_edit: "Промени",
      button_delete: "Изтрий",
      button_add_inv: "Добави стока",
      place: "Изберете ресторант",
      place_label: "Къде имате останала храна?",
      prod: "Изберете продуктова група",
      prod_label: "Кой продукт е с изтичащ срок на годност?",
      prod_det: "Детайли за продукта",
      weight: "Тегло на продукта",
      sh_desc: "Кратко описание",
      desc: "Това описание ще видят вашите клиенти. Напишете нещо интересно!",
      aller: "Алергени",
      aller_pl: "Алергени...",
      off_det: "Детайли на офертата",
      prod_name: "Име на продукта",
      price_org: "Стандартна цена",
      part_number: "Номер Партида",
      price_org_hold: "10 лв.",
      price: "Намалена цена",
      price_hold: "5 лв.",
      qty: "Налично количество",
      qty_hold: "Брой оферти",
      deal: "Тип на офертата",
      deal_hold: "Тип на офертата",
      exp: "Срок на годност",
      time: "Час на годност",
      notifyItemCreated: "Успешно! Офертата е създадена.",
      notifyItemUpdate: "Успешно! Офертата е променена.",
      notifyItemDeleted: "Офертата е изтрита!",
      notifyError: "Нещо се обърка!",
      available_qty: 'Налично количество',
      org_price: 'Регулярна цена',
      reduced_price: 'Намалена цена',
      created: 'Създаден',
      deal_type: 'Тип на сделката',
      expires: 'Изтича',
      currency: 'лв.',
      edit_item: 'Промяна инвентар',
      upload_text: 'Качване на снимка',
      add_image: 'Качи снимка',
      item_details: 'Детайли на инвентар',
      are_you_update: 'Искате ли да промените продукта?',
      no: 'Не',
      yes: 'Да',
      delete: 'Изтрий',
      are_you_delete: 'Искате ли да изтриете продукта?',
      edit_qty_label: 'Ново количество',
      edit_price_label: 'Нова намалена цена',
      terms: 'Съгласен съм с условията на платформата FoodChain',
      update_button: 'Промени',
      sell_item: 'Продажба на продукт',
      deal_personal: 'ПОТРЕБИТЕЛ',
      deal_business: 'БИЗНЕС'
    }
   });

   _setLanguage = (lang) => {
    //console.log(lang)
    this.strings.setLanguage(lang)
  }

  getPlaces = async userid => {
    if (this.state.loadingPlaces) {
      await fetch(`https://api.dontwaste.me/places/accounts/${userid}`)
        .then(res => res.json())
        .then(res => (
          this.setState({ places: res.places, loadingPlaces: false }),
          this.mapPlacesforDropdown(res.places)
        )
        )
        .catch(err => {
          console.log(err);
          this.notifyError();
        });
    }
  }

  getPlacesAll = async () => {
    if (this.state.loadingPlaces) {
      await fetch(`https://api.dontwaste.me/places/`)
        .then(res => res.json())
        .then(res => (
          this.setState({ places: res.places, loadingPlaces: false }),
          this.mapPlacesforDropdown(res.places)
        )
        )
        .catch(err => {
          console.log(err);
          this.notifyError();
        });
    }
  }

  getPlacesIds = (userid, userrole) => {
    userrole === "ADMIN" ? this.getPlacesAll() : this.getPlaces(userid);
    Object.values(this.state.places).map((place, i) =>
      this.state.places_ids.push(place._id)
    );
    // console.log("(this.state.places_ids: ", this.state.places_ids);
  };

  getItems = (userid, userrole) => {
    if (this.state.loading) {
      this.getPlacesIds(userid, userrole)
      console.log(userrole)
      userrole === "ADMIN" ? this.getProductsData() : this.getProductsDataFromDbByAccountId(userid);
      console.log("this.state.places_ids", this.state.places_ids)
      this.state.places_ids.map(async id =>
        await fetch(`https://api.dontwaste.me/inventories/places/${id}`)
          .then(res => res.json())
          .then(res => res.items)
          .then(res => res === undefined ? null : this.setState(prevState => ({
            inventories: [...prevState.inventories, ...res]
          }), console.log('inventory to place id:', id, "and inventory", this.state.inventories)))
          .then(this.setState({ loading: false }))
          .catch(err => {
            console.log(err);
            this.notifyError();
          })
      );
    }
  };
  getProductsDataFromDbByAccountId = userid => {
    if (this.state.loadingProducts) {
      fetch(`https://api.dontwaste.me/products/accounts/${userid}`)
        .then(res => res.json())
        .then(res => this.mapProductsforDropdown(res.products))
        .then(() => this.setState({ loadingProducts: false }))
        .catch(err => {
          console.log(err);
          this.notifyError()
        })
    }
  };

  getProductsData = () => {
    if (this.state.loadingProducts) {
      fetch(`https://api.dontwaste.me/products/`)
        .then(res => res.json())
        .then(res => this.mapProductsforDropdown(res.products))
        .then(() => this.setState({ loadingProducts: false }))
        .catch(err => {
          console.log(err);
          this.notifyError()
        })
    }
  };

  handleProductChange = (e, { value }) => {
    this.setState({ selectedProductValue: value });
    fetch(`https://api.dontwaste.me/products/${value}`)
      .then(res => res.json())
      .then(res => this.setState({ selectedProduct: res.product }))
      .then(() => this.setState({ selectedProductloading: false }))
      .catch(err => {
        console.log(err);
        this.notifyError()
      })
  };

  handlePlaceChange = (e, { value }) => {
    this.setState({ selectedPlaceValue: value })
  }

  handleDealTypeChange = (e, { value }) => {
    this.setState({ selectedDealTypeValue: value })
  }

  mapProductsforDropdown = fetchedProducts => {
    const products = _.map(fetchedProducts, (product, index) => ({
      key: product._id,
      text: product.name,
      value: product._id,
      image: { avatar: true, src: product.image }
    }));
    this.setState({ products: products });
  };
  mapPlacesforDropdown = places => {
    const placesDropdown = _.map(places, (place, index) => ({
      key: place._id,
      text: place.name,
      value: place._id,
    }));
    this.setState({ placesDropdown: placesDropdown });
  };
  getItemsArray = (userid, userrole) => {
    const findPipe = userid.indexOf("|");
    const id = userid.substr(findPipe + 1);
    this.getItems(id, userrole);
    Object.values(this.state.inventories).map((item, i) =>
      this.state.item.push(item._id)
    );
    // console.log('(this.state.item: ',this.state.item)
    // console.log('(items._id.length: ',this.state.item.length)
  };

  closeConfigShow = (closeOnEscape, closeOnDimmerClick) => () => {
    this.setState({ closeOnEscape, closeOnDimmerClick, open: true });
  };

  close = () => this.setState({ open: false });

  handleChangeCheckBox = e =>
    this.setState(({ newItemAgreeTerms }) => ({
      newItemAgreeTerms: !newItemAgreeTerms
    }));

  handleChange = (e, { value }) => this.setState({ newItemUnits: value });

  async saveItemToDB(
    account_id,
    quantity,
    original_price,
    reduce_price,
    exp_date,
    exp_time,
    part_number,
    pack_date,
    harv_date,
    save_date,
    save_temp,
    trans_date,
    trans_temp
  ) {
    const findPipe = account_id.indexOf("|");
    const id = account_id.substr(findPipe + 1);
    this.setState({ open: false });
    this.setState({ newItemAgreeTerms: false });
    console.log('exp_time: ', exp_time)
    const hours = exp_time.substr(0, 2)
    const minutes = exp_time.substr(3, 4)
    exp_date.setMinutes(minutes)
    exp_date.setHours(hours)

    const calculatedTimestamp = exp_date.getTime()
    console.log("calculatedTimestamp", calculatedTimestamp)
    let selectedPlace = this.state.places.find(place => place.id = this.state.selectedPlaceValue)



    await axios.post(
      "https://api.dontwaste.me/inventories/",
      //"http://localhost:3004/",
      {
        account_id: id,
        place_id: this.state.selectedPlaceValue,
        place_name: selectedPlace.name,
        product_id: this.state.selectedProductValue,
        product_name: this.state.selectedProduct.name,
        quantity_available: quantity,
        original_price: original_price,
        reduced_price: reduce_price,
        deal_type: this.state.selectedDealTypeValue,
        expiry_date: calculatedTimestamp,
        part_number: part_number,
        pack_date: pack_date.getTime(),
        harvest_date: harv_date.getTime(),
        save_date: save_date.getTime(),
        save_temp: save_temp,
        transport_date: trans_date.getTime(),
        transport_temp: trans_temp,
      },
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
      .then(res => res.data.item._id)
      .then(id =>
        fetch(`https://api.dontwaste.me/inventories/${id}`)
          .then(res => res.json())
          .then(res => res.item)
          .then(res => (this.setState((prevState) => ({ inventories: [...prevState.inventories, res], fetch_needed: true })), this.notifyItemCreated()))
          .catch(err => {
            console.log(err);
            this.notifyError()
          })
      )
      .catch(err => {
        console.log(err);
        this.notifyError()
      });
  }

  ItemCardUpdateCallback = (id, reduced_price_edit, quantity_available_edit) => {
    var index = this.state.inventories.findIndex(inventory => inventory._id === id)
    console.log("index", index)
    let inventoriesToEdit = [...this.state.inventories]
    let inventoryToEdit = { ...inventoriesToEdit[index] }
    inventoryToEdit.reduced_price = reduced_price_edit
    inventoryToEdit.quantity_available = quantity_available_edit
    inventoriesToEdit[index] = inventoryToEdit
    this.setState({ inventories: inventoriesToEdit })
    this.notifyItemUpdate();
  };

  ItemCardDeleteCallback = (id) => {
    console.log('current state:', this.state.inventories)

    this.setState(prevState => ({ inventories: prevState.inventories.filter(inventory => inventory._id !== id) }))
    this.notifyItemDeleted();
    console.log('new state', this.state.inventories)
  }

  notifyItemCreated = () => toast.success(this.strings.notifyItemCreated);
  notifyItemUpdate = () => toast.info(this.strings.notifyItemUpdate);
  notifyItemDeleted = () => toast.warn(this.strings.notifyItemDeleted);
  notifyError = () => toast.error(this.strings.notifyError);

  render() {
    const {
      inventories,
      open,
      closeOnEscape,
      closeOnDimmerClick,
      loading
    } = this.state;

    const {
      products,
      selectedProduct,
      selectedProductloading,
      selectedProductValue,
      newItemExDate,
      newItemPackDate,
      newItemHarvDate,
      newItemSaveDate,
      newItemTransDate,
      newItemExTime,
      created,
      selectedPlaceValue,
      placesDropdown,
      dealtypeDropdown,
      dealtypeDropdownBG,
      selectedDealTypeValue,
      fetch_needed
    } = this.state;

    return (
      
          <div>
          {this._setLanguage(this.props.user["https://fc-demo/language"])}
            <ToastContainer />
            {
              fetch_needed ?
                <ReactReduxContext.Consumer>
                  {({ store }) => {
                    console.log('ReactReduxContext')
                    store.dispatch(fetchProductsAfterUpdateItem())
                    this.setState({ fetch_needed: false })
                  }}
                </ReactReduxContext.Consumer>
                : null
            }
            <Dimmer active={loading} inverted data={this.getItemsArray(this.props.user.sub, this.props.user["https://fc-demo/role"])}>
              <Loader inverted content="Loading" />
            </Dimmer>
            {console.log("inventories", inventories)}
            <Grid stackable className="header_wrapper">
              <Grid.Column width={8}>
                <Item style={{ display: 'flex', marginBottom: '2rem' }}>
                  <Item.Content>
                    <Item.Header as="h2">{this.strings.header}</Item.Header>
                    <Item.Description style={{ maxWidth: "600px", color: "#4f5765" }}>
                      {this.strings.header_desc}
                </Item.Description>
                  </Item.Content>
                </Item>
              </Grid.Column>

              <Grid.Column width={4}>
                <Segment placeholder raised className="stats_wrapper">
                  <Statistic horizontal>
                    <Statistic.Label><Image src={discount_tag} size="tiny" floated="left" /></Statistic.Label>
                    {
                      this.state.loading ?
                        <Loader active={true} size='medium' />
                        :
                        <Statistic.Value>{this.state.inventories.length}</Statistic.Value>
                    }
                    <Statistic.Label>{this.strings.inv_box}</Statistic.Label>
                  </Statistic>
                </Segment>
              </Grid.Column>

              <Grid.Column width={4}>
                <Segment placeholder raised className="stats_wrapper">
                  <Statistic horizontal>
                    <Statistic.Label><Image src={favorite} size="tiny" floated="left" /></Statistic.Label>
                    {
                      this.state.loading ?
                        <Loader active={true} size='medium' />
                        :
                        <Statistic.Value>87%</Statistic.Value>
                    }
                    <Statistic.Label>{this.strings.perc_box}</Statistic.Label>
                  </Statistic>
                </Segment>
              </Grid.Column>
            </Grid>

            {/*            
            <div>
            <p>Items: ${JSON.stringify(items)}</p>
            <p>Places: ${JSON.stringify(places)}</p>
            <p>places_ids: ${JSON.stringify(places_ids)}</p>
            <p>Inventories: ${JSON.stringify(inventories)}</p>
            <p>Selected Place: ${JSON.stringify(selectedPlaceValue)}</p>
            </div> */}
            <Segment raised padded >
              <Card.Group doubling itemsPerRow={4} stackable className={inventories.length === 0 ? "no_results" : null}>
                {
                  inventories.length <= 0
                    ?
                    (<>
                      <Image src={no_purchases} centered verticalAlign="middle" size="medium" className="empty_state_image" />
                      <Header as="h3" textAlign="center">{this.strings.no_inv}</Header>
                      <Divider />
                    </>
                    )
                    :
                    inventories.map((inventory, i) => (
                      <>
                        {/* {console.log('inventories mapping', inventory)} */}
                        <ItemCard
                          key={i}
                          loading={this.state.loading}
                          inventory_label={this.strings.header}
                          item={inventory}
                          deleteCallback={this.ItemCardDeleteCallback}
                          updateCallback={this.ItemCardUpdateCallback}
                          errorCallback={this.notifyError}
                          button_edit={this.strings.button_edit}
                          button_delete={this.strings.button_delete}
                          available_qty={this.strings.available_qty}
                          org_price={this.strings.org_price}
                          reduced_price={this.strings.reduced_price}
                          created={this.strings.created}
                          deal_type={this.strings.deal_type}
                          expires={this.strings.expires}
                          currency={this.strings.currency}
                          edit_item={this.strings.edit_item}
                          upload_text={this.strings.upload_text}
                          add_image={this.strings.add_image}
                          item_details={this.strings.item_details}
                          are_you_update={this.strings.are_you_update}
                          no={this.strings.no}
                          yes={this.strings.yes}
                          delete={this.strings.delete}
                          are_you_delete={this.strings.are_you_delete}
                          edit_qty_label={this.strings.edit_qty_label}
                          edit_price_label={this.strings.edit_price_label}
                          terms={this.strings.terms}
                          update_button={this.strings.update_button}
                          deal_personal={this.strings.deal_personal}
                          deal_business={this.strings.deal_business}
                        />
                      </>
                    ))}

                <Modal
                  trigger={
                    inventories.length > 0 ?
                      <Card
                        onClick={this.closeConfigShow(true, false)}
                        style={{ display: "flex", alignItems: "center" }}
                        className={inventories.length === 0 ? "no_results_card" : null}
                      >
                        <Card.Content
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            verticalAlign: "middle !important",
                            display: "flex"
                          }}
                        >
                          <div>
                            <Icon
                              circular
                              inverted
                              color="green"
                              name="plus"
                              size="huge"
                            />
                            <Header as="h3" style={{ textAlign: "center" }}>
                              {this.strings.button_add_inv}
                        </Header>
                          </div>
                        </Card.Content>
                      </Card>
                      :
                      <Item onClick={this.closeConfigShow(true, false)} style={{ display: 'flex', cursor: 'pointer' }}>
                        <Item.Content style={{ marginLeft: 'auto', marginRight: 'auto', padding: '1rem' }}>
                          <div>
                            <Icon circular inverted color="green" name="plus" size="huge" />
                            <Header as="h3" style={{ textAlign: "center" }}>{this.strings.button_add_inv}</Header>
                          </div>
                        </Item.Content>
                      </Item>
                  }
                  centered={false}
                  open={open}
                  closeOnEscape={closeOnEscape}
                  closeOnDimmerClick={closeOnDimmerClick}
                  onClose={this.close}
                  closeIcon
                >
                  <Modal.Header>{this.strings.sell_item}</Modal.Header>
                  <Modal.Content image>
                    <Modal.Description
                      style={{ paddingLeft: "3rem", paddingRight: "3rem" }}
                    >
                      <Form>
                        <Form.Dropdown
                          placeholder={this.strings.place}
                          label={this.strings.place_label}
                          fluid
                          search
                          selection
                          options={placesDropdown}
                          value={selectedPlaceValue}
                          onChange={this.handlePlaceChange}
                        />
                        {selectedPlaceValue !== '' ?
                          <Form.Dropdown
                            placeholder={this.strings.prod}
                            label={this.strings.prod_label}
                            fluid
                            search
                            selection
                            options={products}
                            value={selectedProductValue}
                            onChange={this.handleProductChange}
                          />
                          :
                          null
                        }

                        {!selectedProductloading ? (
                          <>
                            <Divider
                              horizontal
                              style={{
                                marginTop: "1.5rem",
                                marginBottom: "1.5rem"
                              }}
                            >
                              <Header style={{ margin: "0 !important" }}>
                                {this.strings.prod_det}
                            </Header>
                            </Divider>
                            <Grid>
                              <Grid.Column width="10">
                                <Form.Group>
                                  <Form.Input
                                    disabled
                                    label={this.strings.prod_name}
                                    type="text"
                                    value={selectedProduct.name}
                                    className="short_input"
                                  />
                                  <Form.Input
                                    disabled
                                    label={selectedProduct.units}
                                    labelPosition="right"
                                    placeholder={this.strings.weight}
                                    value="200"
                                    className="input_dropdown_label"
                                    type="number"
                                  />
                                </Form.Group>
                                {/*needs to become .description*/}
                                <Form.Field
                                  disabled
                                  width="16"
                                  name="short_description"
                                  required
                                  control={TextArea}
                                  value={selectedProduct.description ? selectedProduct.description : '-'}
                                  label={this.strings.sh_desc}
                                  placeholder={this.strings.desc}
                                />
                                {/*needs to become a dropdown*/}
                                <Form.Input
                                  label={this.strings.aller}
                                  placeholder={this.strings.aller_pl}
                                  fluid
                                  disabled
                                  value={selectedProduct.allergens}
                                />
                                {/*needs to follow new unit format*/}
                              </Grid.Column>
                              <Grid.Column width="6">
                                <Image
                                  src={selectedProduct.image}
                                  size="medium"
                                  rounded
                                  verticalAlign="middle"
                                />
                              </Grid.Column>
                            </Grid>

                            <Divider
                              horizontal
                              style={{
                                marginTop: "1.5rem",
                                marginBottom: "1.5rem"
                              }}
                            >
                              <Header style={{ margin: "0 !important" }}>
                                {this.strings.off_det}
                            </Header>
                            </Divider>

                            <Application theme={theme}>
                            <Form.Group unstackable widths={2}>

                              {/* <Form.Input
                                required
                                label={this.strings.price_org}
                                placeholder={this.strings.price_org_hold}
                                type="number"
                                onChange={e =>
                                  this.setState({
                                    newItemOrgPrice: e.target.value
                                  })
                                }
                              />
                              <Form.Input
                              required
                                label={this.strings.price}
                                placeholder={this.strings.price_hold}
                                type="number"
                                onChange={e =>
                                  this.setState({
                                    newItemRedPrice: e.target.value
                                  })
                                }
                              /> */}

                              <Form.Input
                                required
                                label={this.strings.part_number}
                                placeholder={this.strings.part_number}
                                type="number"
                                onChange={e =>
                                  this.setState({ part_number: e.target.value })
                                }
                              />
                              <Form.Field required>
                                  <label>Пакетирано на</label>
                                  <DatePicker
                                    value={newItemPackDate}
                                    minDate={new Date()}
                                    maxDate={new Date(2025, 12, 31)}
                                    onChange={value =>
                                      this.setState({ newItemPackDate: value })
                                    }
                                    className="date_picker"
                                  />
                              </Form.Field>

                              {/* <Form.Dropdown
                                required
                                label={this.strings.deal}
                                placeholder={this.strings.deal_hold}
                                fluid
                                search
                                selection
                                options={this.props.user["https://fc-demo/language"] === 'en' ? dealtypeDropdown : dealtypeDropdownBG}
                                value={selectedDealTypeValue}
                                onChange={this.handleDealTypeChange}
                              /> */}
                            </Form.Group>
                            <Form.Group unstackable widths={2}>
                            
                            <Form.Field required>
                                <label>Дата на бране</label>
                                <DatePicker
                                  value={newItemHarvDate}
                                  minDate={new Date()}
                                  maxDate={new Date(2025, 12, 31)}
                                  onChange={value =>
                                    this.setState({ newItemHarvDate: value })
                                  }
                                  className="date_picker"
                                />
                            </Form.Field>
                            <Form.Input
                            required
                              label="Набрано количесто"
                              placeholder={this.strings.qty_hold}
                              type="number"
                              onChange={e =>
                                this.setState({ newItemQyAv: e.target.value })
                              }
                            />
                          </Form.Group>
                          <br/>
                            <Form.Group unstackable widths={2}>
                              <Form.Field required>
                                      <label>Съхранено на</label>
                                      <DatePicker
                                        value={newItemSaveDate}
                                        minDate={new Date()}
                                        maxDate={new Date(2025, 12, 31)}
                                        onChange={value =>
                                          this.setState({ newItemSaveDate: value })
                                        }
                                        className="date_picker"
                                      />
                              </Form.Field>
                              <Form.Input
                                    required
                                    label="Температура на съхранение"
                                    placeholder="7°C"
                                    type="number"
                                    onChange={e =>
                                      this.setState({ newItemSaveTemp: e.target.value })
                                    }
                                >
                                  <Label basic>°C</Label>
                                  <input />
                                </Form.Input>
                            </Form.Group>
                            <br/>
                            <Form.Group unstackable widths={2}>
                              <Form.Field required>
                                    <label>Транспорт към Склад на</label>
                                    <DatePicker
                                      value={newItemTransDate}
                                      minDate={new Date()}
                                      maxDate={new Date(2025, 12, 31)}
                                      onChange={value =>
                                        this.setState({ newItemTransDate: value })
                                      }
                                      className="date_picker"
                                    />
                                </Form.Field>
                                <Form.Input
                                    required
                                    label="Транспортна температура"
                                    placeholder="7°C"
                                    type="number"
                                    onChange={e =>
                                      this.setState({ newItemTransTemp: e.target.value })

                                    }
                                >
                                  <Label basic>°C</Label>
                                  <input />
                                </Form.Input>
                            </Form.Group>
                            <br/>

                              <Form.Group widths={2}>
                                <Form.Field required>
                                  <label>{this.strings.exp}</label>
                                  <DatePicker
                                    value={newItemExDate}
                                    minDate={new Date()}
                                    maxDate={new Date(2025, 12, 31)}
                                    onChange={value =>
                                      this.setState({ newItemExDate: value })
                                    }
                                    className="date_picker"
                                  />
                                </Form.Field>
                                <Form.Field required>
                                  <label>{this.strings.time}</label>
                                  <TimePicker
                                    hour24
                                    value={newItemExTime}
                                    onChange={value =>
                                      this.setState({ newItemExTime: value })
                                    }
                                    className="time_picker"
                                  />
                                </Form.Field>
                              </Form.Group>
                            </Application>
                            {console.log('date', newItemExDate)}
                            {console.log('time', newItemExTime)}
                            <Button
                              type="submit"
                              secondary
                              style={{ display: 'flex', marginLeft: 'auto', marginRight: 'auto' }}
                              disabled={this.state.newItemQyAv <= 0 || this.state.newItemRedPrice <= 0 || 
                                this.state.newItemOrgPrice <=0 || this.state.newItemExDate === '' ||
                                this.state.newItemExTime === ''}
                              onClick={() =>
                                this.saveItemToDB(
                                  this.props.user.sub,
                                  this.state.newItemQyAv,
                                  this.state.newItemOrgPrice,
                                  this.state.newItemRedPrice,
                                  this.state.newItemExDate,
                                  this.state.newItemExTime,
                                  this.state.part_number,
                                  this.state.newItemPackDate,
                                  this.state.newItemHarvDate,
                                  this.state.newItemSaveDate,
                                  this.state.newItemSaveTemp,
                                  this.state.newItemTransDate,
                                  this.state.newItemTransTemp,
                                  created
                                )
                              }
                            >
                              {this.strings.button_add_inv}
                          </Button>
                          </>
                        ) : null}
                      </Form>
                    </Modal.Description>
                  </Modal.Content>
                </Modal>
              </Card.Group>
            </Segment>
          </div>
        )}
      
}
