import React, { Component } from "react";
import axios from "axios";
import { Segment, Image, Grid, Statistic } from "semantic-ui-react";
import revenue from "../images/dashboard/graph.svg"

export default class OrdersRevenueDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
        orders: [],
        sumValue: [],
        loading: true
        };
    }  

    getOrdersByToken = (userid, user_type) => {
        const findPipe = userid.indexOf("|");
        const id = userid.substr(findPipe+1);
        let url = user_type === "ADMIN" ? 'https://api.dontwaste.me/orders/' : `https://api.dontwaste.me/orders/purchases/accounts/${id}`
        if (this.state.loading) {
            axios.get(`${url}`, {})
                .then(orders => { this.setState({ orders: orders.data.orders, loading: false }) })
                .catch(err => {console.log(err);});
        }
    };

    render() {
        return (
            <Grid.Column width={3} data={this.getOrdersByToken(this.props.userid, this.props.user_type)}>

                <Segment placeholder raised>
                        <Statistic >
                            <Statistic.Label><Image src={revenue} size="mini"/></Statistic.Label>
                                    <Statistic.Value>
                                        {
                                            this.state.orders.map(
                                                (order) => (
                                                    this.state.sumValue.push(order.total_amount)
                                                )
                                            ),
                                            this.state.sumValue.reduce(function(acc, val) { return acc + val; }, 0).toFixed(2)
                                        } ЛВ.
                                    </Statistic.Value>
                            <Statistic.Label>Оборот</Statistic.Label>
                        </Statistic>
                </Segment>

            </Grid.Column>
        );
    }
    }
