import React, { useState } from 'react';
import { connect, ReactReduxContext } from 'react-redux'
import { paymentProvider } from '../../reducers/productActions';
import { Link } from 'react-router-dom';
import { Icon, Card, Image, Button, Segment } from 'semantic-ui-react';
import StripeUI from './StripeUI';
import EpayBGLogo from './images/epaybg_logo.png';
import StripeLogo from './images/stripe_logo.png';

const PaymentCardsNoToken = ({ paymentProvider }) => {

  const [onlyOnce, setOnlyOnce] = useState(false);
  const [cardLabelElementEpay, setLabelEpay] = useState(false);
  const [cardLabelElementStripe, setLabelStripe] = useState(false);

  console.log(cardLabelElementEpay)
  console.log(cardLabelElementStripe)

  const showCardLabelEpay = () => {
    if(!cardLabelElementEpay) {
      console.log('epay false')
      setLabelEpay({ cardLabelElementEpay: { corner: 'left', icon: 'check', color: 'green'} })
      setLabelStripe({ cardLabelElementStripe: false })
      
    } else {
      setLabelEpay({ cardLabelElementEpay: false })
    }
  }

  const showCardLabelStripe = () => {
    setLabelStripe(!cardLabelElementStripe)
    console.log('Stripe clicked')
    cardLabelElementStripe ? paymentProvider('none') : paymentProvider('stripe')
    setLabelEpay(false)
}

  const isSetPaymentMethod = (store) => {
    console.log('isSetPaymentMethod', store.PaymentProvider)
    if(store.PaymentProvider === 'none') {
      return <div><Button primary disabled={true} type='submit'>Next</Button></div>
    } else {
      return <div><Link to={'/confirmation'}><Button primary type='submit'>Next</Button></Link></div>
    }
  }

  const setPaymentProvOnlyOnce = () => {
    if(!onlyOnce) {
      paymentProvider('stripe')
      setOnlyOnce(true)
    } else console.log('Payment Prov is set by default to Stripe!')
  }

  return (

      <Card as='a' onClick={ () => (showCardLabelStripe()) }>
        {setPaymentProvOnlyOnce()}
        <Image src={StripeLogo} wrapped ui={false} label={ cardLabelElementStripe ? ({corner: 'left', icon: 'check', color: 'green'}) : null }/>
        <Card.Content>
          <Card.Header>Stripe</Card.Header>
          <Card.Meta>
            <span className='date'>Worldwide</span>
          </Card.Meta>
          {/* <Card.Description>
            Matthew is a musician living in Nashville.
          </Card.Description> */}
        </Card.Content>
        <Card.Content extra>
            <Icon name='check' color='green'/>
              Configured!
        </Card.Content>
      </Card>
    
  )
}

export default connect('', { paymentProvider })(PaymentCardsNoToken)
