import React from 'react';
import { Icon, Card, Image } from 'semantic-ui-react';
import StripeLogo from './images/stripe_logo.png';

const PaymentCardsNoToken = (props) => { 

  return (

      <Card>
        <Image src={StripeLogo} wrapped ui={false} />
        <Card.Content>
          <Card.Header>Stripe</Card.Header>
          <Card.Meta>
            <span className='date'>{props.logo_desc}</span>
          </Card.Meta>
          {/* <Card.Description>
            Matthew is a musician living in Nashville.
          </Card.Description> */}
        </Card.Content>
        <Card.Content extra>
            <Icon name='check' color='green'/>
              {props.is_conf}
        </Card.Content>
      </Card>
    
  )
}

export default PaymentCardsNoToken
