import React from "react";
//import axios from 'axios';
//import { Link } from "react-router-dom";
import { 
  Grid, Image, Icon, Header, Divider, Container, Label, Button, TableRow, TableHeaderCell, TableHeader, TableCell, TableBody, Table, CardMeta, CardHeader, CardDescription, CardContent, Card
} from "semantic-ui-react";
import "./mapstyles.css";
import YouTube from 'react-youtube';
/* import Reci5763 from "../pages/images/recipe5763.png";
import Reci240 from "../pages/images/recipe-bg-240.png";
import Reci349 from "../pages/images/recipe-bg-349.png";
import Reci734 from "../pages/images/recipe-bg-734.png"; */
import posthog from 'posthog-js';
import ProductMap from './HomeProduct/productMap';
//import { padEnd } from "lodash";
import calories from './images/calories-icon-s.png';
import carbon from './images/carbon-icon-s.png';
import fats from './images/fats-icon-s.png';
import protein from './images/protein-icon-s.png';
import co2 from './images/co2-icon.png';
import recycle from './images/recycle-icon.png';
import transport from './images/transport-icon-s.png';
//import shareicon from './images/share-icon.png';
import { shareOnMobile } from "react-mobile-share";

//posthog.init('phc_gtRKYeaZQMQKmTiA4taevgqvuKrL25gRcD98dPVJbZs', { api_host: 'https://eu.posthog.com' })

posthog.capture('Kaufland QR Scan', { property: 'visited' })

export default class MapPageItemsDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      latitude: 42.069325,
      longitude: 25.606698,
      loading: true,
      loadingLat: true,
      loadingLng: true,
      noProducts: true,
      noResults: false,
      selectedProduct: [],
      productClicked: false, 
      account_info: [],
      product_info: [],
      place_info: [],
      inventory_info: [],
      inventory_info_details: [],
      dist: Number,
      PlaceID: '',
      isSetVendorId: false,
      vendorID: '',
    };
  }

  componentDidMount() {
    fetch(`https://api.dontwaste.me/inventories/${this.getItemID()}`)
        .then(res => res.json())
        .then(res => this.setState({ inventory_info_details: res.item, product_info: res.itemProduct[0], place_info: res.itemPlace[0], loading: false }));
    
    /* fetch(`https://api.dontwaste.me/places/${this.state.place_info.place_id}`)
        .then(res => res.json())
        .then(res => this.setState({ inventory_info_details: res.item, product_info: res.itemProduct[0], place_info: res.itemPlace[0], loading: false })); */
  }
  
  getItemID = function () {
    let subUrl = window.location.pathname.substring(15)
    console.log('subUrlgetItemID: ', subUrl)
    return subUrl
  }

  getCreatedDateFromStamp (timestamp) {
        //console.log("timestamp in received:", timestamp)
       //const date = new Date(timestamp*1000).toString()
        //console.log("date in received:", date)
        //var date = moment.unix(timestamp).format("dddd, MMMM Do YYYY, h:mm:ss a")
        //var date = moment.unix(timestamp).fromNow()
        const date = new Date(timestamp).toString()
        return date
    }

  render() {
    const { loading } = this.state
    const { product_info, place_info, inventory_info_details } = this.state
    const { description, place_image_url } = this.state.place_info
    //console.log('this.state.place_info: ',this.state.place_info)
    return (

      // window.location.pathname.includes("/itemsbyvendor/") ?
      !loading &&
        

                      <div>
                        <Grid className="map_results" stackable>
                        <Grid.Column width={4} style={{marginTop:'1%'}}>
                          <Image src={product_info.image ? product_info.image : "https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/kaufland%2Fi6cli_1668090696103.jpg?alt=media&token=a4b41686-d932-4848-b6bf-a3d1266eed89"} />
                          {/* <Image size="tiny" src={product_info.image ? product_info.image : "https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/places%2F3580748650526e31e62cb43ce82ad2319f305e22.png?alt=media&token=7d025195-08e4-431a-9dd1-4de721a3f059"} /> */}
                        </Grid.Column>
                        <Grid.Column width={9} style={{marginTop:'3%',textAlign:'center'}} >
                          <Header as='h1' style={{ margin: "0.5rem 0rem 1rem" }}>{inventory_info_details.product_name}
                              {description && <Header.Subheader as='h3' color='blue'><Icon name='location arrow' color="blue" />{description}</Header.Subheader>}
                            </Header>
                            <Header.Subheader>
                              <p>{`Производител - "${place_info.name}"`}</p>
                              <Image src="https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/kaufland%2Fkaufland-brei-logo-768x432.jpg?alt=media&token=047464a6-a890-4f23-a920-aa04c178e4e8"
                              style={{
                                width: "50%",
                                display: "flex",
                                marginLeft: "auto",
                                marginRight: "auto"
                            }} />
                              {/* "Тракия Агрикола" e българска семейна фирма, която се занимава с производството на листни зеленчуци и зелени подправки. Създадена през 2004 г. от Ивайло Малджански, днес тя разполага с две землища в с. Златитрап и с. Калековец, област Пловдив, както и два пакетажни цеха. */}
                          </Header.Subheader>
                        </Grid.Column>
                        <Grid.Column width={3} style={{marginTop:'3%'}}>
                          {/* <Image src={place_image_url ? place_image_url : "https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/places%2FQR-Kaufland-1.png?alt=media&token=420a6a0a-778d-47ed-95f8-b18fb1f549c6"}
                            style={{
                              width: "50%",
                              display: "flex",
                              marginLeft: "auto",
                              marginRight: "auto"
                            }} /> */}
                            {/* <Image src={inventory_info_details.qr_code ? inventory_info_details.qr_code : "https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/places%2FQR-Kaufland-1.png?alt=media&token=420a6a0a-778d-47ed-95f8-b18fb1f549c6"}
                            style={{
                              width: "50%",
                              display: "flex",
                              marginLeft: "auto",
                              marginRight: "auto"
                            }} /> */}
                        </Grid.Column>
                      </Grid>


                      {/* <Divider horizontal>
                        <Header as='h4'>
                          <Icon name='picture' />
                            Снимки за продукта
                        </Header>
                      </Divider> */}

                      {/* <Grid columns={3} divided style={{marginLeft: '1%'}}>
                        <Grid.Row>
                            <Grid.Column>
                                <Image size="small" src={product_info.image ? product_info.image : "https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/places%2F3580748650526e31e62cb43ce82ad2319f305e22.png?alt=media&token=7d025195-08e4-431a-9dd1-4de721a3f059"} />
                            </Grid.Column>
                            <Grid.Column>
                                <Rating icon='star' defaultRating={3} maxRating={4} />
                            </Grid.Column>
                            <Grid.Column>
                                <Image size="small" src={product_info.image ? product_info.image : "https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/places%2F3580748650526e31e62cb43ce82ad2319f305e22.png?alt=media&token=7d025195-08e4-431a-9dd1-4de721a3f059"} />
                            </Grid.Column>
                        </Grid.Row>
                      </Grid> */}


                      <Divider horizontal>
                        <Header as='h4'>
                          <Icon name='food' />
                            Данни за продукта
                        </Header>
                      </Divider>

                      {
                        /* morkovi */
                        product_info._id === '6654b8a5c294260cc4268339' &&
                          <Header as='h5' textAlign='center' style={{margin: '0'}}>
                            <Icon name='truck' />Дата на бране: 27.09.24
                          </Header>
                      }

                      {
                        /* qbulki chervena*/
                        product_info._id === '6654ba33a4b98a6e25742321' &&
                          <Header as='h5' textAlign='center' style={{margin: '0'}}>
                            <Icon name='truck' />Дата на бране: 28.09.24
                          </Header>
                      }

                      {
                        /* qbulki zlatna*/
                        product_info._id === '6654bd3aa4b98a4d52742323' &&
                          <Header as='h5' textAlign='center' style={{margin: '0'}}>
                            <Icon name='truck' />Дата на бране: 28.09.24
                          </Header>
                      }
                      
                      {
                        /* spanak */
                        inventory_info_details._id === '65f7f66a652f61fe5d461bb9' &&
                          <Header as='h5' textAlign='center' style={{margin: '0'}}>
                            <Icon name='truck' />Дата на бране: 28.09.24
                          </Header>
                      }
                      

                      <Header as='h5' textAlign='center'>
                            Хранителни вещества на 100 гр.
                      </Header>

                      <Grid columns={3} divided style={{margin: 'auto'}}>
                        {/* <Grid.Row>
                            
                          <Grid.Column>
                            <Header as='h4' content='Номер партида' subheader={inventory_info_details.part_number} />
                          </Grid.Column>
                          <Grid.Column>
                            <Header as='h4' content='Дата на бране' subheader={this.getCreatedDateFromStamp(inventory_info_details.harvest_date)} />
                          </Grid.Column>
                          <Grid.Column>
                            <Header as='h4' content='Пакетирано на' subheader={this.getCreatedDateFromStamp(inventory_info_details.pack_date)} />
                          </Grid.Column>
                        </Grid.Row> */}

                        <Grid.Row style={{textAlign: 'center'}}>
                        
                          {/* <Grid.Column>
                            <Header as='h4' content='Витамини' subheader='няма информация' />
                          </Grid.Column> */}
                          {/* <Grid.Column>
                            <Header as='h4' content='Хранителни вещества' subheader='няма информация' />
                          </Grid.Column> */}
                          {/* <Grid.Column>
                            <Header as='h4' content='Локация на фермата' subheader={place_info.location.geolocation} />
                            <Header as='h4' content='Данни за производителя' subheader={`${place_info.name}`} />
                          </Grid.Column> */}

                         {/*  <Grid.Column>
                            <Header as='h4' content='Витамини' subheader='няма информация' />
                          </Grid.Column> */}
                          <Grid.Column>
                            <Header as='h4' content='Калории: ' style={{margin: 0}}/>
                            <Label style={{backgroundColor: '#00aa00'}}>
                              <Image avatar spaced='right' src={calories} />
                              {this.state.product_info.ingredients.Калорий}
                            </Label>
                          </Grid.Column>
                          <Grid.Column>
                            <Header as='h4' content='Въглехидрати: ' style={{margin: 0}}/>
                            <Label style={{backgroundColor: '#00aa00'}}>
                              <Image avatar spaced='right' src={carbon} />
                              {this.state.product_info.ingredients.Въглехидрати}
                            </Label>
                          </Grid.Column>
                          <Grid.Column>
                            <Header as='h4' content='Мазнини: ' style={{margin: 0}}/>
                            <Label style={{backgroundColor: '#00aa00'}}>
                              <Image avatar spaced='right' src={fats} />
                              {this.state.product_info.ingredients.Мазнини}
                            </Label>
                          </Grid.Column>
                          <Grid.Column>
                            <Header as='h4' content='Протеини: ' style={{margin: 0}}/>
                            <Label style={{backgroundColor: '#00aa00'}}>
                              <Image avatar spaced='right' src={protein} />
                              {this.state.product_info.ingredients.Протеини}
                            </Label>
                          </Grid.Column>
                          <br />
                          {/* <Grid.Column>
                            <Header as='h4' content='Локация на фермата' subheader={place_info.location.geolocation} />
                          </Grid.Column> */}

                          <Image src="https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/kaufland%2FEcoscore-VF-1024x737-s.png?alt=media&token=f9372de4-179a-404f-a70e-dc67377d277b"
                            style={{
                              width: "80%",
                              display: "flex",
                              marginLeft: "auto",
                              marginRight: "auto",
                              marginTop: "2%"
                            }}
                          />
                          <Image src="https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/kaufland%2FNutri-score-A_light_background_logo.svg-s.png?alt=media&token=cf41feeb-9051-423b-9a2f-cc80bc3594c9"
                            style={{
                              width: "80%",
                              display: "flex",
                              marginLeft: "auto",
                              marginRight: "auto",
                              marginTop: "2%"
                            }}
                          />
                          <Image src="https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/kaufland%2FGRASP.png?alt=media&token=0a5d58c6-cffb-466a-b0d2-51635d67939a"
                            style={{
                              width: "25%",
                              display: "flex",
                              marginLeft: "auto",
                              marginRight: "auto",
                              marginTop: "2%"
                            }}
                          />
                          <Image src="https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/kaufland%2FGlobal%20G.A.P..png?alt=media&token=8b0828db-0c39-4b98-8644-6040cf25c3bd"
                            style={{
                              width: "35%",
                              display: "flex",
                              marginLeft: "auto",
                              marginRight: "auto",
                              marginTop: "2%"
                            }}
                          />
                          <Image src="https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/kaufland%2Fnova-score-1.png?alt=media&token=4408915f-1316-49ae-b144-e7eb8f62eed4"
                            style={{
                              width: "90%",
                              display: "flex",
                              marginLeft: "auto",
                              marginRight: "auto",
                              marginTop: "2%"
                            }}
                          />

                          <Grid.Column>
                            <Header as='h4' content='Въглероден отпечатък CO2: ' style={{margin: 0}}/>
                            <Label style={{backgroundColor: '#c0c0c0'}}>
                              <Image avatar spaced='right' src={co2} />
                              {product_info.eco}
                            </Label>
                          </Grid.Column>
                          <Grid.Column>
                            <Header as='h4' content='Пакетиране: ' style={{margin: 0}}/>
                            <Label style={{backgroundColor: '#087b02'}}>
                              <Image avatar spaced='right' src={recycle} />
                              Опаковка с ниско въздействие
                            </Label>
                          </Grid.Column>
                          <Grid.Column>
                            <Header as='h4' content='Логистика: ' style={{margin: 0}}/>
                            <Label style={{backgroundColor: '#565656'}}>
                              <Image avatar spaced='right' src={transport} />
                              Стандартен транспорт
                            </Label>
                          </Grid.Column>
                          {/* <Header as='h5' textAlign='center'>Въглероден отпечатък CO2</Header> */}
                          
                          {/* <Image src="https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/kaufland%2Fnova-4.png?alt=media&token=23244afc-47a1-4b11-8c1a-d55ad371fee7"
                            style={{
                              width: "90%",
                              display: "flex",
                              marginLeft: "auto",
                              marginRight: "auto"
                            }}
                          /> */}
                          
                          {/* <Header as='h5' textAlign='center'>Пакетиране</Header> */}
                          
                          {/* <Image src="https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/kaufland%2Fnova-4.png?alt=media&token=23244afc-47a1-4b11-8c1a-d55ad371fee7"
                            style={{
                              width: "90%",
                              display: "flex",
                              marginLeft: "auto",
                              marginRight: "auto"
                            }}
                          /> */}
                        </Grid.Row>
                      </Grid>
                    
                      {/* <h4>Как да съхраняваме спанака</h4>
                      <p>Пресният спанак трябва да се съхранява опакован в торбичка в отделението за зеленчуци в хладилника, за да се запази свежестта му. Там може да престои три-четири дни. Друг вариант е замразяването. За целта предварително опаковайте насипния спанак в торбичка за замразяване.</p> */}
                      
                      <Header as='h4' style={{textAlign:"center"}}>
                      <Button style={{backgroundColor: '#ff7800'}}
                        onClick={() => 
                          shareOnMobile({
                            text: "Хей, открих невериятен продукт в Кауфланд.",
                            url: `https://app.traceground.com/items_details/${inventory_info_details._id}`,
                            title: "Traceground -> Kaufland",
                            images: product_info.image
                          }, (message) => alert(message))
                        }
                      >
                        <Icon name="share alternate" />
                        Сподели!
                      </Button>
                      </Header>

                      {/* <Table color='olive'>
                        <TableHeader>
                          <TableRow>
                            <TableHeaderCell>{product_info.name}</TableHeaderCell>
                            <TableHeaderCell>ОБЩИ</TableHeaderCell>
                            <TableHeaderCell>ВЪГЛЕХИДРАТИ</TableHeaderCell>
                            <TableHeaderCell>ВИТАМИНИ</TableHeaderCell>
                            <TableHeaderCell>Аминокиселини</TableHeaderCell>
                            <TableHeaderCell>Мазнини</TableHeaderCell>
                            <TableHeaderCell>Минерали</TableHeaderCell>
                            <TableHeaderCell>Стероли</TableHeaderCell>
                            <TableHeaderCell>Още</TableHeaderCell>
                          </TableRow>
                        </TableHeader>

                        <TableBody>
                          <TableRow>
                            <TableHeaderCell>ОБЩИ</TableHeaderCell>
                            <TableCell>Калории</TableCell>
                            <TableCell>200</TableCell>
                            <TableCell>Протеин</TableCell>
                            <TableCell>310</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Протеин</TableCell>
                            <TableCell>310</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table> */}

                      <Image src="https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/kaufland%2Fobichat-me-i-nashite-i-chujdite-h8s-brey-d-1.webp?alt=media&token=a167aea0-a3ed-468b-8ea7-477224ee7c8b" />
                      <Image src={place_image_url ? place_image_url : "https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/kaufland%2Fkaufland-headquarter.jpg?alt=media&token=7b58af57-585b-461a-b2dc-5c6651fbfca1"} />
                      
                      <br />

                      <Divider horizontal>
                        <Header as='h4'>
                          <Icon name='location arrow' />
                            Локация на фермата
                        </Header>
                      </Divider>

                      <Grid.Column width={3} style={{marginTop:'3%'}}>
                          <ProductMap
                            latitude={this.state.place_info.location.coordinates[0]}
                            longitude={this.state.place_info.location.coordinates[1]}
                            label={`${place_info.name}`}
                          />
                          {/* <Image src={place_image_url ? place_image_url : "https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/places%2FQR-Kaufland-1.png?alt=media&token=420a6a0a-778d-47ed-95f8-b18fb1f549c6"}
                            style={{
                              width: "50%",
                              display: "flex",
                              marginLeft: "auto",
                              marginRight: "auto"
                            }} /> */}
                            {/* <Image src='https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/kaufland%2FBREI-Kaufland-farm-land.png?alt=media&token=1808b72a-4c8f-444e-905c-68dd1cc23365'
                            style={{
                              width: "100%",
                              display: "flex",
                              marginLeft: "auto",
                              marginRight: "auto"
                            }} />
                            <br />
                            <Image src='https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/kaufland%2FBREI-Kaufland-farm-land-2.png?alt=media&token=13c5c95d-f07f-43b6-92ee-68314313a235'
                            style={{
                              width: "100%",
                              display: "flex",
                              marginLeft: "auto",
                              marginRight: "auto"
                            }} /> */}
                        </Grid.Column>

                      <Divider horizontal>
                        <Header as='h4'>
                          <Icon name='location arrow' />
                            Информация за фермата
                        </Header>
                      </Divider>
                      {/* <Header as='h5' textAlign='center'>Чудни родни вкуснотии, както ги обичаме по нашите земи. Където кравите пасат най-сладко и киселото мляко е най-кисело. Където вием най-правите хора и най-витите баници. Където морето е най-черно, а сиренето — е не е ли най-бяло! Тук избираме най-дебелата сянка и най-тънките мезета, а седнем ли на масата – няма ставане. Брей! Това е българско!</Header> */}
                      {
                        this.state.place_info.created_by.length > 50 ?
                          <Container textAlign='center'>
                            <p>{this.state.place_info.created_by}</p>
                          </Container>
                          : <Header as='h5' textAlign='center'>Чудни родни вкуснотии, както ги обичаме по нашите земи. Където кравите пасат най-сладко и киселото мляко е най-кисело. Където вием най-правите хора и най-витите баници. Където морето е най-черно, а сиренето — е не е ли най-бяло! Тук избираме най-дебелата сянка и най-тънките мезета, а седнем ли на масата – няма ставане. Брей! Това е българско!</Header>
                      }

                      {
                        this.state.place_info.hours.time.length > 50 &&
                          <div>
                            <Divider horizontal>
                              <Header as='h4'>
                                <Icon name='image' />
                                  Галерия
                              </Header>
                            </Divider>
                            <Grid.Row columns={3} style={{textAlign: "-moz-center"}}>
                                <Grid.Column>
                                  <Image src={this.state.place_info.hours.time} />
                                </Grid.Column>
                                {
                                  this.state.place_info.hours.days.length > 50 ?
                                  <Grid.Column>
                                    <Image src={this.state.place_info.hours.days} />
                                  </Grid.Column>
                                  : null
                                }
                              </Grid.Row>
                            </div>
                      }

                      {/* <Divider horizontal>
                        <Header as='h4'>
                          <Icon name='image' />
                            Галерия
                        </Header>
                      </Divider> */}
                        
                      {/* <Grid stackable>
                        <Grid.Row columns={3} style={{textAlign: "-moz-center"}}>
                        
                        {
                          this.state.place_info.hours.time.length > 50 ?
                            <Grid.Row columns={3} style={{textAlign: "-moz-center"}}>
                              <Grid.Column>
                                <Image src={this.state.place_info.hours.time} />
                              </Grid.Column>
                              {
                                this.state.place_info.hours.days.length > 50 ?
                                <Grid.Column>
                                  <Image src={this.state.place_info.hours.days} />
                                </Grid.Column>
                                : null
                              }
                            </Grid.Row>
                          :
                            <Grid.Column>
                              <Image src={place_image_url ? place_image_url : "https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/kaufland%2Fdepositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg?alt=media&token=1968b772-8628-47a8-b0b7-dde0f5620983"} 
                                style={{
                                  width: "12%",
                                  display: "flex",
                                  marginLeft: "auto",
                                  marginRight: "auto"
                                }}
                              />
                            </Grid.Column>
                        }
                        </Grid.Row>
                      </Grid> */}
                      {
                        this.state.place_info.contact.name.length === 11 &&
                          <div>
                            <Divider horizontal>
                              <Header as='h4'>
                                <Icon name='video' />
                                  Видео
                              </Header>
                            </Divider>
                              <Grid columns={2} stackable style={{textAlign: "-moz-center"}}>
                                    <Grid.Column>
                                      <YouTube className="embed-container" videoId={this.state.place_info.contact.name} onReady={this._onReady} />
                                    </Grid.Column>
                                </Grid>
                          </div>
                      }

                      {
                        this.state.product_info._id === '64f1b4d6875ddb85fd36c1d7' &&
                          <div>
                            <Divider horizontal>
                              <Header as='h4'>
                                  Рецепти
                              </Header>
                            </Divider>
                            <Grid style={{marginLeft: '10%', marginRight: 'auto'}}>
                              <Grid.Row>
                            <a href="https://www.kaufland.bg/recepti/tursene-recepti/recepta-detail.%D0%BE%D0%B3%D1%80%D0%B5%D1%82%D0%B5%D0%BD-%D1%81%D0%BF%D0%B0%D0%BD%D0%B0%D0%BA.r_id=BG_247.html" target="_blank">
                            <Card>
                              <Image src='https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/kaufland%2Frecipes%2Frecipe-bg-247_ogreten-ot-spanak.webp?alt=media&token=7dc1ec87-f7ca-42c0-87d1-64709b683374' wrapped ui={false} />
                              <CardContent>
                                <CardHeader>Огретен от спанак</CardHeader>
                                <CardDescription>
                                  Продукти (4 порции)
                                </CardDescription>
                              </CardContent>
                              <CardContent extra>
                              До 30 минути | Начинаещи
                            </CardContent>
                            </Card>
                            </a>
                            </Grid.Row>
                            <Grid.Row>
                            <a href="https://www.kaufland.bg/tursene/recepta-detail.r_id=BG_97.html" target="_blank">
                            <Card>
                              <Image src='https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/kaufland%2Frecipes%2Frecipe-bg-97_spanak-s-tahan-i-chesun.webp?alt=media&token=91ef9faa-6563-45fb-9eb4-d0a14987bf63' wrapped ui={false} />
                              <CardContent>
                                <CardHeader>Спанак с тахан и чесън</CardHeader>
                                <CardDescription>
                                Продукти (3 порции)
                                </CardDescription>
                              </CardContent>
                              <CardContent extra>
                              До 15 минути | Начинаещи
                            </CardContent>
                            </Card>
                            </a>
                            </Grid.Row>
                            </Grid>
                          </div>
                      }

                      {
                        this.state.product_info._id === '6654b8a5c294260cc4268339' &&
                          <div>
                            <Divider horizontal>
                              <Header as='h4'>
                                  Рецепти
                              </Header>
                            </Divider>
                            <Grid style={{marginLeft: '10%', marginRight: 'auto'}}>
                              <Grid.Row>
                            <a href="https://www.kaufland.bg/recepti/tursene-recepti/recepta-detail.%D0%BC%D0%BE%D1%80%D0%BA%D0%BE%D0%B2%D0%B5%D0%BD%D0%B0-%D1%82%D0%BE%D1%80%D1%82%D0%B0.r_id=BG_675.html" target="_blank">
                            <Card>
                              <Image src='https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/kaufland%2Frecipes%2Frecipe6243_moehrentorte-1.webp?alt=media&token=c83a2604-b79c-47a4-b489-7d891ab821c5' wrapped ui={false} />
                              <CardContent>
                                <CardHeader>Морковена торта</CardHeader>
                                <CardDescription>
                                Продукти (12 парчета)
                                </CardDescription>
                              </CardContent>
                              <CardContent extra>
                              Над 60 минути | Напреднали
                            </CardContent>
                            </Card>
                            </a>
                            </Grid.Row>
                            <Grid.Row>
                            <a href="https://www.kaufland.bg/recepti/tursene-recepti/recepta-detail.%D0%BC%D0%BE%D1%80%D0%BA%D0%BE%D0%B2%D0%B5%D0%BD-%D1%82%D0%B0%D1%80%D1%82.r_id=Recipe_24568713.html" target="_blank">
                            <Card>
                              <Image src='https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/kaufland%2Frecipes%2Frecipe20890641_voll-auf-die-moehre-tarte.webp?alt=media&token=01c04416-c589-46f6-a395-7f612ad713e7' wrapped ui={false} />
                              <CardContent>
                                <CardHeader>Морковен тарт</CardHeader>
                                <CardDescription>
                                Продукти (8 парчета)
                                </CardDescription>
                              </CardContent>
                              <CardContent extra>
                              До 60 минути | Начинаещи
                            </CardContent>
                            </Card>
                            </a>
                            </Grid.Row>
                            </Grid>
                          </div>
                      }

                      {
                        this.state.product_info._id === '6654ba33a4b98a6e25742321' &&
                          <div>
                            <Divider horizontal>
                              <Header as='h4'>
                                  Рецепти
                              </Header>
                            </Divider>
                            <Grid style={{marginLeft: '10%', marginRight: 'auto'}}>
                              <Grid.Row>
                            <a href="https://www.kaufland.bg/recepti/tursene-recepti/recepta-detail.%D0%BF%D0%B5%D1%87%D0%B5%D0%BD%D0%B8-%D1%8F%D0%B1%D1%8A%D0%BB%D0%BA%D0%B8-%D1%80%D0%B8%D0%BA%D0%BE%D1%82%D0%B0-%D1%85%D1%80%D1%83%D0%BF%D0%BA%D0%B0%D0%B2%D0%B0-%D0%BA%D0%BE%D1%80%D0%B8%D1%87%D0%BA%D0%B0.r_id=BG_1819.html" target="_blank">
                            <Card>
                              <Image src='https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/kaufland%2Frecipes%2Frecipe7724_ricotta-brataepfel-nussknusperkruste.webp?alt=media&token=4dde8dd0-c0f6-4a16-a777-f4bd8c1c8ea1' wrapped ui={false} />
                              <CardContent>
                                <CardHeader>Печени ябълки с рикота и хрупкава коричка</CardHeader>
                                <CardDescription>
                                Продукти (4 порции)
                                </CardDescription>
                              </CardContent>
                              <CardContent extra>
                              Над 60 минути | Начинаещи
                            </CardContent>
                            </Card>
                            </a>
                            </Grid.Row>
                            <Grid.Row>
                            <a href="https://www.kaufland.bg/recepti/tursene-recepti/recepta-detail.%D1%81%D0%BB%D0%B0%D0%B4%D0%BA%D0%B8%D1%88-%D1%8F%D0%B1%D1%8A%D0%BB%D0%BA%D0%B8.r_id=Recipe_4272302.html" target="_blank">
                            <Card>
                              <Image src='https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/kaufland%2Frecipes%2FRecipe-bg-4272302_Sladkish-s-qbulki.webp?alt=media&token=c7b12d5f-cbb7-4365-9e0f-49cb912c9c8f' wrapped ui={false} />
                              <CardContent>
                                <CardHeader>Сладкиш с ябълки</CardHeader>
                                <CardDescription>
                                Продукти (4 порции)
                                </CardDescription>
                              </CardContent>
                              <CardContent extra>
                              Над 60 минути | Начинаещи
                            </CardContent>
                            </Card>
                            </a>
                            </Grid.Row>
                            </Grid>
                          </div>
                      }

{
                        this.state.product_info._id === '6654bd3aa4b98a4d52742323' &&
                          <div>
                            <Divider horizontal>
                              <Header as='h4'>
                                  Рецепти
                              </Header>
                            </Divider>
                            <Grid style={{marginLeft: '10%', marginRight: 'auto'}}>
                              <Grid.Row>
                            <a href="https://www.kaufland.bg/recepti/tursene-recepti/recepta-detail.%D0%BF%D0%B5%D1%87%D0%B5%D0%BD%D0%B8-%D1%8F%D0%B1%D1%8A%D0%BB%D0%BA%D0%B8-%D1%80%D0%B8%D0%BA%D0%BE%D1%82%D0%B0-%D1%85%D1%80%D1%83%D0%BF%D0%BA%D0%B0%D0%B2%D0%B0-%D0%BA%D0%BE%D1%80%D0%B8%D1%87%D0%BA%D0%B0.r_id=BG_1819.html" target="_blank">
                            <Card>
                              <Image src='https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/kaufland%2Frecipes%2Frecipe7724_ricotta-brataepfel-nussknusperkruste.webp?alt=media&token=4dde8dd0-c0f6-4a16-a777-f4bd8c1c8ea1' wrapped ui={false} />
                              <CardContent>
                                <CardHeader>Печени ябълки с рикота и хрупкава коричка</CardHeader>
                                <CardDescription>
                                Продукти (4 порции)
                                </CardDescription>
                              </CardContent>
                              <CardContent extra>
                              Над 60 минути | Начинаещи
                            </CardContent>
                            </Card>
                            </a>
                            </Grid.Row>
                            <Grid.Row>
                            <a href="https://www.kaufland.bg/recepti/tursene-recepti/recepta-detail.%D1%81%D0%BB%D0%B0%D0%B4%D0%BA%D0%B8%D1%88-%D1%8F%D0%B1%D1%8A%D0%BB%D0%BA%D0%B8.r_id=Recipe_4272302.html" target="_blank">
                            <Card>
                              <Image src='https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/kaufland%2Frecipes%2FRecipe-bg-4272302_Sladkish-s-qbulki.webp?alt=media&token=c7b12d5f-cbb7-4365-9e0f-49cb912c9c8f' wrapped ui={false} />
                              <CardContent>
                                <CardHeader>Сладкиш с ябълки</CardHeader>
                                <CardDescription>
                                Продукти (4 порции)
                                </CardDescription>
                              </CardContent>
                              <CardContent extra>
                              Над 60 минути | Начинаещи
                            </CardContent>
                            </Card>
                            </a>
                            </Grid.Row>
                            </Grid>
                          </div>
                      }

                      

                      {/* <Divider horizontal>
                        <Header as='h4'>
                          <Icon name='video' />
                            Видео
                        </Header>
                      </Divider> */}
                      {/* {console.log(this.state.place_info.contact.name.length)} */}
                      
                      

                      {/* <Divider horizontal>
                        <Header as='h4'>
                          <Icon name='product hunt' />
                            Наши Продукти
                        </Header>
                      </Divider> */}

                      

                  {/* <Divider horizontal>
                        <Header as='h4'>
                          <Icon name='birthday cake' />
                            Препоръчани рецепти
                        </Header>
                      </Divider>
                      
                      <div>

                        <a href="https://www.kaufland.bg/recepti/tursene-recepti/recepta-detail.%D1%81%D0%BF%D0%B0%D0%BD%D0%B0%D0%BA-%D1%82%D0%B8%D0%BA%D0%B2%D0%B8%D1%87%D0%BA%D0%B8-%D0%BA%D0%BE%D0%B7%D0%B5-%D1%81%D0%B8%D1%80%D0%B5%D0%BD%D0%B5.r_id=BG_240.html" target="_blank" rel="noopener noreferrer">
                          <Card style={{textAlign:"left",margin:"auto",marginBottom:"1em"}}>
                            <Image src={Reci240} wrapped ui={false} />
                            <Card.Content>
                              <Card.Header>Спанак с тиквички и козе сирене</Card.Header>
                            </Card.Content>
                            <Card.Content extra>
                                <Icon name='clock outline' />
                                <Icon name='clock outline' />
                                До 30 минути
                            </Card.Content>
                            <Card.Content extra>
                                <Icon name='food' />
                                Начинаещи
                            </Card.Content>
                          </Card>
                        </a>

                        <a href="https://www.kaufland.bg/recepti/tursene-recepti/recepta-detail.%D0%BC%D0%B8%D0%BD%D0%B8%D0%BF%D0%B8%D1%86%D0%B8-%D1%81%D0%BF%D0%B0%D0%BD%D0%B0%D0%BA-%D0%BF%D1%8A%D0%B4%D0%BF%D1%8A%D0%B4%D1%8A%D1%87%D0%B8-%D1%8F%D0%B9%D1%86%D0%B0.r_id=BG_349.html" target="_blank" rel="noopener noreferrer">
                          <Card style={{textAlign:"left",margin:"auto",marginBottom:"1em"}}>
                            <Image src={Reci349} wrapped ui={false} />
                            <Card.Content>
                              <Card.Header>Минипици със спанак и пъдпъдъчи яйца</Card.Header>
                            </Card.Content>
                            <Card.Content extra>
                                <Icon name='clock outline' />
                                <Icon name='clock outline' />
                                <Icon name='clock outline' />
                                До 60 минути
                            </Card.Content>
                            <Card.Content extra>
                                <Icon name='food' />
                                Начинаещи
                            </Card.Content>
                          </Card>
                        </a>

                        <a href="https://www.kaufland.bg/recepti/tursene-recepti/recepta-detail.%D1%81%D0%BC%D1%83%D1%82%D0%B8-%D0%BF%D0%BE%D1%80%D1%82%D0%BE%D0%BA%D0%B0%D0%BB-%D1%81%D0%BF%D0%B0%D0%BD%D0%B0%D0%BA.r_id=Recipe_3953939.html" target="_blank" rel="noopener noreferrer">
                          <Card style={{textAlign:"left",margin:"auto",marginBottom:"1em"}}>
                            <Image src={Reci5763} wrapped ui={false} />
                            <Card.Content>
                              <Card.Header>Смути от портокал и спанак</Card.Header>
                            </Card.Content>
                            <Card.Content extra>
                                <Icon name='clock outline' />
                                До 15 минути
                            </Card.Content>
                            <Card.Content extra>
                                <Icon name='food' />
                                Начинаещи
                            </Card.Content>
                          </Card>
                        </a>

                        <a href="https://www.kaufland.bg/recepti/tursene-recepti/recepta-detail.%D0%BF%D0%B8%D0%BB%D0%B5-%D0%BF%D1%8E%D1%80%D0%B5-%D1%81%D0%BF%D0%B0%D0%BD%D0%B0%D0%BA.r_id=BG_734.html" target="_blank" rel="noopener noreferrer">
                          <Card style={{textAlign:"left",margin:"auto",marginBottom:"1em"}}>
                            <Image src={Reci734} wrapped ui={false} label={{color:"grey",content:"Веган",ribbon: true}}/>
                            <Card.Content>
                              <Card.Header>Пиле с пюре от спанак</Card.Header>
                            </Card.Content>
                            <Card.Content extra>
                                <Icon name='clock outline' />
                                <Icon name='clock outline' />
                                <Icon name='clock outline' />
                                До 60 минути
                            </Card.Content>
                            <Card.Content extra>
                                <Icon name='food' />
                                Начинаещи
                            </Card.Content>
                          </Card>
                        </a>

                      </div> */}

                      {/* <Divider horizontal>
                        <Header as='h4'>
                            Актуални предложения - от дата до дата
                        </Header>
                      </Divider>
                      <Grid.Row columns={3} style={{textAlign: "-moz-center"}}>
                        <Grid.Column>
                          <Image src="https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/kaufland%2Fc-2023-kw30-mob-products-zelenchutsi-7.webp?alt=media&token=9eb743fa-75f9-4f4f-acd7-520a9d83b22c" />
                        </Grid.Column>
                      </Grid.Row> */}

                      {/* <Image src={inventory_info_details.qr_code ? inventory_info_details.qr_code : "https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/places%2FQR-Kaufland-1.png?alt=media&token=420a6a0a-778d-47ed-95f8-b18fb1f549c6"}
                            style={{
                              width: "50%",
                              display: "flex",
                              marginLeft: "auto",
                              marginRight: "auto"
                            }} /> */}

                      <Divider horizontal>
                        <Header as='h4'>
                            Български зеленчуци
                        </Header>
                      </Divider>
                      <Grid.Row columns={3} style={{textAlign: "-moz-center"}}>
                        <Grid.Column>
                          <Image src="https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/kaufland%2Fc-2023-kw30-mob-products-zelenchutsi-7.webp?alt=media&token=9eb743fa-75f9-4f4f-acd7-520a9d83b22c" />
                        </Grid.Column>
                      </Grid.Row>

                      <br />

                      <Divider horizontal>
                        <Header as='h4'>
                            Проследяване на храната
                        </Header>
                      </Divider>
                      <Grid.Row columns={3} style={{textAlign: "-moz-center"}}>
                        <Grid.Column>
                          <Image src="https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/kaufland%2FTG-logo-3.png?alt=media&token=05f81b9f-5e02-463f-bb3b-a0139ac592ee" />
                          <Image src="https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/kaufland%2FBrey-chetiri-stepenen-kontrol-kachestvo-d.webp?alt=media&token=f13b2c1b-260b-47c2-8a31-a91ff4028377" />
                        </Grid.Column>
                      </Grid.Row>

                   {/* {console.log('this.state.place_info: ', this.state.place_info)} */}
                   <Header as='h4' style={{textAlign:"center"}}>Виж повече на: <a href="https://www.kaufland.bg/" target="_blank" rel="noopener noreferrer">kaufland.bg</a></Header>

                </div>
    )
  }
}
