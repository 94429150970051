import React from 'react';
//import axios from 'axios';
//import Stripe from 'stripe';
import { Header, Button, Icon, Card, Image } from 'semantic-ui-react';
import StripeUI from './StripeUI';
import EpayBGLogo from './images/epaybg_logo.png';
import StripeLogo from './images/stripe_logo.png';
import PaymentCards from './PaymentCards';
import PaymentCardsNoToken from './PaymentCardsNoToken';
import BottomButtons from './Billing_bottom_buttons';


//const stripe = require('stripe')('sk_test_4oO9HQAJyvgo1ZG5RKbInLAG00FGGWuJTy', {apiVersion: ''});
/* const stripe = new Stripe('sk_test_4oO9HQAJyvgo1ZG5RKbInLAG00FGGWuJTy', {
  apiVersion: '2020-03-02',
});  */

const APPID_PROD='6554291183785013076003440952844464939156359335971190257260323942'
const SECRET_PROD='cBZGZK4ACubeCVUCMNrPNzDdHaNN56ZrG2nA5816abfT2PkZkOG7GyhwWWuVcObrcV4zspDblMtVOOkyu5lpRGmh3IC8GrcMBLxPaRVhajYlVDxlg567G0SleycE5YfW'
const APPID='2123128777047314469988321512492541281777074012414332339158786136'
//const DEVICEID='deviceid16' //must be different in every request. We can ask EpayBG Support about this. Current deviceid10!!!
const KEY='MwomssTWpZ9vgBz3RnBYk0uB6CKiCVFtiRsWYtxA3sbUYbgXigVpcVBxe9qWXNVE7IgEvc7sHefOUvMqgxocxkeJpqCQsjXQjGzCy1Uhk0pdTHVWfYGJA3X7nAbtkBQV'
const DEVICE_NAME='myphone'
const BRAND='iPhone'
const OS='iOS'
const MODEL='iPhone5s'
const OS_VERSION='8.0'
const PHONE='1'

//Redirected URL after success authorization: https://dontwaste.me/platform/orders/success?ret=authok&deviceid=deviceid12

class PaymentOption extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      displayEpayBG: 'none',
      deviceID: '',
      cardLabelElementEpay: false,
      cardLabelElementStripe: false,
    }
  }

  showCardLabelEpay = () => {
    if(!this.state.cardLabelElementEpay) {
      this.setState({ cardLabelElementEpay: { corner: 'left', icon: 'check', color: 'green'}, cardLabelElementStripe: false })
    } else {
      this.setState({ cardLabelElementEpay: false })
    }
    //paymentProvider('EpayBG')
    
  }

  showCardLabelStripe = () => {
    if(!this.state.cardLabelElementStripe) {
      this.setState({ cardLabelElementStripe: { corner: 'left', icon: 'check', color: 'green'}, cardLabelElementEpay: false })
    } else {
      this.setState({ cardLabelElementStripe: false })
    }
    //paymentProvider('Stripe')
    
  }

  render() {
      return (
          <div>
              <Header as='h1'>{this.props.choose_header}</Header>
              {
                this.props.user["https://fc-demo/TOKEN"] ?
                    <PaymentCards />
                  :
                    <div>
                    <Card.Group centered>
                      <Card>
                        <Image src={EpayBGLogo} wrapped ui={false} label={{ corner: 'left', icon: 'ban', color: 'red'}}/>
                        <Card.Content>
                          <Card.Header>ePay.bg</Card.Header>
                          <Card.Meta>
                            <span className='date'>{this.props.epaybg_text}</span>
                          </Card.Meta>
                        </Card.Content>
                        <Card.Content extra>
                            <Icon name='ban' color='red'/>
                              <a href={`https://demo.epay.bg/xdev/mobile/api/start?APPID=${APPID}&DEVICEID=${this.props.user.sub}&KEY=${KEY}&DEVICE_NAME=${DEVICE_NAME}&BRAND=${BRAND}&OS=${OS}&MODEL=${MODEL}&OS_VERSION=${OS_VERSION}&PHONE=${PHONE}`}>
                                      <Button color='blue'>
                                        <Icon name='payment' /> {this.props.epaybg_activate}
                                      </Button>
                              </a>
                              {/* <a href={`https://www.epay.bg/v3/mob/api/start?APPID=${APPID_PROD}&KEY=${KEY}&SECRET=${SECRET_PROD}&DEVICEID=${this.props.user.sub}&DEVICE_NAME=${DEVICE_NAME}&BRAND=${BRAND}&OS=${OS}&MODEL=${MODEL}&OS_VERSION=${OS_VERSION}&PHONE=${PHONE}`}>
                                    <Button color='blue'>
                                      <Icon name='payment' /> EpayBG External Link PROD
                                    </Button>
                                </a> */}
                        </Card.Content>
                      </Card>

                      <PaymentCardsNoToken />
                    </Card.Group>
                    </div>
              }
              <BottomButtons 
                but_next= {this.props.but_next}
                but_back= {this.props.but_back}
              />
          </div>
        )}
}

export default PaymentOption
