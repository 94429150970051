import React, { useEffect, useState } from 'react'
import { Grid, Segment, Statistic, Image } from 'semantic-ui-react'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import waste from "../images/dashboard/planet-earth.svg"
import water from "../images/dashboard/save-water.svg";

const coSavedFactor = 1;
const waterSavedFactor = 3000;

const FoodDataRow = ({ userid }) => {
    const [purchases, setPurchases] = useState([]);
    const notifyError = () => toast.error("Something went wrong!");
    useEffect(() => {
        const getPurchasesByToken = async (userid) => {
            const findPipe = userid.indexOf("|");
            const id = userid.substr(findPipe + 1);
            await axios.get(`https://api.dontwaste.me/orders/purchases/accounts/${id}`, {
            })
                .then(res => (
                    console.log('purchases res', res),
                    setPurchases(res.data.orders)))
                .catch(err => {
                    console.log(err);
                    notifyError()
                });
        }
        console.log('userid', userid)
        getPurchasesByToken(userid)
    }, [])
    const calculateWaterWaste = () => {
        //needs to be .nickname but not working
        let waterSaved
        const randWMultiplier = (13 / 10)
        console.log("purchases.length", purchases.length)
        waterSaved = purchases.length < 1 ? 0 : purchases.length * waterSavedFactor * randWMultiplier.toFixed(1)
        console.log('waterSaved', waterSaved)
        return waterSaved
    }

    const calculateFoodWaste = () => {
        //needs to be .nickname but not working
        const randFMultiplier = (12 / 8)
        let coSaved
        coSaved = purchases.length < 1 ? 0 : purchases.length * coSavedFactor * randFMultiplier.toFixed(2)
        console.log('ccoSaved', coSaved)
        return coSaved
    }

    const calculatePurchases = () => { 
        return purchases.length
    }
    const calculateFoodRescued = () => {
        let totalWeight = 0;
        purchases.map(purchase =>
            purchase.inventories && purchase.inventories.map(item =>
                item.weight ? (
                    totalWeight += item.weight.units === "kg" ? item.weight.value : item.weight.value/1000
                )
                :
                totalWeight+=0.300
                )
        )
        return totalWeight.toFixed(2)
    }
    return (
        <Grid.Row stretched>
            <Grid.Column width={4}>
                <Segment placeholder raised>
                    <Statistic>
                        <Statistic.Label>
                            <Image src={waste} size="mini" />
                        </Statistic.Label>

                        <Statistic.Value>{calculatePurchases()}</Statistic.Value>

                        <Statistic.Label>Total Purchases</Statistic.Label>
                    </Statistic>
                </Segment>
            </Grid.Column>
            <Grid.Column width={4} >
                <Segment placeholder raised>
                    <Statistic>
                        <Statistic.Label>
                            <Image src={water} size='mini' />
                        </Statistic.Label>

                        <Statistic.Value>{calculateFoodRescued()} kg</Statistic.Value>

                        <Statistic.Label>Food rescued</Statistic.Label>
                    </Statistic>
                </Segment>
            </Grid.Column>
            <Grid.Column width={4} >
                <Segment placeholder raised>
                    <Statistic>
                        <Statistic.Label>
                            <Image src={waste} size="mini" />
                        </Statistic.Label>

                        <Statistic.Value>{calculateFoodWaste()} kg</Statistic.Value>

                        <Statistic.Label>CO2 emissions reduced</Statistic.Label>
                    </Statistic>
                </Segment>
            </Grid.Column>
            <Grid.Column width={4}>
                <Segment placeholder raised>
                    <Statistic>
                        <Statistic.Label>
                            <Image src={water} size='mini' />
                        </Statistic.Label>

                        <Statistic.Value>{calculateWaterWaste()} l</Statistic.Value>

                        <Statistic.Label>Water saved</Statistic.Label>
                    </Statistic>
                </Segment>
            </Grid.Column>
        </Grid.Row>
    )
}
export default FoodDataRow