import React, { Component } from 'react';
import { Grid, Image, Header, Label, Segment, Icon, Button, Container } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import dayjs from "dayjs";
import ProductMap from './productMap.js'
import ItemsByVendor from '../MapPageItemsByVendor';
import "./style.css"
import * as moment from "moment";
import co2 from "../images/dashboard/factory.svg"
import water from "../images/dashboard/save-water.svg"
import Application from "react-rainbow-components/components/Application";
import Colors from '../../constants/Colors'
import ProductsContainerSingleItem from '../../containers/ProductsContainerSingleItem'
const theme = {
  rainbow: {
    palette: {
      brand: Colors.fcPink,
    },
  },
};
/* require('moment-countdown');
require('moment-timer') */

class Detailpage2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qty: 1,
      opened: false,
      time_remaining: ''
      
    };
    this.countdownTimer = this.countdownTimer.bind(this)
  }
  componentDidMount() {
    this.setState({ time_remaining: this.countdownTimer() })
  }
  componentDidUpdate() {
    setTimeout(() => {
      this.setState({ time_remaining: this.countdownTimer() })
    }, 1000);
  }

  countdownTimer() {
    let difference;
    console.log('exp_date', this.props.inventory.expiry_date)
    const exp_date = dayjs(this.props.inventory.expiry_date)
    const today = dayjs()
    difference = exp_date.diff(difference)
    // console.log('exp date', exp_date)
    // console.log('today', today)
    // console.log('diff', difference)
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      }
    }

     return timeLeft;
  }

  calculatePickUpTime(timestamp) {
    const divided = timestamp / 1000;
    var date = moment.unix(divided).format("h:mm a on dddd, MMMM Do");
    return date;
  }

  openExtra = () => {
    this.setState({ opened: !this.state.opened })
  }
  
  render() {
    const { opened, time_remaining } = this.state;
    const { location, place_name } = this.props.item
    const { image, description, allergens,  } = this.props.product;
    const { product_name, deal_type, quantity_available, reduced_price, original_price, expiry_date, place_id, account_id, _id } = this.props.inventory;
    const place = this.props.place;
// clock needs to be improved to get time on first open instantenously without updating the duration after that
/* var timerFirst = moment.duration(1000).timer( ()=> {
  this.countdownTimer(expiry_date)}); */
  const timerComponents = [];

      Object.keys(time_remaining).forEach(interval => {
        if (!time_remaining[interval]) {
          return;
        }

        timerComponents.push(
          <span>
            {time_remaining[interval]} {interval}{" "}
          </span>
        );
      });

    return (
                  <Grid className="products_wrapper">
                    
                      {/* {timerFirst.start()} */}
                      {/* {console.log('this.stateHomePr: ', this.state)} */}
                      {/* {console.log('this.propsHomePr: ', this.props)} */}
                    <Grid.Row className="product_row">
                      <Grid.Column width={10}>
                        <Grid.Column >
                        <Label attached="top" color="red" icon="clock" size="large" className="time_remaining_label">{timerComponents.length ? timerComponents : "Item has expired"}</Label>
                        <Image centered src={image} rounded size="large" className="products_image" />
                        <Header as='h1'>{product_name}
                          <Header.Subheader as='h3' color='blue'><Icon name='location arrow' color="blue" />{this.props.dist} km away from you</Header.Subheader>
                          <Header.Subheader as='h3' ><Icon name='hourglass end' /> Collect before: {this.calculatePickUpTime(expiry_date)}</Header.Subheader>
                            
                          </Header>
                          {!opened ? null :
                          ( 
                            <>
                              <Header as='h5'>{this.props.product_detail_desc}</Header>
                              <p>Id: {_id}</p>
                              <Header as='h5'>{this.props.product_detail_cat_type}</Header>
                              <p>{deal_type}</p>
                              <Header as='h5'>{this.props.product_detail_delivery}</Header>
                              <p>{this.props.product_detail_pick_by}</p>
                              <Header as='h2'>{this.props.product_detail_sold_by}</Header>
                              <Header>Account ID: {account_id}</Header>
                              <p>{this.props.product_detail_vendor_desc} </p>
                              <p>{this.props.product_detail_place} {place_name}</p>
                              <p>Place ID: {place_id}</p>
                            </>
                          )}
                          <Button primary onClick={this.openExtra}><Icon name={opened ? "chevron up" : "chevron down"} />Show {!opened ? "more" : "less"}</Button>
                        </Grid.Column>
                      </Grid.Column>
                      <Grid.Column width={6} >
                      {/* <Segment raised className="add_to_cart_container">
                        <Header as='h2' color='green'>Reduced price: ${reduced_price}</Header>
                        <h4>Original price: ${original_price}</h4>
                        <ProductsContainerSingleItem 
                          productId={this.props.item._id} 
                          productName={this.props.item.name} 
                          product={this.props.item} 
                          qty={this.props.item.quantity_available}
                          product_detail_qty_choose='Choose quantity'
                          product_detail_qty_available='available'
                        />


                          { (quantity_available > 0) ?
                          <Link to='/cartpage'>
                            <Button primary disabled={this.state.qty < 1}>Go to Cart</Button>
                          </Link>
                          :
                          <Button disabled>{this.props.product_detail_cart_sold_out}</Button>
                          }

                          <p><Link to={`/report_item/${this.props.item._id}`}><Label size="small" className="report_label">{this.props.product_detail_report}</Label></Link></p>
                          <Header as='h4' color='blue'>{this.props.product_detail_env_header}</Header>
                          
                            <Grid>
                                <Grid.Row>
                                <Grid.Column width={8} verticalAlign="middle" textAlign="middle"><Image src={co2} size="mini" centered/></Grid.Column>
                                <Grid.Column width={8} verticalAlign="middle" textAlign="middle"><Image src={water} size="mini" centered/></Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                <Grid.Column width={8} verticalAlign="middle" textAlign="middle">0.5 {this.props.product_detail_env_co2}</Grid.Column>
                                <Grid.Column width={8} verticalAlign="middle" textAlign="middle">20 {this.props.product_detail_env_water}</Grid.Column>
                                </Grid.Row>
                            </Grid>
                          </Segment> */}
                          
                      </Grid.Column>
                      
                    </Grid.Row>
                    {/* <Application theme={theme} className="row">
          <ProductMap
            latitude={location[1]}
            longitude={location[0]}
            label={place_name}
          />
        </Application> */}
                    <Grid.Row >
          <Grid.Column width={6}>
            <Header as="h2" style={{paddingTop: "1rem"}}>{this.props.place.name}
              <Header.Subheader as="h4"> <Icon name="globe" />{this.props.place.location.geolocation}</Header.Subheader>
            </Header>
            {this.props.place.description && 
            <Container>
            <p>{this.props.place.description}</p>
            </Container>}
            <Link to={`/itemsbyvendor/${this.props.place._id}`}
              >
            <Button 
              secondary 
              >    
              See all items on sale
            </Button>
            </Link>
          </Grid.Column>
          <Grid.Column width={10}>
                    <ProductMap
                      latitude={location[1]}
                      longitude={location[0]}
                      label={place_name}
                    />
                    </Grid.Column>
        </Grid.Row>

                  </Grid>            
      
    );
  }
}

export default Detailpage2;