
const Colors = {
    fcPink: "#ED5E5E",
    fcOrange: "#EF9564",
    fcGreen: '#99D68F',
    fcBlue: '#129490',
    fcPinkAlpha: 'rgba(237,94,94,0.4)',
    fcOrangeAlpha: 'rgba(239,149,100, 0.4)',
    fcGreenAlpha: 'rgba(152,214,143,0.2)',
    fcDarkGreen: 'rgb(15,122, 118)',
    fcGrey: '#737373',
    fcDarkGreenAlpha: 'rgba(15,122, 118, 0.2)',
    fcWhite: 'rgba(255, 255, 255, 0.5)',
    tintColor: '#ED5E5E',
    tabIconDefault: '#ccc',
    tabIconSelected: '#ED5E5E',
    tabBar: '#fefefe',
    errorBackground: 'red',
    errorText: '#fff',  
    title: '#404040',
    desc: '#737373',
    red: '#db2828',
    warningBackground: '#EAEB5E',
    warningText: '#666804',
    noticeBackground: '#ED5E5E',
    noticeText: '#fff',
  };
   export default Colors