import React from 'react';
import { useAuth0 } from "../react-auth0-spa";
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { clearCart, addToCart, removeFromCart } from '../reducers/productActions';
import { getTotal, getCartProducts, getPlaceAddedIds } from '../reducers/rootReducer';
import { Link } from 'react-router-dom'
//import CartContainerNoCheckout from '../containers/CartContainerNoCheckout';
import { Segment, Header, Icon, Button } from 'semantic-ui-react';
import OrderingSteps from '../components/OrderingSteps';

const CartPage = ({ products, total, places, cartLocalStorage, clearCart, addToCart, removeFromCart }) => {
  //console.log(products)
  const { user } = useAuth0();
  const hasProducts = products.length > 0;

  const strings = new LocalizedStrings({
    en:{
      steps_cart_header: "Shopping Cart",
      steps_cart_label: "Your shopping cart",
      steps_shipping_header: "Shipping",
      steps_shipping_label: "Choose your shipping options",
      steps_billing_header: "Billing",
      steps_billing_label: "Enter billing information",
      steps_confirm_header: "Confirm Order",
      steps_confirm_label: "Verify order details",
      cart_header:"Your Cart",
      cart_empty_label:"Empty cart or items from different vendors. Please check again!",
      cart_one_place_label:"Your cart has products from one PLACE.",
      cart_but_remove: "remove",
      cart_but_add: "add",
      cart_currency: "$",
      cart_pcs: "pcs",
      cart_total: "Total",
      cart_back_to: "Back to Products",
      cart_reset: "Reset cart",
      cart_but_next: "Next"
    },
    bg: {
      steps_cart_header: "Кошница",
      steps_cart_label: "Вашите продукти",
      steps_shipping_header: "Доставка",
      steps_shipping_label: "Изберете опция за доставка",
      steps_billing_header: "Плащане",
      steps_billing_label: "Въведете информация за плащане",
      steps_confirm_header: "Потвърждаване",
      steps_confirm_label: "Потвърдете информацията на поръчката",
      cart_header:"Вашата кошница",
      cart_empty_label:"Празна кошница или имате стоки от различни доставчици. Моля, проверете!",
      cart_one_place_label:"Вашата кошница има продукти само от един доставчик.",
      cart_but_remove: "премахни",
      cart_but_add: "добави",
      cart_currency: "лв",
      cart_pcs: "бр.",
      cart_total: "Общо",
      cart_back_to: "Обратно към продуктите",
      cart_reset: "Изтриване на кошницата",
      cart_but_next: "Напред"
    }
   });

   const _setLanguage = () => {
    //console.log(lang)
    strings.setLanguage(user["https://fc-demo/language"])
  }

  _setLanguage();

  /* const saveState = (state) => {
    try {
      const serializedState = JSON.stringify(state);
      console.log('serializedStateSaveState: ', serializedState);
      localStorage.setItem('cart', serializedState);
    } catch {
      // ignore write errors
    }
  }; */

  const loadState = () => {
    //console.log('loadState: ', loadState)
    try {
      const serializedState = localStorage.getItem('persist:root');
      console.log('serializedStateLoadState: ', serializedState)
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (err) {
      return undefined;
    }
  };

  return (
    <div>
        <OrderingSteps 
          steps_cart_header= {strings.steps_cart_header}
          steps_cart_label= {strings.steps_cart_label}
          steps_shipping_header= {strings.steps_shipping_header}
          steps_shipping_label= {strings.steps_shipping_label}
          steps_billing_header= {strings.steps_billing_header}
          steps_billing_label= {strings.steps_billing_label}
          steps_confirm_header= {strings.steps_confirm_header}
          steps_confirm_label= {strings.steps_confirm_label}
        />
          <Header as='h2' icon textAlign='center'>
            <Icon name='cart arrow down' circular />
            <Segment style={{marginLeft: "1%", marginRight: "1%"}}>

              {/* <CartContainerNoCheckout user={user} cartLocalStorage={loadState()}/> */}

              <h3>{strings.cart_header}</h3>

              {
                ((places.length > 1) || !hasProducts) ? 
                <Header as="h2" color="red">{strings.cart_empty_label}</Header> 
                : 
                <Header as="h2" color="green">{strings.cart_one_place_label}</Header>
              }

              {
                  products.map(
                    product => (
                      product.quantity_available > 0 ?
                        <div>
                          <p>
                            <Button size="mini" color="red" onClick={() => removeFromCart(product._id, product.place_id, product.reduced_price, product.expiry_date, product.place_name, product.product_name)}>{strings.cart_but_remove}</Button>
                            |
                            <Button size="mini" color="green" onClick={() => addToCart(product._id, product.place_id, product.reduced_price, product.expiry_date, product.place_name, product.product_name)}>{strings.cart_but_add}</Button>
                            | {product.product_name} - {product.place_name} - {strings.cart_currency}{parseFloat(product.reduced_price).toFixed(2)} x {product.quantity_available} {strings.cart_pcs}
                          </p>
                          <p></p>
                        </div>
                      : null
                    )
                  )
              }
              <p></p>
              {
                hasProducts ? <p>{strings.cart_total}: &#36;{total}</p> 
                : 
                <div>{/* {nodesLocalStorage}<p>Total: &#36;{totalLocalStorage, total2.reduce(reducer).toFixed(2)}</p> */}</div>
              }

              <p></p>
              <Link to={`/`}><Button>{strings.cart_back_to}</Button></Link>
              <Link to={'/'}><Button onClick={clearCart}>{strings.cart_reset}</Button></Link>
              {
                ((places.length > 1) || !hasProducts) ? 
                  <Button disabled color="red" type='submit'>{strings.cart_but_next}</Button>
                  : 
                  <Link to={`/shipping`}><Button primary type='submit'>{strings.cart_but_next}</Button></Link>
              }

            </Segment>
          </Header>
    </div>
  )
}

CartPage.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string.isRequired,
    product_name: PropTypes.string.isRequired,
    place_name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    quantity_ordered: PropTypes.number.isRequired
  })).isRequired,
  total: PropTypes.string,
}

const mapStateToProps = (state) => ({
  products: getCartProducts(state),
  total: getTotal(state),
  places: getPlaceAddedIds(state)
})

export default connect(mapStateToProps, { clearCart, addToCart, removeFromCart })(CartPage)
