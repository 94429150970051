import React from 'react';
import { useAuth0 } from "../react-auth0-spa";
import LocalizedStrings from 'react-localization';
import { ReactReduxContext } from 'react-redux';
import CartContainer from '../containers/CartContainer';
import CartContainerNew from '../containers/CartContainerNew';
import { Segment, Image, Header } from 'semantic-ui-react';
import OrderingStepsConfirm from '../components/OrderingSteps/indexConfirm';
import StripeUI from '../components/MyProfile/StripeUI';
import EpayBGLogo from '../components/MyProfile/images/epaybg_logo.png';
import StripeLogo from '../components/MyProfile/images/stripe_logo.png';

const ConfirmOrder = () => {
  const { user } = useAuth0();
  let total2 = [0];
  const reducer = (accumulator, currentValue) => accumulator + currentValue;

  const strings = new LocalizedStrings({
    en:{
      steps_cart_header: "Shopping Cart",
      steps_cart_label: "Your shopping cart",
      steps_shipping_header: "Shipping",
      steps_shipping_label: "Choose your shipping options",
      steps_billing_header: "Billing",
      steps_billing_label: "Enter billing information",
      steps_confirm_header: "Confirm Order",
      steps_confirm_label: "Verify order details",
      epaybg_info_text: "Your order will be paid by EpayBG.",
      no_payment_provider_text: "Please go back on step and choose Payment Provider!",
      cart_no_products: "Please add some products to cart.",
      cart_header: "Your Cart",
      cart_total: "Total",
      total_currency: "$"
    },
    bg: {
      steps_cart_header: "Кошница",
      steps_cart_label: "Вашите продукти",
      steps_shipping_header: "Доставка",
      steps_shipping_label: "Изберете опция за доставка",
      steps_billing_header: "Плащане",
      steps_billing_label: "Въведете информация за плащане",
      steps_confirm_header: "Потвърждаване",
      steps_confirm_label: "Потвърдете информацията на поръчката",
      epaybg_info_text: "Плащането ще бъде извършено чрез иПейБГ.",
      no_payment_provider_text: "Не е избран платежен инструмент. Моля, върнете се една стъпка назад!",
      cart_no_products: "Моля, добавете продукти във вашата кошница.",
      cart_header: "Вашата кошница",
      cart_total: "Общо",
      total_currency: "лв."
    }
   });

   const _setLanguage = () => {
    //console.log(lang)
    strings.setLanguage(user["https://fc-demo/language"])
  }

  _setLanguage();

  const loadState = () => {
    //console.log('loadState: ', loadState)
    try {
      const serializedState = localStorage.getItem('cart');
      //console.log('serializedStateLoadState: ', serializedState)
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (err) {
      return undefined;
    }
  };

  const loadStateTotal = () => {
    //console.log('loadState: ', loadState)
    try {
          const serializedState = localStorage.getItem('cart');
          //console.log('serializedStateLoadState: ', serializedState)
          if (serializedState === null) {
            return false;
          } 
          return JSON.parse(serializedState);
    } 
      catch (err) {
        return undefined;
      }
  };

  const totalLocalStorage = loadStateTotal() ? (
      Object.entries(loadStateTotal().quantityById).map(item => {
          //console.log('item_price: ', item)
          //console.log(item[1].item_price)
          total2.push(item[1].item_price*item[1].quantity);
        }
      )
  ) : null


  const showPaymentDialog = (store) => {
    if(store.PaymentProvider === 'stripe') {
      return (
          <div style={{ width: '30%', marginLeft: 'auto', marginRight: 'auto' }}>
            <Image src={StripeLogo} />
            <StripeUI />
            <hr />
          </div>
        )
    } else if(store.PaymentProvider === 'epaybg') {
      return (
          <div>
            <Image src={EpayBGLogo} wrapped ui={false} />
            <Header as="h2" color="green">{strings.epaybg_info_text}</Header>
            <hr />
          </div>
        )
    } else {
      return <Header as="h2" color="red">{strings.no_payment_provider_text}</Header>
    }
  }

  return (
    <div>
      <OrderingStepsConfirm 
        steps_cart_header= {strings.steps_cart_header}
        steps_cart_label= {strings.steps_cart_label}
        steps_shipping_header= {strings.steps_shipping_header}
        steps_shipping_label= {strings.steps_shipping_label}
        steps_billing_header= {strings.steps_billing_header}
        steps_billing_label= {strings.steps_billing_label}
        steps_confirm_header= {strings.steps_confirm_header}
        steps_confirm_label= {strings.steps_confirm_label}
      />
      <Segment style={{marginLeft: "1%", marginRight: "1%"}} textAlign='center'>
        
          <ReactReduxContext.Consumer>
            {({ store }) => (
                  showPaymentDialog(store.getState())
            )}
          </ReactReduxContext.Consumer>
          {totalLocalStorage}
          <CartContainer user={user} cartLocalStorage={loadState()} totalLocalStorage={total2.reduce(reducer).toFixed(2)} />
          {/* <CartContainerNew user={user} cartLocalStorage={loadState()} totalLocalStorage={total2.reduce(reducer).toFixed(2)} /> */}
        
      </Segment>
    </div>
  )
}

export default ConfirmOrder
