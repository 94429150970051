import shop from '../api/shop'
import { getTotal } from './rootReducer'
import { toast } from 'react-toastify';
//import { func } from 'prop-types';

const request = async () => {
  const response = await fetch('https://api.dontwaste.me/inventories/');
  const json = await response.json();
  return json;
}

const makeid = (length) => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const notifyProductsFetch = () => toast.success("FOODCHAIN RUNNING OK");
const notifyProductsUpdateFetch = () => toast.info("FOODCHAIN RELOADED SUCCESSFULLY");
const notifyError = () => toast.error("FOODCHAIN HAS PROBLEMS!");

//request();

/* function getProducts() {
  //return fetch("http://localhost:3001/api/getData")
  return fetch("http://139.59.205.81/inventories/")
    .then(items => console.log('res: ', items))
    .then(handleErrors)
    .then(items => items.json())
} */

/*function fakeGetProducts() {
  return new Promise(resolve => {
    // Resolve after a timeout so we can see the loading indicator
    setTimeout(
      () =>
        resolve({
          products: [
            {
              id: 0,
              name: "Apple"
            },
            {
              id: 1,
              name: "Bananas"
            },
            {
              id: 2,
              name: "Strawberries"
            }
          ]
        }),
      1000
    );
  });
}*/

export function fetchProducts() {
  return dispatch => {
    dispatch(fetchProductsBegin());
    dispatch(getDeviceId());
    return request()
      .then(json => {
        dispatch(fetchProductsSuccess(json.items));
        notifyProductsFetch();
        return json.items;
      })
      .catch(error => {
        dispatch(fetchProductsFailure(error));
        notifyError()
        }
      );
  };
}

export function fetchProductsAfterUpdateItem() {
  return dispatch => {
    dispatch(fetchProductsBegin());
    return request()
      .then(json => {
        dispatch(fetchProductsSuccess(json.items));
        notifyProductsUpdateFetch();
        return json.items;
      })
      .catch(error => {
        dispatch(fetchProductsFailure(error));
        notifyError()
        }
      );
  };
}

// Handle HTTP errors since fetch won't.
/* function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
} */

export const FETCH_PRODUCTS_BEGIN = "FETCH_PRODUCTS_BEGIN";
export const FETCH_PRODUCTS_SUCCESS =
  "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAILURE =
  "FETCH_PRODUCTS_FAILURE";

export const fetchProductsBegin = () => ({
  type: FETCH_PRODUCTS_BEGIN
});

export const fetchProductsSuccess = products => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: { products }
});

export const fetchProductsFailure = error => ({
  type: FETCH_PRODUCTS_FAILURE,
  payload: { error }
});

export const GET_DEVICE_ID = 'GET_DEVICE_ID'
export const SET_LANGUAGE = 'SET_LANGUAGE'
export const ADD_TO_CART = 'ADD_TO_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const SUBTRACT_FROM_CART = 'SUBTRACT_FROM_CART'
export const CLEAR_CART = 'CLEAR_CART'
export const CHECKOUT_REQUEST = 'CHECKOUT_REQUEST'
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS'
export const CHECKOUT_FAILURE = 'CHECKOUT_FAILURE'
export const CHOOSE_PAYMENT_PROVIDER = 'CHOOSE_PAYMENT_PROVIDER'
export const CARD_PAID_SUCCESSFULLY = 'CARD_PAID_SUCCESSFULLY'

export const getDeviceId = (userid) => ({
  type: GET_DEVICE_ID,
  //payload: 'google-oauth2|106229277036146591397'
  payload: makeid(30)
  //payload: userid
})

const addToCartUnsafe = (productId, placeId, itemPrice, expDate, placeName, productName) => ({
  type: ADD_TO_CART,
  productId, placeId, itemPrice, expDate, placeName, productName
})

const removeFromCartUnsafe = (productId, placeId, itemPrice, expDate, placeName, productName) => ({
  type: REMOVE_FROM_CART,
  productId, placeId, itemPrice, expDate, placeName, productName
})

const subtractFromCartUnsafe = (productId, placeId, itemPrice, expDate, placeName, productName) => ({
  type: SUBTRACT_FROM_CART,
  productId, placeId, itemPrice, expDate, placeName, productName
})

export const addToCart = (productId, placeId, itemPrice, expDate, placeName, productName) => (dispatch, getState) => {
  if (getState().byId[productId].quantity_available > 0) {
    dispatch(addToCartUnsafe(productId, placeId, itemPrice, expDate, placeName, productName))
  } 
}

export const removeFromCart = (productId, placeId, itemPrice, expDate, placeName, productName) => (dispatch, getState) => {
  if (getState().byId[productId].quantity_available > 0) {
    console.log('productId', productId)
    console.log('getState().cart.quantityById', getState().cart.quantityById)
    console.log('getState().cart.addedIds.length', getState().cart.addedIds.length)
    if (getState().cart.quantityById[productId].quantity === 1) {
      dispatch(removeFromCartUnsafe(productId, placeId, itemPrice, expDate, placeName, productName))
    } else {
      dispatch(subtractFromCartUnsafe(productId, placeId, itemPrice, expDate, placeName, productName))
    }
    
  }
}

export const clearCart = () => (dispatch) => {
  console.log('clearCart')
  localStorage.removeItem("cart");
  dispatch({
    type: CLEAR_CART
  })
}

//it does nothing maybe
export const paymentProvider = (payment_option) => (dispatch) => {
  dispatch({
    type: CHOOSE_PAYMENT_PROVIDER,
    payload: payment_option
  })
}

export const setCartToPaid = () => (dispatch) => {
  dispatch({
    type: CARD_PAID_SUCCESSFULLY,
    payload: 'yesByStripe'
  })
}

export const checkout = (products, user, cartLocalStorage, totalLocalStorage) => (dispatch, getState) => {
  const { cart } = getState()
  const total = getTotal(getState())
  console.log('total_Action: ', total)
  console.log('cartLocalStorage: ', cartLocalStorage)
  console.log('totalLocalStorage: ', totalLocalStorage)

  dispatch({
    type: CHECKOUT_REQUEST
  })
  shop.postOrder(products, user, cart, total, cartLocalStorage, totalLocalStorage, () => {
    localStorage.clear();
    dispatch({
      type: CHECKOUT_SUCCESS,
      cart
    })
    // Replace the line above with line below to rollback on failure:
    // dispatch({ type: types.CHECKOUT_FAILURE, cart })
  })
}
