import React from 'react'
import PropTypes from 'prop-types'

const ProductsList = ({ _id, children }) => (
  <div>
    <h3>{_id}</h3>
    <div>{children}</div>
  </div>
)

ProductsList.propTypes = {
  children: PropTypes.node,
  _id: PropTypes.string.isRequired
}

export default ProductsList
