import React, { Component } from 'react';
import axios from 'axios';
import { Icon, Table, Button, Modal, Segment, Header, Form, Dimmer, Loader, Label} from 'semantic-ui-react';
import Autocomplete from 'react-google-autocomplete';
import Geocode from 'react-geocode';

export default class StoresPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      places: [],
      open: false,
      open1: false,
      loading: true,
      selectedPlaceId: [],
      openActionDelete: false,
      newStoreName: '',
      newStoreAddress: {
        coordinates: [],
        id: '',
        type: 'Point'
      },
      newStoreContactPerson: '',
      newStoreImage: '',
      newStoreAgreeWithTerms: false,
      editPlaceId: '',
      editPlaceName: ''
    };
    this.deletePlace = this.deletePlace.bind(this);
  }

  getPlacesDataFromDbByAccountId = (userid, user_type) => {
    const findPipe = userid.indexOf("|");
    const id = userid.substr(findPipe+1);
    let url = user_type === "ADMIN" ? 'https://api.dontwaste.me/places/' : `https://api.dontwaste.me/places/accounts/${id}`
    if (this.state.loading) {
    axios.get(`${url}`, {})
      .then(places => { this.setState({ places: places.data.places, loading: false}) })
      .catch(err => {console.log(err);});
    }
  };

  async savePlaceToDB(account_id, name, person, lat, lng ) {
    const findPipe = account_id.indexOf("|");
    const id = account_id.substr(findPipe+1);
    this.setState({ open: false });
    this.setState({ newStoreAgreeWithTerms: false });
    await axios.post("https://api.dontwaste.me/places/", {
      account_id: id,
      name: name,
      location: {
        coordinates: [lat, lng],
        id: id,
        type: 'Point'
      },
      created_by: person
    },
    {
      headers: { 
        "Content-Type" : "application/json"
      }
    }
    );

    this.setState({ loading: true });
  };

  async deletePlace(storeid) {
    //this.setState({ openActionDelete: false });
    //console.log(storeid)
    await axios.delete(`https://api.dontwaste.me/places/${storeid}`);
    this.setState({ loading: true });
  }

  async editPlace(place_name, place_address) {
    console.log(this.state.editPlaceId)
    this.setState({ open: false, open1: false })
    await axios.put(`https://api.dontwaste.me/places/${this.state.editPlaceId}`, {
      name: place_name
    });
    this.setState({ loading: true });
  }

  closeConfigShow = (closeOnEscape, closeOnDimmerClick) => () => {
    this.setState({ closeOnEscape, closeOnDimmerClick, open: true })
  }

  closeConfigShow1 = (closeOnEscape1, closeOnDimmerClick1, placeid, placename) => () => {
    console.log(placeid)
    this.setState({ closeOnEscape1, closeOnDimmerClick1, editPlaceId: placeid , editPlaceName: placename, open1: true })
  }

  handleChangeCheckBox = e => this.setState(({ newStoreAgreeWithTerms }) => ({ newStoreAgreeWithTerms: !newStoreAgreeWithTerms }))
  
  close = () => this.setState({ open: false, open1: false })

  disableItem = (placeId) => {
    this.setState({ selectedPlaceId: this.state.selectedPlaceId.concat(placeId) })
    console.log(this.state.selectedPlaceId)
    console.log(typeof(this.state.selectedPlaceId))
  };

  convertPlaceToGeoCode = (place1) => {
    this.setState({ placeAddressByGoogle: place1 });
    console.log(this.state.placeAddressByGoogle);
    Geocode.setApiKey('AIzaSyDfeHv6QM9PqtzE_MM5O_X81ZUjwU_jR0Q');
    Geocode.enableDebug();
    Geocode.fromAddress(place1.formatted_address).then(
      response => {
        const { lat, lng } = response.results[0].geometry.location;
        this.setState({ placeLat: lat, placeLng: lng })
        //console.log(lat, lng);
        //console.log('state.lat= ', this.state.placeLat)
        //console.log('state.lng= ', this.state.placeLng)
      },
      error => {
        console.error(error);
      }
    );
  }

  render() {
    const { 
      places, 
      open, 
      closeOnEscape, 
      closeOnDimmerClick, 
      loading,
      closeOnEscape1, 
      closeOnDimmerClick1,
      open1
      } = this.state;
    return (
      <div>
        <Dimmer active={loading} inverted data={this.getPlacesDataFromDbByAccountId(this.props.userid, this.props.user_type)}>
          <Loader inverted content='Loading' />
        </Dimmer>
        <Table basic = "very" celled selectable style={{marginTop:'0px'}}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{this.props.dashboard_segment_stores_name}</Table.HeaderCell>
              <Table.HeaderCell>{this.props.dashboard_segment_stores_status}</Table.HeaderCell>
              <Table.HeaderCell>{this.props.dashboard_segment_stores_type}</Table.HeaderCell>
              <Table.HeaderCell>{this.props.dashboard_segment_stores_contact}</Table.HeaderCell>
              <Table.HeaderCell>{this.props.dashboard_segment_stores_actions}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
                {
                  places.length <= 0
                  ? <Table.Row><Table.Cell>Please wait!</Table.Cell></Table.Row>
                  :
                  Object.values(places).map(
                    (place, i) => (
                      i <= 10 ?
                      place.status !== 'FOR_DELETION' ?
                      <Table.Row key={place._id} disabled={false}>
                       <Table.Cell key={i+2}>{place.name}</Table.Cell>
                            <Table.Cell key={i+3}><Label color = {place.status === 'VERIFIED' ? 'green' : 'red'}>{place.status}</Label></Table.Cell>
                           
                            <Table.Cell key={i+4}>{place.place_type}</Table.Cell>
                            <Table.Cell key={i+5}>{place.contact ? place.contact.name : null} | {place.contact ? place.contact.phone_number : null}</Table.Cell>
                            <Table.Cell key={i+6}>

                              <Modal 
                                trigger={<Button onClick={this.closeConfigShow1(true, false, place._id, place.name)}>{this.props.dashboard_segment_stores_but_edit}</Button>} 
                                centered={false}
                                open={open1}
                                closeOnEscape={closeOnEscape1}
                                closeOnDimmerClick={closeOnDimmerClick1}
                                onClose={this.close}
                                closeIcon
                              >
                                <Modal.Header>Edit store</Modal.Header>
                                <Modal.Content image>
                                <Segment placeholder style={{margin: '2%'}}>
                                  <Header icon>
                                    <Icon name='file image outline' />
                                    Upload image for your store.
                                  </Header>
                                  <Button primary>Add Image</Button>
                                </Segment>
                                  <Modal.Description>
                                    <Header>Store details</Header>
                                    <Form>
                                      <Form.Group unstackable widths={2}>
                                        <Form.Input label='Store name' placeholder={this.state.editPlaceName} type='text' onChange={e => this.setState({ newStoreName: e.target.value })} /> 
                                      </Form.Group>
                                      <Form.Group widths={3}>
                                        <Form.Input label='Contact person' disabled type='text' />
                                      </Form.Group>
                                      <Form.Checkbox 
                                        label='I agree to the Terms and Conditions' 
                                        onChange={this.handleChangeCheckBox}
                                      />
                                      <Modal 
                                        trigger={
                                        <Button type='submit' secondary disabled={!this.state.newStoreAgreeWithTerms}>Add store</Button>
                                        } 
                                        basic 
                                        size='small'
                                        closeOnEscape={false}
                                        closeOnDimmerClick={false}
                                        onClose={this.close}
                                        >
                                        <Header icon='birthday cake' content='Adding new store' />
                                        <Modal.Content>
                                          <p>
                                            Are you ready to update the store?
                                          </p>
                                        </Modal.Content>
                                        <Modal.Actions>
                                          <Button basic color='red' inverted onClick={this.close}>
                                            <Icon name='remove' /> No
                                          </Button>
                                          <Button color='green' inverted onClick={() => this.editPlace(this.state.newStoreName, this.state.newStoreAddress)}>
                                            <Icon name='checkmark' /> Yes
                                          </Button>
                                        </Modal.Actions>
                                      </Modal>

                                    </Form>
                                  </Modal.Description>
                                </Modal.Content>
                              </Modal>

                            
                            </Table.Cell>
                          </Table.Row>
                      : null
                      :null
                    )
                  )
                }

          </Table.Body>
          
          <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan='12'>
                  <Modal 
                    trigger={
                    <Button primary onClick={this.closeConfigShow(true, false)}><Icon name="plus" /> {this.props.dashboard_segment_stores_but_add}</Button>
                    } 
                    centered={false}
                    open={open}
                    closeOnEscape={closeOnEscape}
                    closeOnDimmerClick={closeOnDimmerClick}
                    onClose={this.close}
                    closeIcon
                  >
                    <Modal.Header>Add new store</Modal.Header>
                    <Modal.Content image>
                    <Segment placeholder style={{margin: '2%'}}>
                      <Header icon>
                        <Icon name='file image outline' />
                        Upload image for your store.
                      </Header>
                      <Button primary>Add Image</Button>
                    </Segment>
                      <Modal.Description>
                        <Header>Store details</Header>
                        <Form>
                          <Form.Group unstackable widths={2}>
                            <Form.Input label='Store name' placeholder='Store name' type='text' onChange={e => this.setState({ newStoreName: e.target.value })} />
                            <Form.Input label='Contact person' placeholder='Contact person' type='text' onChange={e => this.setState({ newStoreContactPerson: e.target.value })} />
                          </Form.Group>
                          <Form.Group widths={1}>
                            {/* <Form.Input label='Address' placeholder='Address' type='text' onChange={e => this.setState({ newStoreAddress: e.target.value })} /> */}
                            <Autocomplete
                              style={{width: '100%'}}
                              onPlaceSelected={(place1) => {
                                this.convertPlaceToGeoCode(place1);
                              }}
                              types={['establishment']}
                              componentRestrictions={{country: "bg"}}
                            />
                          </Form.Group>
                          
                              <Label><Icon name='map'/>Longitude: {this.state.placeLng}</Label>
                              <Label><Icon name='map'/>Latitude: {this.state.placeLat}</Label>
                          
                          <Form.Checkbox 
                            label='I agree to the Terms and Conditions' 
                            onChange={this.handleChangeCheckBox}
                          />
                          
                          <Modal 
                            trigger={
                            <Button type='submit' secondary disabled={!this.state.newStoreAgreeWithTerms}>Add store</Button>
                            } 
                            basic 
                            size='small'
                            closeOnEscape={false}
                            closeOnDimmerClick={false}
                            onClose={this.close}
                            >
                            <Header icon='birthday cake' content='Adding new store' />
                            <Modal.Content>
                              <p>
                                Are you ready to add your new store to your Company?
                              </p>
                            </Modal.Content>
                            <Modal.Actions>
                              <Button basic color='red' inverted onClick={this.close}>
                                <Icon name='remove' /> No
                              </Button>
                              <Button color='green' inverted onClick={() => this.savePlaceToDB(this.props.userid, this.state.newStoreName, this.state.newStoreContactPerson, this.state.placeLat, this.state.placeLng)}>
                                <Icon name='checkmark' /> Yes
                              </Button>
                            </Modal.Actions>
                          </Modal>

                        </Form>
                      </Modal.Description>
                    </Modal.Content>
                  </Modal>
                  {/* <Link to={'/stores'}>
                  <Button color="blue" floated="right"><Icon name="search" />View more</Button>
                  </Link> */}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
        </Table>
      </div>
    )
  }
}
