import React from 'react'
import PropTypes from 'prop-types'

const Product = ({ _id, name, place_name, reduced_price, quantity_available, removeFromCart }) => (
  <div>
      {console.log('_id',_id)}
      {console.log('name',name)}
      {console.log('place_name',place_name)}
      {console.log('reduced_price',reduced_price)}
      {console.log('quantity_available',quantity_available)}
      {/* {name} - {place_name} - &#36;{reduced_price} {quantity_available ? ` x ${quantity_available}` : null} */}
  </div>
)

Product.propTypes = {
  name: PropTypes.string,
  place_name: PropTypes.string,
  reduced_price: PropTypes.number,
  quantity_available: PropTypes.number,
  _id: PropTypes.string
}

export default Product
