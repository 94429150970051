import React, { useState, useEffect } from 'react';
import { useAuth0 } from "../../react-auth0-spa";
import LocalizedStrings from 'react-localization';
import Cart from './Cart';
import { Button, Icon, Image, Menu, Modal, Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import WhiteLogo from '../../pages/images/white_logo.png';
import WhiteLogoTG from '../../pages/images/TG-logo-8-2.png';
import KauflandLogo from '../../pages/images/Kaufland_Logo.png';
import NotificationsIconTopBar from './Notifications';
//import CartTopBar from '../../containers/CartContainerTopBar';
import CartTopBarNew from '../../containers/CartContainerTopBarNew';
import PopUpMenu from "./PopUpMenu.js";
import "./topBarStyles.css";

const TopBar = () => {
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenPopUpMenu, setIsOpenPopUpMenu] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const togglePopUpMenu = () => setIsOpenPopUpMenu(!isOpenPopUpMenu);

  const strings = new LocalizedStrings({
    en:{
      shop_cart_header:"My shopping cart",
      popup_menu_my_profile:"My profile",
      popup_menu_dashboard:"Dashboard",
      popup_menu_help:"Help",
      popup_menu_cust_care:"Customer Care",
      popup_menu_faq:"FAQ",
      popup_menu_logout:"Logout",
    },
    bg: {
      shop_cart_header:"Моята кошница",
      popup_menu_my_profile:"Профил",
      popup_menu_dashboard:"Табло",
      popup_menu_help:"Помощ",
      popup_menu_cust_care:"Поддръжка",
      popup_menu_faq:"Въпроси",
      popup_menu_logout:"Изход",
    }
   });

   const _setLanguage = () => {
    //console.log(lang)
    user ? strings.setLanguage(user["https://fc-demo/language"]) 
    : strings.setLanguage('en')
  }

  _setLanguage();

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin
    });

  const loadState = () => {
    //console.log('loadState: ', loadState)
    try {
      const serializedState = localStorage.getItem('cart');
      //console.log('serializedStateLoadState: ', serializedState)
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (err) {
      return undefined;
    }
  };

  return (
    <div>
      <Menu primary="true" fixed='top' className={user ? user["https://fc-demo.platform/meta_data"].account_type === "ADMIN" ? "main_top_bar_admin" : user["https://fc-demo.platform/meta_data"].account_type === "VENDOR" ? "main_top_bar_vendor" : "main_top_bar" : "main_top_bar"}>
        <Link to={'/'} className="logo_image_link">
          <Menu.Item 
            name='logo' 
            className="logo_image_container"
            >
              {/* <Image src={WhiteLogo} verticalAlign= "middle" className="logo_image"/> */}
              <Image src={KauflandLogo} verticalAlign= "middle" className="logo_image"/>
              <Image src={WhiteLogoTG} verticalAlign= "middle" className="logo_image"/>
              
          </Menu.Item>
        </Link>
        <Menu.Menu position='right'>
          {
            isAuthenticated ?
              <div>
                <NotificationsIconTopBar user={user["https://fc-demo.platform/meta_data"].account_type} userid={user.sub}/>
                {/* <Modal trigger=
                  {
                    <Button onClick={toggle} style={{backgroundColor: 'transparent', color: '#FFF'}}>
                      <Button.Content visible>
                        <Icon name='shop' size="large" />
                        <Cart qtyLocalStorage={loadState()}/>
                      </Button.Content>
                    </Button>
                  } centered={false} dimmer={isOpen} open={isOpen} onClose={toggle} closeIcon closeOnDocumentClick>
                  <Modal.Header>My shopping cart</Modal.Header>
                  <Header as='h3' icon textAlign='center'>
                    
                    <CartTopBarNew open={isOpen} handleCartModalClick={toggle}/>
                  </Header>
                </Modal> */}
                <Button icon onClick={togglePopUpMenu} style={{background: 'none'} }><Image src={user.picture} alt={user.nickname} avatar className= "avatar_image"/></Button>
              </div>
            : 
              <div>
                <Button onClick={loginWithRedirect} style={{background: 'none', color: '#FFF', fontWeight: 'bold', opacity: '1'}}><Icon name='user' size="large"/></Button>
              </div>
          }
        </Menu.Menu>
      </Menu>

      { 
        !isOpenPopUpMenu ? 
          null 
          : 
          <PopUpMenu 
            visible={isOpenPopUpMenu} 
            handleSideMenuClick={togglePopUpMenu}
            popup_menu_my_profile={strings.popup_menu_my_profile}
            popup_menu_dashboard={strings.popup_menu_dashboard}
            popup_menu_help={strings.popup_menu_help}
            popup_menu_cust_care={strings.popup_menu_cust_care}
            popup_menu_faq={strings.popup_menu_faq}
            popup_menu_logout={strings.popup_menu_logout}
          />
      }

    </div>
  )
}

export default TopBar







