import React from 'react';
import LocalizedStrings from 'react-localization';
import { Header, Button, Icon, Card, Image } from 'semantic-ui-react';
import EpayBGLogo from './images/epaybg_logo.png';
import PaymentCards from './PaymentCards';
import PaymentCardsProfile from './PaymentCardsProfile';

const APPID_PROD='6554291183785013076003440952844464939156359335971190257260323942'
const SECRET_PROD='cBZGZK4ACubeCVUCMNrPNzDdHaNN56ZrG2nA5816abfT2PkZkOG7GyhwWWuVcObrcV4zspDblMtVOOkyu5lpRGmh3IC8GrcMBLxPaRVhajYlVDxlg567G0SleycE5YfW'
const APPID='2123128777047314469988321512492541281777074012414332339158786136'
//const DEVICEID='deviceid16' //must be different in every request. We can ask EpayBG Support about this. Current deviceid10!!!
const KEY='MwomssTWpZ9vgBz3RnBYk0uB6CKiCVFtiRsWYtxA3sbUYbgXigVpcVBxe9qWXNVE7IgEvc7sHefOUvMqgxocxkeJpqCQsjXQjGzCy1Uhk0pdTHVWfYGJA3X7nAbtkBQV'
const DEVICE_NAME='myphone'
const BRAND='iPhone'
const OS='iOS'
const MODEL='iPhone5s'
const OS_VERSION='8.0'
const PHONE='1'

//Redirected URL after success authorization: https://dontwaste.me/platform/orders/success?ret=authok&deviceid=deviceid12

class PaymentOption extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      displayEpayBG: 'none',
      deviceID: '',
      cardLabelElementEpay: false,
      cardLabelElementStripe: false,
    }
  }

  showCardLabelEpay = () => {
    if(!this.state.cardLabelElementEpay) {
      this.setState({ cardLabelElementEpay: { corner: 'left', icon: 'check', color: 'green'}, cardLabelElementStripe: false })
    } else {
      this.setState({ cardLabelElementEpay: false })
    }
    //paymentProvider('EpayBG')
    
  }

  showCardLabelStripe = () => {
    if(!this.state.cardLabelElementStripe) {
      this.setState({ cardLabelElementStripe: { corner: 'left', icon: 'check', color: 'green'}, cardLabelElementEpay: false })
    } else {
      this.setState({ cardLabelElementStripe: false })
    }
    //paymentProvider('Stripe')
    
  }

  _setLanguage = (lang) => {
    //console.log(lang)
    this.strings.setLanguage(lang)
  }

  strings = new LocalizedStrings({
    en:{
      header:"Available Payment Providers",
      logo_desc_epaybg:"Only for Bulgaria",
      logo_desc_stripe:"Worldwide",
      is_ready_small_text:"Configured",
      epay_button_activate:"Activate ePayBG"
    },
    bg: {
      header:"Валидни Платежни Инструменти",
      logo_desc_epaybg:"Само за България",
      logo_desc_stripe:"Свят",
      is_ready_small_text:"Конфигурирано",
      epay_button_activate:"Активирай ИпейБГ"
    }
   });

  render() {
      return (
          <div>
            {this._setLanguage(this.props.user["https://fc-demo/language"])}
              <Header as='h1' textAlign='centered'>{this.strings.header}</Header>
                    <Card.Group centered>
                      <Card>
                        <Image src={EpayBGLogo} wrapped ui={false} label={{ corner: 'left', icon: 'ban', color: 'red'}}/>
                        <Card.Content>
                          <Card.Header>ePay.bg</Card.Header>
                          <Card.Meta>
                            <span className='date'>{this.strings.logo_desc_epaybg}</span>
                          </Card.Meta>
                        </Card.Content>
                        <Card.Content extra>
                            <Icon name='ban' color='red'/>
                              <a href={`https://demo.epay.bg/xdev/mobile/api/start?APPID=${APPID}&DEVICEID=${this.props.user.sub}&KEY=${KEY}&DEVICE_NAME=${DEVICE_NAME}&BRAND=${BRAND}&OS=${OS}&MODEL=${MODEL}&OS_VERSION=${OS_VERSION}&PHONE=${PHONE}`}>
                                      <Button color='blue'>
                                        <Icon name='payment' /> {this.strings.epay_button_activate}
                                      </Button>
                              </a>
                              {/* <a href={`https://www.epay.bg/v3/mob/api/start?APPID=${APPID_PROD}&KEY=${KEY}&SECRET=${SECRET_PROD}&DEVICEID=${this.props.user.sub}&DEVICE_NAME=${DEVICE_NAME}&BRAND=${BRAND}&OS=${OS}&MODEL=${MODEL}&OS_VERSION=${OS_VERSION}&PHONE=${PHONE}`}>
                                    <Button color='blue'>
                                      <Icon name='payment' /> EpayBG External Link PROD
                                    </Button>
                                </a> */}
                        </Card.Content>
                      </Card>
                      <PaymentCardsProfile logo_desc={this.strings.logo_desc_stripe} is_conf={this.strings.is_ready_small_text}/>
                    </Card.Group>
          </div>
        )}
}

export default PaymentOption
