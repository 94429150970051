import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
//persist
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { PersistGate } from 'redux-persist/integration/react';
//persist
import { Provider } from "react-redux";
import ReduxThunk from 'redux-thunk';
//import reducers from "./reducers";
import './ReactotronConfig'
import logger from 'redux-logger'
import 'semantic-ui-less/semantic.less'
import rootReducer from './reducers/rootReducer'
import { fetchProducts } from "./reducers/productActions";
import './index.css';
import App from './App';
//import { makeMainRoutes } from './routes'
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from "./react-auth0-spa";
import history from './utils/history';
import config from "./auth_config.json";

const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

if(process.env.NODE_ENV === 'production') {
  console.log('We are running in production mode')
  // We are running in production mode
} else {
  console.log('We are running in development mode')
 // We are running in development mode
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 }) || compose;
const middleware = [ReduxThunk, logger];

const persistConfig = {
	key: 'root',
	storage: storage,
	stateReconciler: autoMergeLevel2
}

const pReducer = persistReducer(persistConfig, rootReducer);

//const store = createStore(rootReducer, /* preloadedState, */ composeEnhancers(

const store = createStore(pReducer, /* preloadedState, */ composeEnhancers(
  applyMiddleware(...middleware)
  ));

const persistor = persistStore(store);

//store.dispatch(getAllProducts())
store.dispatch(fetchProducts())
console.log('store: ', store.getState())

//const routes = makeMainRoutes();

const disableStyle = styleName => {
  const styles = document.styleSheets;
  let href = "";
  for (let i = 0; i < styles.length; i++) {
    if (!styles[i].href) {
      continue;
    }
    href = styles[i].href.split("/");
    href = href[href.length - 1];
    if (href === styleName) {
      styles[i].disabled = true;
      break;
    }
  }
};

/* 
  <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
*/


ReactDOM.render(
  <Auth0Provider
    domain={config.domain}
    client_id={config.clientId}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}  
  >
    <Provider store={ store }>
      <App />
    </Provider>
  </Auth0Provider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
