import React, { Component } from 'react';
import SecBar2 from '../components/SecBar2';

export default class ProfileMenuPage extends Component {
  render() {
    return (
      <div>
        <SecBar2 />
      </div>
    )
  }
}
