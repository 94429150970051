import React, { Component } from 'react';
import axios from 'axios';
import LocalizedStrings from 'react-localization';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { Icon, Button, Modal, Segment, Header, Form, Dimmer, Loader, Card, List, Image, Divider, TextArea, Dropdown, Input, Item, Grid, Statistic } from 'semantic-ui-react'
//import Autocomplete from 'react-google-autocomplete';
import Geocode from 'react-geocode';
import CustomUploadButton from 'react-firebase-file-uploader/lib/CustomUploadButton';
import firebase from "firebase";
import "../../../src/firebaseConfig.js";
import "./styles.css"
import product from "../images/dashboard/barcode.svg"
import heart from "../images/dashboard/heart.svg"
import no_products from "../images/empty/no_products.png"
const unitsDropDown = [
  { key: 'gr', text: 'gr', value: 'gr' },
  { key: 'kg', text: 'kg', value: 'kg' },
  { key: 'l', text: 'liter', value: 'liter' },
  { key: 'ml', text: 'ml', value: 'ml' },
]
const unitsDropDownBG = [
  { key: 'gr', text: 'гр', value: 'gr' },
  { key: 'kg', text: 'кг', value: 'kg' },
  { key: 'l', text: 'литър', value: 'liter' },
  { key: 'ml', text: 'милилитър', value: 'ml' },
]
const MAX_SIZE = 1e6;
const allergens = [
  { key: 'gluten', text: 'Gluten', value: 'Gluten' },
  { key: 'crustaceans', text: 'Crustaceans', value: 'Crustaceans' },
  { key: 'eggs', text: 'Eggs', value: 'Eggs' },
  { key: 'fish', text: 'Fish', value: 'Fish' },
  { key: 'peanuts', text: 'Peanuts', value: 'Peanuts' },
  { key: 'soya', text: 'Soya', value: 'Soya' },
  { key: 'milk', text: 'Milk', value: 'Milk' },
  { key: 'nuts', text: 'Nuts', value: 'Nuts' },
  { key: 'celery', text: 'Celery', value: 'Celery' },
  { key: 'mustard', text: 'Mustard', value: 'Mustard' },
  { key: 'sesame', text: 'Sesame', value: 'Sesame' },
  { key: 'sulphites', text: 'Sulphites', value: 'Sulphites' },
  { key: 'lupin', text: 'Lupin', value: 'Lupin' },
  { key: 'molluscs', text: 'Molluscs', value: 'Molluscs' }
]
const allergensBG = [
  { key: 'gluten', text: 'Глутен', value: 'Глутен' },
  { key: 'crustaceans', text: 'Ракообразни', value: 'Ракообразни' },
  { key: 'eggs', text: 'Яйца', value: 'Яйца' },
  { key: 'fish', text: 'Риба', value: 'Риба' },
  { key: 'peanuts', text: 'Фъстъци', value: 'Фъстъци' },
  { key: 'soya', text: 'Соя', value: 'Соя' },
  { key: 'milk', text: 'Мляко', value: 'Мляко' },
  { key: 'nuts', text: 'Ядки', value: 'Ядки' },
  { key: 'celery', text: 'Целина', value: 'Целина' },
  { key: 'mustard', text: 'Горчица', value: 'Горчица' },
  { key: 'sesame', text: 'Сусам', value: 'Сусам' },
  { key: 'sulphites', text: 'Сулфити', value: 'Сулфити' },
  { key: 'lupin', text: 'Лупин', value: 'Лупин' },
  { key: 'molluscs', text: 'Мекотели', value: 'Мекотели' }
]
export default class ProductBasedOnStores extends Component {
  constructor(props) {
    super(props)
    this.state = {
      products: [],
      open: false,
      open1: false,
      intervalIsSet: false,
      loading: true,
      selectedProductId: [],
      openActionDelete: false,
      newProductName: '',
      newProductDesc: '',
      newProductAller: [],
      newProductIngredients: '',
      newProductAgreeTerms: false,
      newProductCal: '0',
      newProductCarb: '0',
      newProductFat: '0',
      newProductProt: '0',
      newProductSavedWater: '2',
      newProductSavedCO2: '50',
      editProductId: '',
      editProductName: '',
      newProductUnitsValue: '',
      newProductUnitsType: 'гр',
      newProductImageURL: 'https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/default%2Ffood_bag.svg?alt=media&token=7d5a0451-c8c7-418c-94bd-f90f41eab67d',
      newProductImageName:'default',
      newProductEco: '',
      newProductDescription: '',
      productTypeSelected: 'none'
    };
    this.deleteProduct = this.deleteProduct.bind(this);
  }

  strings = new LocalizedStrings({
    en:{
      header: "Products",
      header_desc: "This section contains all of your company's products. Think of it as a database where you can store all of the data for your products and when any of these products are about to expire you can quickly sell it at a reduced price by adding it to your 'Inventory'. Products are shared across all of your stores so that you don't have to list the same product many times.",
      products_box: "Products",
      rating_box: "Rating",
      no_products: "You have no products yet!",
      product_row: "",
      button_edit: "Edit",
      button_delete: "Delete",
      product_row_header: "",
      product_row_desc:"",
      product_row_details:"Product details",
      product_row_weight:"",
      product_row_location: "",
      product_row_allergens: "",
      button_add_product: "Add Product",
      add_new_header: "Add new Product",
      add_new_product_type: "What type of product would you like to add?",
      add_new_surprise_box_header: "Surprise Box",
      add_new_surprise_box_desc: "FoodChain surprise boxex are...",
      add_new_custom_box_header: "Custom product",
      add_new_custom_box_desc: "You can add anything you want as a custom product",
      add_new_edit: "Edit product",
      add_new_upload_image: "Upload image for your product.",
      add_new_image: "Add Image",
      add_new_update: "Update product",
      add_new_are_you: "Are you ready to update the product?",
      add_new_yes: "yes",
      add_new_no: "no",
      add_new_def_image: "Use our default image",
      add_new_or: "OR",
      add_new_own: "Upload your own",
      surprise_box_label: "Product name",
      surprise_box_label_placeholder: "Name",
      surprise_box_placeholder: "Surprise Box",
      desc_short: "Short description",
      desc_form_placeholder: "This description will be shown to clients on our platform. Introduce your business in a couple of sentences and make it stand out!",
      allergens_label: "Allergens",
      allergend_placeholder: "Allergens...",
      terms: "I agree to the Terms and Conditions",
      weight: "Select the weight of your product",
      notifyProductCreated: "Success! Your Product is created.",
      notifyProductUpdate: "Success! Your Product is updated.",
      notifyProductDeleted: "Product DELETED!",
      notifyError: "Something went wrong!",
      food_info_label: "Food information",
      food_info_cal: "Calories",
      food_info_carb: "Carbohydrates",
      food_info_fat: "Fat",
      food_info_prot: "Proteins",
      food_eco_save_water: "Water saved",
      food_eco_save_co2: "CO2 saved",
      unit_kcal: 'kcal',
      unit_gr: 'gr',
      unit_liter: 'l',
      placeholder_cal: 'Calories',
      placeholder_carb: 'Carbohydrates',
      placeholder_fat: 'Fars',
      placeholder_prot: 'Proteins'
    },
    bg: {
      header: "Продукти",
      header_desc: "На този екран виждате вашите продукти. Това е вашата база данни, където можете да съхранявате вашата информация и когато някой продукт е с изтичащ срок на годност, вие можете бързо да намалите неговата цена, като го добавите във вашия 'Инвентар'. Продукти за общи за всички ваши обекти, така че няма нужда да създавате еднакъв продукт от различни ресторанти.",
      products_box: "Продукти",
      rating_box: "Рейтинг",
      no_products: "Нямате продукти!",
      product_row: "",
      button_edit: "Промени",
      button_delete: "Изтрий",
      product_row_header: "",
      product_row_desc:"",
      product_row_details:"Детайли на продукта",
      product_row_weight:"",
      product_row_location: "",
      product_row_allergens: "",
      button_add_product: "Добави продукт",
      add_new_header: "Добави нов продукт",
      add_new_product_type: "Какъв тип продукт искате да добавите?",
      add_new_surprise_box_header: "Кутия с изненади",
      add_new_surprise_box_desc: "FoodChain кутийте с изненади са...",
      add_new_custom_box_header: "Определен продукт",
      add_new_custom_box_desc: "Можете да добвите всякакъв продукт",
      add_new_edit: "Промени продукт",
      add_new_upload_image: "Качете снимка на продукта.",
      add_new_image: "Добави снимка",
      add_new_update: "Промени продукта",
      add_new_are_you: "Сигурни ли сте, че искате да промените продукта?",
      add_new_yes: "да",
      add_new_no: "не",
      add_new_def_image: "Използвайте нашата базова снимка",
      add_new_or: "ИЛИ",
      add_new_own: "Качете ваша снимка",
      surprise_box_label: "Име на продукта",
      surprise_box_label_placeholder: "Име",
      surprise_box_placeholder: "Кутия с изненади",
      desc_short: "Кратко описание",
      desc_form_placeholder: "Това описание ще виждат вашите клиенти. Опишете добре продукта си!",
      allergens_label: "Алергени",
      allergend_placeholder: "Алергени...",
      terms: "Съгласен съм с условията на ползване.",
      weight: "Изберете тегло на продукта",
      notifyProductCreated: "Успешно! Вашият продукт е създаден.",
      notifyProductUpdate: "Успешно! Продукта е променен.",
      notifyProductDeleted: "Продукта е изтрит!",
      notifyError: "Нещо се обърка!",
      food_info_label: "Хранителна информация",
      food_info_cal: "Калорий",
      food_info_carb: "Въглехидрати",
      food_info_fat: "Мазнини",
      food_info_prot: "Протеини",
      food_eco_save_water: "Спестена вода",
      food_eco_save_co2: "Намалено CO2",
      unit_kcal: 'кал',
      unit_gr: 'гр',
      unit_liter: 'л',
      placeholder_cal: 'Количество калорий',
      placeholder_carb: 'Количество въглехидрати',
      placeholder_fat: 'Количество мазнини',
      placeholder_prot: 'Количество протеини'
    }
   });

   _setLanguage = (lang) => {
    //console.log(lang)
    this.strings.setLanguage(lang)
  }

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
  handleProgress = progress => this.setState({ progress });
  handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };
  handleUploadSuccess = filename => {
    this.setState({ newProductImageName: filename, progress: 100, isUploading: false });
    firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL()
      .then(url => this.setState({ newProductImageURL: url }));
  };
  chooseBox = () => {
    this.setState({productTypeSelected: 'box', newProductUnitsType: 'box', newProductUnitsValue: 1})
  }
  chooseCustom = () => {
    this.setState({productTypeSelected: 'custom'})
  }
  /* getProductsDataFromDb = () => {
    fetch("https://api.dontwaste.me/products/")
      .then(res => res.json())
      .then(res => this.setState({ products: res.products, loading: false }))
      .catch(err => {
        console.log(err);
        this.notifyError()
      });
  }; */

  getProductsDataFromDbByAccountId = (userid) => {
    const findPipe = userid.indexOf("|");
    const id = userid.substr(findPipe+1);
    //console.log('id', id)
    if (this.state.loading) {
      fetch(`https://api.dontwaste.me/products/accounts/${id}`)
        .then(res => res.json())
        .then(res => this.setState({ products: res.products, loading: false }))
        .catch(err => {
          console.log(err);
          this.notifyError()
        });
    }
  };

  getProductsData = () => {
    //console.log('id', id)
    if (this.state.loading) {
      fetch(`https://api.dontwaste.me/products/`)
        .then(res => res.json())
        .then(res => this.setState({ products: res.products, loading: false }))
        .catch(err => {
          console.log(err);
          this.notifyError()
        });
    }
  };

  async saveProductToDB(account_id, name, description, allergens, ingredients, units, units_value, calories, carbo, fats, proteins) {
    console.log('account_id: ', account_id)
    console.log('name: ', name)
    console.log('description: ', description)
    console.log('allergens: ', allergens)
    console.log('ingredients: ', ingredients)
    console.log('units: ', units)
    console.log('units_value: ', units_value)
    console.log('calories: ', calories)
    console.log('carbo: ', carbo)
    console.log('fats: ', fats)
    console.log('proteins: ', proteins)
    const findPipe = account_id.indexOf("|");
    const id = account_id.substr(findPipe+1);
    this.setState({ open: false });
    this.setState({ newProductAgreeTerms: false });
    await axios.post("https://api.dontwaste.me/products/", {
      account_id: id,
      name: name,
      image: this.state.newProductImageURL,
      allergens: allergens,
      description: description,
      eco: `${this.strings.food_eco_save_water} ${this.state.newProductSavedWater} ${this.strings.unit_liter} | ${this.strings.food_eco_save_co2} ${this.state.newProductSavedCO2} ${this.strings.unit_gr}`,
      ingredients: {
        'Калорий': `${calories} ${this.strings.unit_kcal}`,
        'Въглехидрати': `${carbo} ${this.strings.unit_gr}`,
        'Мазнини': `${fats} ${this.strings.unit_gr}`,
        'Протеини': `${proteins} ${this.strings.unit_gr}`
      },
      weight: {
        units: units_value,
        value: units
      }
    },
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
    .then(res => res.status === 201 ? this.notifyProductCreated() : null)
    .catch(err => {
      console.log(err);
      this.notifyError()
    })

    this.setState({ loading: true,
      newProductName: '',
      newProductAller: [],
      newProductIngredients: '',
      newProductAgreeTerms: false, 
      newProductUnitsValue: '',
      newProductUnitsType: '',
      newProductImageURL: 'https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/default%2Ffood_bag.svg?alt=media&token=7d5a0451-c8c7-418c-94bd-f90f41eab67d',
      newProductImageName:'default',
      newProductEco: 'Eco 1, Eco 2',
      newProductDescription: '' });
  };

  async deleteProduct(productid) {
    //this.setState({ openActionDelete: false });
    //console.log(productid)
    await axios.delete(`https://api.dontwaste.me/products/${productid}`)
    .then(res => res.status === 204 ? this.notifyProductDeleted() : null)
    .catch(err => {
      console.log(err);
      this.notifyError()
    })
    this.setState({ loading: true });
  }

  async editProduct(name, ingredients, allergens, units) {
    console.log(this.state.editProductId)
    this.setState({ open: false, open1: false })
    await axios.put(`https://api.dontwaste.me/products/${this.state.editProductId}`, {
      name: name,
      allergens: allergens,
      ingredients: ingredients,
      units: units
    })
    .then(res => res.status === 200 ? this.notifyProductUpdate() : null)
    .catch(err => {
      console.log(err);
      this.notifyError()
    })
    this.setState({ loading: true });
  }

  closeConfigShow = (closeOnEscape, closeOnDimmerClick) => () => {
    this.setState({ closeOnEscape, closeOnDimmerClick, open: true })
    this.setState({productTypeSelected:'none'})
  }

  closeConfigShow1 = (closeOnEscape1, closeOnDimmerClick1, id, name, ingredients, allergens, units) => () => {
    console.log(id)
    this.setState({ closeOnEscape1, closeOnDimmerClick1, editProductId: id, editProductName: name, newProductIngredients: ingredients, newProductAller: allergens, newProductUnits: units, open1: true })
  }

  closeConfigShowActionDelete = (dimmer) => () => {
    this.setState({ dimmer, openActionDelete: true })
  }

  handleChangeCheckBox = e => this.setState(({ newProductAgreeTerms }) => ({ newProductAgreeTerms: !newProductAgreeTerms }))

  handleAllergensChange = (e, { value }) => this.setState({ newProductAller: value }, console.log('allergens', this.state.newProductAller))

  handleChange = (e, { value }) => this.setState({ newProductUnits: value })
  show = dimmer => () => this.setState({ dimmer, open: true })
  close = () => this.setState({ open: false, open1: false })
  closeActionDelete = () => this.setState({ openActionDelete: false })

  disableItem = (productId) => {
    this.setState({ selectedProductId: this.state.selectedProductId.concat(productId) })
    console.log(this.state.selectedProductId)
    console.log(typeof (this.state.selectedProductId))
  };
  handleFormChange = (e, { value }) => this.setState({ newProductDesc: value })

  convertProductToGeoCode = (product1) => {
    this.setState({ productAddressByGoogle: product1 });
    console.log(this.state.productAddressByGoogle);
    Geocode.setApiKey('AIzaSyDfeHv6QM9PqtzE_MM5O_X81ZUjwU_jR0Q');
    Geocode.enableDebug();
    Geocode.fromAddress(product1.formatted_address).then(
      response => {
        const { lat, lng } = response.results[0].geometry.location;
        this.setState({ productLat: lat, productLng: lng })
        //console.log(lat, lng);
        //console.log('state.lat= ', this.state.placeLat)
        //console.log('state.lng= ', this.state.placeLng)
      },
      error => {
        console.error(error);
      }
    );
  }
MapAllergens = (allergens) => {
  const lenAllergen= allergens.length;
  return (
  Object.values(allergens).map((allergen, i, ) => 
    (lenAllergen === (i+1) ?  allergen : allergen + ", " )
    )
  )
}

notifyProductCreated = () => toast.success(this.strings.notifyProductCreated);
notifyProductUpdate = () => toast.info(this.strings.notifyProductUpdate);
notifyProductDeleted = () => toast.warn(this.strings.notifyProductDeleted);
notifyError = () => toast.error(this.strings.notifyError);

  render() {
    const {
      products,
      open,
      closeOnEscape,
      closeOnDimmerClick,
      loading,
      closeOnEscape1,
      closeOnDimmerClick1,
      open1
    } = this.state;
    const {
      newProductName,
      productTypeSelected,
      newProductImageURL,
      newProductImageName,
      newProductDescription,
      newProductAller,
      newProductCal,
      newProductCarb,
      newProductFat,
      newProductProt,
      newProductSavedWater,
      newProductSavedCO2
    } = this.state;
    return (
          <div>
          {this._setLanguage(this.props.user["https://fc-demo/language"])}
          <ToastContainer />
            <Dimmer 
              active={loading} 
              inverted 
              data={
                this.props.user["https://fc-demo/role"] === "ADMIN" ?
                this.getProductsData()
                : this.getProductsDataFromDbByAccountId(this.props.user.sub)
                }
            >
           {/* <Dimmer active={loading} inverted data={this.getProductsDataFromDbByAccountId('5c891024ba001f00067381e8')}>  */}
              <Loader inverted content='Loading' />
            </Dimmer>
            <Grid stackable className="header_wrapper">
              <Grid.Column width={8}>
              <Item style={{ display: 'flex', marginBottom: '2rem' }}>
                            {/* <Icon name="food" size="huge" className="item_icon" /> */}
                            <Item.Content>
                                <Item.Header as='h2'>{this.strings.header} </Item.Header>
                                <Item.Description style={{maxWidth:'600px', color: "#4f5765"}}>
                                    {this.strings.header_desc}  
                                </Item.Description>
                            </Item.Content>
                        </Item>
              </Grid.Column>
              
              <Grid.Column width={4}>
                  <Segment placeholder raised className="stats_wrapper">
                  <Statistic horizontal>
                                            <Statistic.Label><Image src={product} size="tiny" floated="left"/></Statistic.Label>
                                            {
                                                this.state.loading ?
                                                    <Loader active={true} size='medium' />
                                                    :
                                                    <Statistic.Value>{this.state.products.length}</Statistic.Value>
                                            }
                                            <Statistic.Label>{this.strings.products_box}</Statistic.Label>
                                        </Statistic>
                  </Segment>
              </Grid.Column>

              <Grid.Column width={4}>
                  <Segment placeholder raised className="stats_wrapper">
                  <Statistic horizontal>
                                            <Statistic.Label><Image src={heart} size="tiny" floated="left"/></Statistic.Label>
                                            {
                                                this.state.loading ?
                                                    <Loader active={true} size='medium' />
                                                    :
                                                    <Statistic.Value>4.8</Statistic.Value>
                                            }
                                            <Statistic.Label>{this.strings.rating_box}</Statistic.Label>
                                        </Statistic>
                  </Segment>
              </Grid.Column>
            </Grid>
                    
          <Segment raised padded className={products.length === 0 ? "no_results" : null}>
            <Item.Group divided className="product_list">
              {products.length <= 0
                  ?  
                  ( <>
                    <Image src={no_products} centered verticalAlign="middle" size="medium" className="empty_state_image"/>
                    <Header as="h3" textAlign="center">{this.strings.no_products}</Header>
                    </>
                    )
                  :
                 Object.values(products).map(
                  (product, i) => (
                    product.isMarkedForDeletion !== true ?

                      <Item key={product.id}>
                        {loading ? (
                         <Item.Image size='tiny' src='https://react.semantic-ui.com/images/wireframe/image.png' />
                        ) : (
                            <Item.Image src={product.image} ui={false} centered className="center_crop_card"/>
                          )}
                          {loading ? (
                             <Item.Content>
                            <Item.Header as='a'>Header</Item.Header>
                            <Item.Meta>Description</Item.Meta>
                            <Item.Description>
                              <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                            </Item.Description>
                            <Item.Extra>Additional Details</Item.Extra>
                            </Item.Content>
                          )
                        :(
                        <Item.Content>
                        <Item.Header >{product.name}</Item.Header>
                          {/* <Item.Meta><Icon name='balance scale' />{product.weight ? (Object.values(product.weight).map(row => row)) : null}</Item.Meta> */}
                          <Item.Description>
                          <Grid>
                            <Grid.Column width={8}>{product.description ? product.description : this.strings.desc_form_placeholder}</Grid.Column>
                            <Grid.Column width={8}>
                              <Header as="h3">{this.strings.product_row_details}</Header>
                            <List>
                            <List.Item>
                                        <List.Icon name='balance scale' />
                                        <List.Content>{product.weight ? (Object.values(product.weight).map(row => row)) : null}</List.Content>
                                      </List.Item>
                                      <List.Item>
                                        <List.Header><List.Icon name='book' /></List.Header>
                                        {
                                          Object.entries(product.ingredients).map(
                                            row => (
                                              <List.Content>{row[0]} - {row[1]}</List.Content>
                                            )
                                          )
                                        }
                                        {/* <List.Content>{product.ingredients}</List.Content> */}
                                      </List.Item>
                                      <List.Item>
                                        <List.Icon name='warning sign' />
                                        <List.Content> {this.MapAllergens(product.allergens)}</List.Content>
                                      </List.Item>
                                      <List.Item>
                                        <List.Icon name='globe' />
                                        <List.Content>{product.eco}</List.Content>
                                      </List.Item>
                                    </List>
                            </Grid.Column>
                          </Grid>
                           </Item.Description>
                              <Item.Extra >
                              <Modal
                              trigger={<Button onClick={this.closeConfigShow1(true, false, product._id, product.name, product.ingredients, product.allergens, product.units)}>{this.strings.button_edit}</Button>}
                              centered={false}
                              open={open1}
                              closeOnEscape={closeOnEscape1}
                              closeOnDimmerClick={closeOnDimmerClick1}
                              onClose={this.close}
                              closeIcon
                            >
                              <Modal.Header>{this.strings.add_new_edit}</Modal.Header>
                              <Modal.Content image>
                                <Segment placeholder style={{ margin: '2%', maxWidth:"300px" }}>
                                  <Header icon>
                                    <Icon name='file image outline' />
                                    {this.strings.add_new_upload_image}
                                  </Header>
                                  <Button primary>{this.strings.add_new_image}</Button>
                                </Segment>
                                <Modal.Description>
                                  <Header>{this.strings.product_row_details}</Header>
                                  <Form>
                                    <Form.Group unstackable widths={2}>
                                      <Form.Input label='Product name' placeholder={this.state.editProductName} type='text' onChange={e => this.setState({ newProductName: e.target.value })} />
                                      <Form.Input label='Product Ingredients' placeholder={this.state.newProductIngredients} type='text' onChange={e => this.setState({ newProductIngredients: e.target.value })} />
                                      <Form.Input label='Product Allergens' placeholder={this.state.newProductAller} type='text' onChange={e => this.setState({ newProductAller: e.target.value })} />
                                      <Form.Input label='Product Units' placeholder={this.state.newProductUnits} type='text' onChange={e => this.setState({ newProductUnits: e.target.value })} />
                                    </Form.Group>
                                    <Form.Checkbox
                                      as='link'
                                      label='I agree to the Terms and Conditions'
                                      onChange={this.handleChangeCheckBox}
                                    />
                                    <Modal
                                      trigger={
                                        <Button type='submit' secondary disabled={!this.state.newProductAgreeTerms}>{this.strings.add_new_update}</Button>
                                      }
                                      basic
                                      size='small'
                                      closeOnEscape={false}
                                      closeOnDimmerClick={false}
                                      onClose={this.close}
                                    >
                                      <Header icon='birthday cake' content='Adding new product' />
                                      <Modal.Content>
                                        <p>
                                          {this.strings.add_new_are_you}
                                          </p>
                                      </Modal.Content>
                                      <Modal.Actions>
                                        <Button basic color='red' inverted onClick={this.close}>
                                          <Icon name='remove' /> {this.strings.add_new_no}
                                          </Button>
                                        <Button color='green' inverted onClick={() => this.editProduct(this.state.newProductName, this.state.newProductIngredients, this.state.newProductAller, this.state.newProductUnits)}>
                                          <Icon name='checkmark' /> {this.strings.add_new_yes}
                                          </Button>
                                      </Modal.Actions>
                                    </Modal>

                                  </Form>
                                </Modal.Description>
                              </Modal.Content>
                            </Modal>
                            <Button negative onClick={() => this.deleteProduct(product._id)}>{this.strings.button_delete}</Button>
                              </Item.Extra>
                        </Item.Content>
                       )
                        }
                        </Item>  
                        : 
                        null
                        )
                        )
            }
            <Modal
                trigger={
                  <Item onClick={this.closeConfigShow(true, false)} style={{ display: 'flex', alignItems: 'center', cursor: "pointer" }}>
                    <Item.Content style={{ justifyContent: 'center', alignItems: 'center', verticalAlign: "middle !important", display:'flex' }}>
                      <div>
                      <Icon circular inverted color="green" name="plus" size="huge" />
                      <Header as="h3" style={{ textAlign: "center" }}>{this.strings.button_add_product}</Header>
                      </div>
                    </Item.Content>
                  </Item>
                }
                centered={false}
                open={open}
                closeOnEscape={closeOnEscape}
                closeOnDimmerClick={closeOnDimmerClick}
                onClose={this.close}
                closeIcon
              >
                <Modal.Header>{this.strings.add_new_header}</Modal.Header>
               { productTypeSelected === 'none' ?
                 (<Modal.Content>
                <Header as="h3" textAlign="center">
                  {this.strings.add_new_product_type}
                </Header>
                <Card.Group centered>
                  <Card onClick={this.chooseBox}>
                   {/*<Image
                      src={find_food}
                      wrapped
                      style={{
                        background: "none !important",
                        maxHeight: "300px"
                      }}
                    />*/}
                    <Card.Content>
                      <Card.Header textAlign="center">{this.strings.add_new_surprise_box_header}</Card.Header>

                      <Card.Description>
                       {this.strings.add_new_surprise_box_desc}
                      </Card.Description>
                    </Card.Content>
                  </Card>
                  <Card onClick={this.chooseCustom}>
                    {/*<Image
                      src={sell_food}
                      wrapped
                      style={{
                        background: "none !important",
                        maxHeight: "300px"
                      }}
                    />*/}
                    <Card.Content>
                      <Card.Header textAlign="center">{this.strings.add_new_custom_box_header}</Card.Header>

                      <Card.Description>
                        {this.strings.add_new_custom_box_desc}
                      </Card.Description>
                    </Card.Content>
                  </Card>
                </Card.Group>
              </Modal.Content>
              ) 
              : productTypeSelected === "box" ?
                (<Modal.Content image>
                  <Segment placeholder style={{ margin: '2%', maxWidth:"300px" }}>
                    
                      { newProductImageName === "default" ?
                      (<>
                      <Image centered src="https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/default%2Ffood_bag.svg?alt=media&token=7d5a0451-c8c7-418c-94bd-f90f41eab67d" size="tiny"/>
                     <p style={{textAlign:"center", marginTop:"1rem"}}> {this.strings.add_new_def_image}</p>
                     </>
                     )
                     :
                     (
                      <Image centered src={newProductImageURL} size="tiny"/>
                     )
                     }
                      <Divider horizontal>{this.strings.add_new_or}</Divider>
                      <CustomUploadButton
                            className="upload_button"
                            style={{margin: '2rem', backgroundColor: '#99D68F', color: 'white', padding: 10, borderRadius: 4, fontSize: '1rem',fontWeight: 'bold'}}
                            required
                            accept="image/*"
                            name="product"
                            randomizeFilename
                            storageRef={firebase.storage().ref("images")}
                            beforeuploadstart={file => { if (file.size > MAX_SIZE) throw Error('File too large') }}
                            onUploadStart={this.handleUploadStart}
                            onUploadError={this.handleUploadError}
                            onUploadSuccess={this.handleUploadSuccess}
                            onProgress={this.handleProgress}
                            metadata={{cacheControl: 'max-age=3600'}}
                          >
                          {this.strings.add_new_own}
                          </CustomUploadButton>
                  </Segment>
                  <Modal.Description>
                    <Header>{this.strings.product_row_details}</Header>
                    <Form >
                      
                        <Form.Input disabled label={this.strings.surprise_box_label} placeholder={this.strings.surprise_box_placeholder} type='text' value={this.strings.surprise_box_placeholder} onChange={e => this.setState({ newProductName: e.target.value })} className= "short_input"/>
                        
                        <Form.Field
                          width="16"
                          name="newProductDescription"
                          required
                          control={TextArea}
                          value={newProductDescription}
                          onChange={this.handleFormChange}
                          label={this.strings.desc_short}
                          placeholder={this.strings.desc_form_placeholder}
                        />
                      <Form.Dropdown label={this.strings.allergens_label} placeholder={this.strings.allergend_placeholder} fluid multiple selection options={this.props.user["https://fc-demo/language"] === 'bg' ? allergensBG : allergens} value={newProductAller} onChange={this.handleAllergensChange}/>
                       <Form.Checkbox
                        label={this.strings.terms}
                        onChange={this.handleChangeCheckBox}
                      />
                          <Button type='submit' secondary disabled={!this.state.newProductAgreeTerms}
                          onSubmit={() => this.saveProductToDB(this.props.user.sub, this.state.newProductName, this.state.newProductAller, this.state.newProductIngredients, this.state.newProductUnits, 'няма данни', 'няма данни', 'няма данни', 'няма данни')}
                          >{this.strings.button_add_product}</Button>
                    </Form>
                  </Modal.Description>
                </Modal.Content>
                ) 
                :
                (
                  <Modal.Content image>
                  <Segment placeholder style={{ margin: '2%', maxWidth:"300px" }}>
                    
                      { newProductImageName === "default" ?
                      (<>
                      <Image centered src="https://firebasestorage.googleapis.com/v0/b/foodchainapp-1531482755524.appspot.com/o/default%2Ffood_bag.svg?alt=media&token=7d5a0451-c8c7-418c-94bd-f90f41eab67d" size="tiny"/>
                     <p style={{textAlign:"center", marginTop:"1rem"}}> {this.strings.add_new_def_image}</p>
                     </>
                     )
                     :
                     (
                      <Image centered src={newProductImageURL} size="tiny"/>
                     )
                     }
                      <Divider horizontal>{this.strings.add_new_or}</Divider>
                      <CustomUploadButton
                            className="upload_button"
                            style={{margin: '2rem', backgroundColor: '#99D68F', color: 'white', padding: 10, borderRadius: 4, fontSize: '1rem',fontWeight: 'bold'}}
                            required
                            accept="image/*"
                            name="product"
                            randomizeFilename
                            storageRef={firebase.storage().ref("images")}
                            beforeuploadstart={file => { if (file.size > MAX_SIZE) throw Error('File too large') }}
                            onUploadStart={this.handleUploadStart}
                            onUploadError={this.handleUploadError}
                            onUploadSuccess={this.handleUploadSuccess}
                            onProgress={this.handleProgress}
                            metadata={{cacheControl: 'max-age=3600'}}
                          >
                          {this.strings.add_new_own}
                          </CustomUploadButton>
                  </Segment>
                  <Modal.Description>
                    <Header>{this.strings.product_row_details}</Header>
                    <Form >
                        <Form.Group style = {{alignItems: "flex-end"}}>
                        <Form.Input required label={this.strings.surprise_box_label} placeholder={this.strings.surprise_box_label_placeholder} type='text' value={newProductName} onChange={e => this.setState({ newProductName: e.target.value })} />
                        <Input
                        label={<Dropdown options={this.props.user["https://fc-demo/language"] === 'bg' ? unitsDropDownBG : unitsDropDown} defaultValue={this.props.user["https://fc-demo/language"] === 'bg' ? unitsDropDownBG[0].value : unitsDropDown[0].value}  onChange={e => this.setState({newProductUnitsType: e.target.value})} />}
                        labelPosition='right'
                          placeholder={this.strings.weight}
                          onChange={e => this.setState({ newProductUnitsValue: e.target.value })}
                          className="input_dropdown_label"
                          type="number"
                        />
                        </Form.Group>

                        <Form.Group style = {{alignItems: "flex-end"}}>
                          <Form.Input label={this.strings.food_info_cal} placeholder={this.strings.placeholder_cal} type='number' value={newProductCal} onChange={e => this.setState({ newProductCal: e.target.value })} />{this.strings.unit_kcal}
                          <Form.Input label={this.strings.food_info_carb} placeholder={this.strings.placeholder_carb} type='number' value={newProductCarb} onChange={e => this.setState({ newProductCarb: e.target.value })} />{this.strings.unit_gr}
                        </Form.Group>

                        <Form.Group style = {{alignItems: "flex-end"}}>
                          <Form.Input label={this.strings.food_info_fat} placeholder={this.strings.placeholder_fat} type='number' value={newProductFat} onChange={e => this.setState({ newProductFat: e.target.value })} />{this.strings.unit_gr}
                          <Form.Input label={this.strings.food_info_prot} placeholder={this.strings.placeholder_prot} type='number' value={newProductProt} onChange={e => this.setState({ newProductProt: e.target.value })} />{this.strings.unit_gr}
                        </Form.Group>

                        <Form.Group style = {{alignItems: "flex-end"}}>
                          <Form.Input label={this.strings.food_eco_save_water} placeholder={this.state.newProductSavedWater} type='number' value={newProductSavedWater} onChange={e => this.setState({ newProductSavedWater: e.target.value })} />{this.strings.unit_liter}
                          <Form.Input label={this.strings.food_eco_save_co2} placeholder={this.state.newProductSavedCO2} type='number' value={newProductSavedCO2} onChange={e => this.setState({ newProductSavedCO2: e.target.value })} />{this.strings.unit_gr}
                        </Form.Group>
                
                        <Form.Field
                          width="16"
                          name="newProductDescription"
                          required
                          control={TextArea}
                          //value={newProductDescription}
                          onChange={this.handleFormChange}
                          label={this.strings.desc_short}
                          placeholder={this.strings.desc_form_placeholder}
                        />
                      <Form.Dropdown label={this.strings.allergens_label} placeholder={this.strings.allergend_placeholder} fluid multiple selection options={this.props.user["https://fc-demo/language"] === 'bg' ? allergensBG : allergens} value={newProductAller} onChange={this.handleAllergensChange}/>
                      <Form.Checkbox
                        label={this.strings.terms}
                        onChange={this.handleChangeCheckBox}
                      />
                      <Button type='submit' secondary disabled={!this.state.newProductAgreeTerms} onClick={() => this.saveProductToDB(this.props.user.sub, this.state.newProductName, this.state.newProductDesc, this.state.newProductAller, this.state.newProductIngredients, this.state.newProductUnitsType, this.state.newProductUnitsValue, this.state.newProductCal, this.state.newProductCarb, this.state.newProductFat, this.state.newProductProt)}>{this.strings.button_add_product}</Button>
                      
                    </Form>
                  </Modal.Description>
                </Modal.Content>
                )
              }
              </Modal>
            </Item.Group>
            </Segment>
          </div>
     
      
    )
  }
}
