import React from 'react'
import { Table, Header, Button } from 'semantic-ui-react'

function productUrl() {
    let url = window.location.href.toString()
    let subUrl = url.substring(34)
    return (
      subUrl
    )
  }

const ReportItem = () => (
    <div>
      <Header as='h2' style={{color: '#19263D'}}>Reporting Item</Header>
      <Table>
        <Table.Row>
          <Table.Cell>
            <Header as="h4">
              ID: 
              {productUrl()}
            </Header>
          </Table.Cell>
        </Table.Row>
        <Button content='Submit' primary />
      </Table>
    </div>
)

export default ReportItem
