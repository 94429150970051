/* eslint-disable max-len */
// For this screen we need to consider pending orders, completed orders, inventories
import React, { Component } from 'react'
import { Segment, Statistic, Dimmer, Loader, Grid, Item, Image, Header } from 'semantic-ui-react'
import axios from 'axios';
import { css } from "emotion";
import StoresTableDashboard from "./../Stores/StoresTableDashboard";
import ProductsTableDashboard from "./../Products/ProductsTableDashboard";
import OrdersTableDashboard from "./../Orders/ordersDashboardTable";
import ProductsCountDashboard from "./../Products/ProductsCountDashboard";
import OrdersClientsDashboard from "./../Orders/ordersDashboardClients";
import StoresCountDashboard from "./../Stores/StoresCountDashboard";
import OrdersDashboardCount from "./../Orders/ordersDashboardCount";
import OrdersDashboardRevenue from "./../Orders/ordersDashboardRevenue";
import StoresCO2 from "./../Stores/StoreCO2";
import StoresWater from "./../Stores/StoreWater";
import StoresElectro from "./../Stores/StoreElectro";
import StoresFuel from "./../Stores/StoreFuel";
import StoresWaste from "./../Stores/StoreWaste";
import dog from "./../avatars/dog.png"
import cat from "./../avatars/cat.png"
import chick from "./../avatars/chick.png"
import rabbit from "./../avatars/rabbit.png"
import fish from "./../avatars/fish.png"
import parrot from "./../avatars/parrot.png"
import revenue from "./../images/dashboard/graph.svg"

var avatars = [dog, cat, chick, rabbit, fish, parrot];

const ui = css`
    .ui
    { 
    .statistic {
        display: flex;
        align-items: center;
    .label {
        margin-bottom: 1em;
    }
}}
`;

export default class ContainerExampleContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            orders: [],
            accounts: [],
            loading: true,
            loadingProducts: true,
            loadingOrders: true,
            loadingAccounts: true,
            avatar: null,
            sumValue: [],
            sumClients: [],
        }
    }

    componentDidMount() {
        this.setState({ avatar: this.chooseRandom(avatars) })
    }

    chooseRandom(list) {
        var rand = list[Math.floor(Math.random() * list.length)];
        return rand
    }

    getData(userid) {
        const findPipe = userid.indexOf("|");
        const id = userid.substr(findPipe+1);
        if (this.state.loading) {
            this.getOrdersByToken(id)
            //this.getProductsDataFromDbByAccountId(userid)
            this.setState({ loading: false })
        }
    };

    getProductsDataFromDbByAccountId = (userid) => {
        axios.get(`https://api.dontwaste.me/products/accounts/${userid}`, {})
            .then(products => { this.setState({ products: products.data.products}) })
            .catch(err => {console.log(err);});
    };

    getOrdersByToken = (userid) => {
        axios.get(`https://api.dontwaste.me/orders/accounts/${userid}`, {})
            .then(orders => (this.setState({ orders: orders.data.orders}), console.log('orders: ',orders.data.orders)))
            .catch(err => {console.log(err);});
    };

    render() {
        const { loading, avatar } = this.state
        return (
                    <div className={ui} >
                        <Dimmer active={loading} inverted data={this.getData(this.props.user.sub)} console={console.log('Refresh in Dimmer')}>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        {console.log('Refresh outside Dimmer')}
                        <Item style={{ display: 'flex', marginTop: "2%", marginBottom: '3%' }}>
                            <Item.Image size='tiny' src={avatar} style={{ marginRight: "3%" }} />

                            <Item.Content>
                                <Item.Header as='h2'>{this.props.dashboard_welcome}, {this.props.user.nickname ? this.props.user.nickname : 'FoodChain Hero'} </Item.Header>
                                <Item.Description>
                                    {this.props.dashboard_label}
                                </Item.Description>
                            </Item.Content>
                        </Item>

                        <Grid stackable style={{ textAlign: 'center' }}>
                            <Grid.Row stretched>

                                <ProductsCountDashboard 
                                    userid={this.props.user.sub}
                                    user_type={this.props.user["https://fc-demo.platform/meta_data"].account_type}
                                    dashboard_box_products={this.props.dashboard_box_products}
                                />

                                <StoresCountDashboard 
                                    userid={this.props.user.sub}
                                    user_type={this.props.user["https://fc-demo.platform/meta_data"].account_type}
                                    dashboard_box_products={this.props.dashboard_box_products}
                                />
                                
                                <OrdersDashboardCount 
                                    userid={this.props.user.sub}
                                    user_type={this.props.user["https://fc-demo.platform/meta_data"].account_type}
                                    dashboard_box_orders={this.props.dashboard_box_orders}
                                />

                                
                                <OrdersClientsDashboard 
                                    userid={this.props.user.sub}
                                    user_type={this.props.user["https://fc-demo.platform/meta_data"].account_type}
                                    dashboard_box_clients={this.props.dashboard_box_clients}
                                />                
                                

                                <OrdersDashboardRevenue 
                                    userid={this.props.user.sub}
                                    user_type={this.props.user["https://fc-demo.platform/meta_data"].account_type}
                                    dashboard_box_orders={this.props.dashboard_box_orders}
                                />
                                                
                                

                                
                                                
                            </Grid.Row>
                            

                            {/* <Grid.Row stretched>

                                <ProductsCountDashboard 
                                    userid={this.props.user.sub}
                                    user_type={this.props.user["https://fc-demo.platform/meta_data"].account_type}
                                    dashboard_box_products={this.props.dashboard_box_products}
                                />

                                <OrdersClientsDashboard 
                                    userid={this.props.user.sub}
                                    user_type={this.props.user["https://fc-demo.platform/meta_data"].account_type}
                                    dashboard_box_clients={this.props.dashboard_box_clients}
                                />

                                <ProductsCountDashboard 
                                    userid={this.props.user.sub}
                                    user_type={this.props.user["https://fc-demo.platform/meta_data"].account_type}
                                    dashboard_box_products={this.props.dashboard_box_products}
                                />

                                <OrdersClientsDashboard 
                                    userid={this.props.user.sub}
                                    user_type={this.props.user["https://fc-demo.platform/meta_data"].account_type}
                                    dashboard_box_clients={this.props.dashboard_box_clients}
                                />

                                <ProductsCountDashboard 
                                    userid={this.props.user.sub}
                                    user_type={this.props.user["https://fc-demo.platform/meta_data"].account_type}
                                    dashboard_box_products={this.props.dashboard_box_products}
                                />


                            </Grid.Row> */}

                            <Grid.Row stretched>

                                <StoresCO2 
                                    userid={this.props.user.sub}
                                    user_type={this.props.user["https://fc-demo.platform/meta_data"].account_type}
                                    dashboard_box_co2_kg= {this.props.dashboard_box_co2_kg}
                                    dashboard_box_co2= {this.props.dashboard_box_co2}
                                />

                                <StoresWater
                                    userid={this.props.user.sub}
                                    user_type={this.props.user["https://fc-demo.platform/meta_data"].account_type}
                                    dashboard_box_water_l= {this.props.dashboard_box_water_l}
                                    dashboard_box_water= {this.props.dashboard_box_water}
                                />

                                <StoresElectro 
                                    userid={this.props.user.sub}
                                    user_type={this.props.user["https://fc-demo.platform/meta_data"].account_type}
                                    dashboard_box_co2_kg= {this.props.dashboard_box_co2_kg}
                                    dashboard_box_co2= {this.props.dashboard_box_co2}
                                />

                                <StoresFuel 
                                    userid={this.props.user.sub}
                                    user_type={this.props.user["https://fc-demo.platform/meta_data"].account_type}
                                    dashboard_box_co2_kg= {this.props.dashboard_box_co2_kg}
                                    dashboard_box_co2= {this.props.dashboard_box_co2}
                                />

                                <StoresWaste 
                                    userid={this.props.user.sub}
                                    user_type={this.props.user["https://fc-demo.platform/meta_data"].account_type}
                                    dashboard_box_co2_kg= {this.props.dashboard_box_co2_kg}
                                    dashboard_box_co2= {this.props.dashboard_box_co2}
                                />

                                

                            </Grid.Row>

                            

                            <Grid.Row stretched>
                                <Grid.Column width={16}>
                                    <Segment raised>
                                    <Header as="h3" textAlign= 'left' >
                                        <Header.Content>
                                        {this.props.dashboard_segment_products_header}
                                            <Header.Subheader>{this.props.dashboard_segment_products_label}</Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                        <ProductsTableDashboard 
                                            userid={this.props.user.sub}
                                            user_type={this.props.user["https://fc-demo.platform/meta_data"].account_type}
                                            dashboard_segment_products_header= {this.props.dashboard_segment_products_header}
                                            dashboard_segment_products_label= {this.props.dashboard_segment_products_label}
                                            dashboard_segment_products_image= {this.props.dashboard_segment_products_image}
                                            dashboard_segment_products_name= {this.props.dashboard_segment_products_name}
                                            dashboard_segment_products_desc= {this.props.dashboard_segment_products_desc}
                                            dashboard_segment_products_but_edit= {this.props.dashboard_segment_products_but_edit}
                                            dashboard_segment_products_but_add= {this.props.dashboard_segment_products_but_add}
                                            dashboard_segment_products_no_products= {this.props.dashboard_segment_products_no_products}
                                        />
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                            
                            
                            <Grid.Row stretched>

                                <Grid.Column width={16}>
                                    <Segment raised>
                                    <Header as="h3" textAlign= 'left' >
                                        <Header.Content>
                                            {this.props.dashboard_segment_stores_header}
                                            <Header.Subheader>
                                                {this.props.dashboard_segment_stores_label}
                                            </Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                            <StoresTableDashboard 
                                                userid={this.props.user.sub}
                                                user_type={this.props.user["https://fc-demo.platform/meta_data"].account_type}
                                                dashboard_segment_stores_header= {this.props.dashboard_segment_stores_header}
                                                dashboard_segment_stores_label= {this.props.dashboard_segment_stores_label}
                                                dashboard_segment_stores_name= {this.props.dashboard_segment_stores_name}
                                                dashboard_segment_stores_status= {this.props.dashboard_segment_stores_status}
                                                dashboard_segment_stores_type= {this.props.dashboard_segment_stores_type}
                                                dashboard_segment_stores_contact= {this.props.dashboard_segment_stores_contact}
                                                dashboard_segment_stores_actions= {this.props.dashboard_segment_stores_actions}
                                                dashboard_segment_stores_but_edit= {this.props.dashboard_segment_stores_but_edit}
                                                dashboard_segment_stores_but_add= {this.props.dashboard_segment_stores_but_add}
                                            />
                                        </Segment>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row stretched>

                                <Grid.Column width={16}>
                                    <Segment raised>
                                        <Header as="h3" textAlign= 'left' >
                                            <Header.Content>
                                                {this.props.dashboard_box_orders}
                                            <Header.Subheader>{this.props.dashboard_segment_orders_label}</Header.Subheader>
                                            </Header.Content>
                                        </Header>
                                            <OrdersTableDashboard 
                                                userid={this.props.user.sub}
                                                user_type={this.props.user["https://fc-demo.platform/meta_data"].account_type}
                                                dashboard_segment_orders_table_id= {this.props.dashboard_segment_orders_table_id}
                                                dashboard_segment_orders_table_status= {this.props.dashboard_segment_orders_table_status}
                                                dashboard_segment_orders_table_item_id= {this.props.dashboard_segment_orders_table_item_id}
                                                dashboard_segment_orders_table_item_qty= {this.props.dashboard_segment_orders_table_item_qty}
                                                dashboard_segment_orders_table_item_actions= {this.props.dashboard_segment_orders_table_item_actions}
                                                dashboard_segment_orders_table_but_report= {this.props.dashboard_segment_orders_table_but_report}
                                            />
                                        </Segment>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                    </div>
                    
                    
                )
    }
}
