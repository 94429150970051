import React from 'react';
import {
  NumberBox,
  RangeSlider,
  DataSearch,
  SelectedFilters,
  DatePicker,
  ToggleButton,
  SingleRange
} from '@appbaseio/reactivesearch';
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(isSameOrAfter);

export default class FilterComponents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      selectedDate: dayjs().format("YYYY-MM-DD"),
      currentDate: {}
    }
  }

  /* defaultQuery() {
    return {
      query: {
        match_all: {}
      }
    }
  } */

  render() {
    return (
      <>
    <h4>{this.props.filters_header}</h4>
    <hr />
    <SelectedFilters />
    <DataSearch
      componentId="Search"
      dataField={['name']}
      //defaultQuery={this.defaultQuery}
      title={this.props.filters_data_search_header}
      URLParam
      //defaultValue='Meal'
      //shows 
      renderNoSuggestion={() => (
        <div>
            {this.props.filters_no_suggestions}
        </div>
          )
      }
      //categoryField="authors.raw"
      //showIcon = "true"
    />
{
    // <MultiDataList
    //   componentId="CategoriesSensor"
    //   dataField="category_type"
    //   data={[
    //     {
    //       label: 'Mixed',
    //       value: 'mixed',
    //     },
    //     {
    //       label: 'Canned food',
    //       value: 'canned_food',
    //     },
    //     {
    //       label: 'Fruits',
    //       value: 'fruits',
    //     },
    //     {
    //       label: 'Meat',
    //       value: 'meat',
    //     },
    //     {
    //       label: 'Vegetables',
    //       value: 'vegetables',
    //     },
    //   ]}
    //   title="CATEGORIES"
    //   defaultSelected={['mixed', 'canned_food', 'fruits', 'meat', 'vegetables']}
    //   showSearch={false}
    // />
}
    <RangeSlider
      componentId="Price range"
      // dataField="regular_price"
      dataField="reduced_price"
      title={this.props.filters_price_range_header}
      range={{
        start: 1,
        end: 20,
      }}
      rangeLabels={{
        start: `1 ${this.props.filters_price_range_currency}`,
        end: `20 ${this.props.filters_price_range_currency}`,
      }}
      defaultValue={{
        start: 1,
        end: 20,
      }}
      stepValue={1}
      interval={1}
      // react={{
      //   and: ['DateRangeSensor'],
      // }}
    />

    <NumberBox
      componentId="Available quantity"
      // dataField="stock_quantity"
      dataField="quantity_available"
      title={this.props.filters_quantity_header}
      defaultValue={1}
      labelPosition="left"
      data={{"start": 1 , "end": 10}}
    />

    {/* <DatePicker
      componentId="DateSensor"
      dataField="item_exp"
      title="Pick-up Date"
      //defaultValue={{
        //start: new Date(),
        //end: new Date('2020-01-31')
      //}}
      placeholder={{
          start: 'Start Date',
          end: 'End Date'
      }}
      focused={true}
      numberOfMonths={1}
      //queryFormat="date"
      autoFocusEnd={true}
      showClear={true}
      showFilter={true}
      filterLabel="Date"
      URLParams={false}
    /> */}

            <DatePicker
              title={this.props.filters_date_header}
              componentId="DateSensor"
              placeholder={this.props.filters_date_pl}
              showFilter={true}
              defaultSelected={this.state.selectedDate}
              filterLabel="Date"
              //style={styles.filter}
              onValueChange={value => {
                console.log("onValueChange date", value);
              }}
              customQuery={function (value, props) {
                // console.log("time value", value);
                if (value !== null) {
                  //this.setState({ selectedDate: value });
                  let selected = dayjs(value);
                  //let now = dayjs().valueOf() * 1000;
                  // console.log("now", now);
                  //  console.log("GTE", selected.startOf("day").valueOf() * 1000);
                  return {
                    query: {
                      range: {
                        item_exp: {
                          gte: selected.startOf("day").valueOf()
                        }
                      }
                    }
                  };
                }
              }}
              
            />

            <ToggleButton
                componentId="DealTypes"
                title={this.props.filters_deal_type_header}
                dataField="deal_type"
                showFilter={true}
                customQuery={
                  value => {
                    if(value[0] !== undefined) {
                      //console.log('value[0]:', value[0])
                        return {
                        query: {
                          match: {
                            "deal_type": value[0].value
                          }
                        }
                      }
                    }
                    
                  }
                }
                multiSelect={false}
                URLParams={false}
                defaultValue={['']}
                data={[ { label: this.props.filters_deal_type_personal, value: 'PERSONAL' }, { label: this.props.filters_deal_type_business, value: 'BUSINESS' } ]}
            />


  </>

    )
  }
}

/* 
onValueChange={
                  value => {
                    if(value) {
                      if(value[0] !== undefined) {
                          if(value[0].value === 'PERSONAL') {
                            console.log('PERSONAL')
                            return {
                              query: {
                                match: {
                                  deal_type:"PERSONAL"
                                }
                              }
                            }
                          } else if(value[0].value === 'BUSINESS') {
                            console.log('BUSINESS')
                            return {
                              query: {
                                match: {
                                  deal_type:"BUSINESS"
                                }
                              }
                            }
                        }
                      }
                    }
                  }
                }
*/

