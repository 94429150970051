import React from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getAddedIds } from '../../reducers/cartReducer';
import { Label } from 'semantic-ui-react';

const CartGetQ = ({ quantities, qtyLocalStorage }) => {
    //console.log('qtyLocalStorage', Object.keys(qtyLocalStorage.quantityById).length)
    return (
        <div>
            {
                qtyLocalStorage ?
                    quantities.length || Object.keys(qtyLocalStorage.quantityById).length > 0 ? <Label color='green' floating style={{top: '0em', left: 'auto', padding: '0.3em 0.6em'}}>*</Label> : null
                : 
                    quantities.length > 0 ? <Label color='green' floating style={{top: '0em', left: 'auto', padding: '0.3em 0.6em'}}>*</Label> : null
            }
            {/* {quantities.length || Object.keys(qtyLocalStorage.quantityById).length > 0 ? <Label color='green' floating style={{top: '0em', left: 'auto', padding: '0.3em 0.6em'}}>*</Label> : null} */}
        </div>
    )
}

CartGetQ.propTypes = {
    quantities: PropTypes.object.isRequired
  }
  
  const mapStateToProps = state => ({
    quantities: getAddedIds(state.cart)
  })
  
  export default connect(mapStateToProps)(CartGetQ)