import React, { useState } from 'react';
//import PropTypes from 'prop-types'
import { connect, ReactReduxContext } from 'react-redux'
import { paymentProvider } from '../../reducers/productActions'
import { Link } from 'react-router-dom';
import { Icon, Card, Image, Segment, Button } from 'semantic-ui-react';
//import StripeUI from './StripeUI';
import EpayBGLogo from './images/epaybg_logo.png';
import StripeLogo from './images/stripe_logo.png';

const PaymentCards = ({ paymentProvider }) => {

  const [cardLabelElementEpay, setLabelEpay] = useState(false);
  const [cardLabelElementStripe, setLabelStripe] = useState(false);

  //console.log('cardLabelElementEpay', cardLabelElementEpay)
  //console.log('cardLabelElementStripe', cardLabelElementStripe)

  const showCardLabelEpay = () => {
    setLabelEpay(!cardLabelElementEpay)
    cardLabelElementEpay ? paymentProvider('none') : paymentProvider('epaybg')
    setLabelStripe(false)
  }

  const showCardLabelStripe = () => {
      setLabelStripe(!cardLabelElementStripe)
      cardLabelElementStripe ? paymentProvider('none') : paymentProvider('stripe')
      setLabelEpay(false)
  }
  
  //onClick={() => paymentProvider('stripe')}

  const isSetPaymentMethod = (store) => {
    console.log('isSetPaymentMethod', store.PaymentProvider)
    if(store.PaymentProvider === 'none') {
      return <div><Button primary disabled={true} type='submit'>Next</Button></div>
    } else {
      return <div><Link to={'/confirmation'}><Button primary type='submit'>Next</Button></Link></div>
    }
  }

  return (
    <div>
                        <Card.Group centered>
                          <Card as='a' onClick={ () => (showCardLabelEpay()) }>
                            <Image src={EpayBGLogo} wrapped ui={false} label={ cardLabelElementEpay ? ({corner: 'left', icon: 'check', color: 'green'}) : null }/>
                            <Card.Content>
                              <Card.Header>EpayBG</Card.Header>
                              <Card.Meta>
                                <span className='date'>Only for Bulgaria</span>
                              </Card.Meta>
                              {/* <Card.Description>
                                Matthew is a musician living in Nashville.
                              </Card.Description> */}
                            </Card.Content>
                            <Card.Content extra>
                                <Icon name='check' color='green'/>
                                Configured!
                            </Card.Content>
                          </Card>
                        
                        
                          <Card as='a' onClick={ () => (showCardLabelStripe()) }>
                            <Image src={StripeLogo} wrapped ui={false} label={ cardLabelElementStripe ? ({corner: 'left', icon: 'check', color: 'green'}) : null }/>
                            <Card.Content>
                              <Card.Header>Stripe</Card.Header>
                              <Card.Meta>
                                <span className='date'>Worldwide</span>
                              </Card.Meta>
                              {/* <Card.Description>
                                Matthew is a musician living in Nashville.
                              </Card.Description> */}
                            </Card.Content>
                            <Card.Content extra>
                                <Icon name='check' color='green'/>
                                  Configured!
                            </Card.Content>
                          </Card>
                        
                        </Card.Group>
                        <Segment.Group horizontal>
                          <Segment textAlign='left'><Link to={'/shipping'}><Button secondary type='submit'>Back</Button></Link></Segment>
                          <Segment textAlign='right'>
                            <ReactReduxContext.Consumer>
                              {({ store }) => (
                                isSetPaymentMethod(store.getState())
                              )}
                            </ReactReduxContext.Consumer>
                          </Segment>
                        </Segment.Group>
                        {/* {
                          !cardLabelElementStripe ? 
                            null
                            :
                            <div style={{ 'width': '25%', marginLeft: 'auto', marginRight: 'auto' }}>
                              <StripeUI />
                            </div>
                        } */}
                        
                    </div>
  )
}

export default connect('', { paymentProvider })(PaymentCards)
