import { combineReducers } from "redux";
import products, * as fromProducts from "./productReducer";
import cart, * as fromCart from './cartReducer'
import { byId, visibleIds, /* visibleIds_placeIds */ } from './productReducer'
import { PaymentProvider, setLanguage } from './cartReducer'
//adding new table structure and functions
//import { reducer as form } from "redux-form";
//adding new table structure and functions

export default combineReducers({
  byId,
  visibleIds,
  /* visibleIds_placeIds, */
  products,
  cart,
  PaymentProvider,
  setLanguage
  /* form */
});

const getAddedIds = state => fromCart.getAddedIds(state.cart)
export const getPlaceAddedIds = state => fromCart.getAddedIds_placeIds(state.cart)
const getQuantity = (state, _id) => fromCart.getQuantity(state.cart, _id)
const getProduct = (state, _id) => fromProducts.getProduct(state, _id)

export const getTotal = state =>
  getAddedIds(state)
    .reduce((total, _id) =>
      total + getProduct(state, _id).reduced_price * getQuantity(state, _id),
      0
    )
    .toFixed(2)

export const getCartProducts = state =>
  getAddedIds(state).map(_id => ({
    ...getProduct(state, _id),
    quantity_available: getQuantity(state, _id)
  }))

