import React from 'react';
import LocalizedStrings from 'react-localization';
import axios from 'axios';
import { getTotal } from '../../reducers/rootReducer';
//import { setCartToPaid } from '../../reducers/productActions';
import { toast } from 'react-toastify';
import { CardElement} from '@stripe/react-stripe-js';

import CardSection from './CardSection';



class CheckoutForm extends React.Component {
    constructor(props) {
        super(props);
        this.state ={
            payButtonClick: false,
            result: {},
            total: 0,
            store: {},
            totalLocalStorage: 0,
            totalSendToStripe: 0,
        }
    }

    componentDidMount() {
        this.setState({ total: parseFloat(getTotal(this.props.storeRedux)), totalLocalStorage: parseFloat(this.props.totalLocalStorageToStripe) })
    }

    strings = new LocalizedStrings({
        en:{
            notifyStripePayment: "Stripe Succesfull payment request",
            notifyStripeCheckPayment: "Stripe Checking payment...",
            notifyStripeErrorInfo: "Problem, please call Stripe Support.",
            notifyStripeCheckPaymentDone: "Stripe Payment is done, thank you!",
            notifyError: "Something went wrong!",
        },
        bg: {
            notifyStripePayment: "Успешна заявка за плащане през Stripe",
            notifyStripeCheckPayment: "Stripe Плашането се проверява...",
            notifyStripeErrorInfo: "Има проблем, моля свържете се с поддръжката.",
            notifyStripeCheckPaymentDone: "Успешно плащане през Stripe, благодарим Ви!",
            notifyError: "Нещо се обърка!",
          
        }
      });
    
      _setLanguage = (lang) => {
        //console.log(lang)
        this.strings.setLanguage(lang)
      }

    notifyStripePayment = () => toast.info(this.strings.notifyStripePayment);
    notifyStripeCheckPayment = () => toast.info(this.strings.notifyStripeCheckPayment);
    notifyStripeErrorInfo = () => toast.info(this.strings.notifyStripeErrorInfo);
    //const notifyStripeErrorInfo2 = () => toast.info("Payment was processed, but there is a problem with your account. Please call Stripe Support.");
    notifyStripeCheckPaymentDone = () => toast.success(this.strings.notifyStripeCheckPaymentDone);
    notifyError = () => toast.error(this.strings.notifyError);

    /* //total check must be outside Submit func
    //total is set in the button below! this.state.total === 0
    if(totalAmount > 0) {
        //totalToStripe = totalAmount
        this.setState({ total: totalAmount })
    } else if(totalLocalStorageToStripe > 0) {
        //totalToStripe = totalLocalStorageToStripe
        this.setState({ total: totalLocalStorageToStripe })
    } else {
        //totalToStripe = 0
        this.setState({ total: 0 })
    } */

    checkTotalToBeSentToStripe = () => {
        if(this.state.total > 0) {
            return this.state.total
        } else if(this.state.totalLocalStorage > 0) {
            return this.state.totalLocalStorage
        } else {
            return 0
        }
    }
    
    handleSubmit = async (event) => {
        this.setState({ payButtonClick: true })
        let clientSecret = '';
        //let totalToStripe = 0;
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        const { stripe, elements, storeRedux, totalLocalStorageToStripe, cartLocalStorageToStripe, user, setCartToPaid } = this.props
        console.log('user CheckourFromClass:', user)
        //console.log('get storeRedux from handleSubmit', storeRedux)
        const totalAmount = getTotal(storeRedux)
        //console.log('totalAmount from Redux', totalAmount)
        //console.log('totalAmount from LocalStorage', totalLocalStorageToStripe)
        //console.log('Cart from Redux', storeRedux.cart)
        //console.log('Length Cart from Redux', Object.keys(storeRedux.cart.addedIds).length)
        //console.log('Cart from LocalStorage', cartLocalStorageToStripe)
        //console.log('Length Cart from LocalStorage', Object.keys(cartLocalStorageToStripe.addedIds).length)
        
        if ( !stripe || !elements || !storeRedux ) {
        // Stripe.js has not yet loaded.
        // Make  sure to disable form submission until Stripe.js has loaded.
        return 'loading';
        }

        const checkCartToBeSentToStripe = () => {
            if (Object.keys(storeRedux.cart.addedIds).length > 0) {
                return cartQuantities(storeRedux.cart)
            } else if (Object.keys(cartLocalStorageToStripe.addedIds).length > 0) {
                return cartQuantities(cartLocalStorageToStripe)
            } else return []
        }

        const cartQuantities = (cart) => {
            let quantities = [];
            Object.entries(cart.quantityById).map(
              (item, i) => {
                quantities.push({
                  'inventory_id': item[0],
                  'quantity': item[1].quantity,
                  'product_name': item[1].product_name,
                  'item_price': item[1].item_price,
                  'exp_date': item[1].exp_date,
                  'place_name': item[1].place_name,
                  'place_id': item[1].place_id
                });
                console.log('item:', item)
                console.log('item[0]:', item[0])
                console.log('item[1].place_name:', item[1].place_name)
              }
            );
            console.log('quantities: ',quantities);
            return quantities;
          };

        //const makePaymentStripe = await axios.post('https://api.dontwaste.me/orders/stripe/create_payment_intent', {
        //const makePaymentStripe = await axios.post('http://localhost:3004/stripe/create-payment-intent', {
        try {
            const makePaymentStripe = await axios.post('https://api.dontwaste.me/orders/stripe/create_payment_intent', {
                customer: user['https://fc-demo/stripe_customer_id'],
                //amount: 333,
                items: checkCartToBeSentToStripe(),
                total: this.checkTotalToBeSentToStripe(),
                currency: 'bgn',
                metadata: {
                    integration_check: "accept_a_payment"
                }
            })
            .then(res => res.status === 200 ? (this.notifyStripePayment(), clientSecret = res.data.clientSecret) : this.notifyError())
        } catch (error) {
            console.log(error);
            this.notifyError();
            }
        

        console.log(clientSecret)
        

        const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
            //name: 'Jenny Rosen',
            name: user.name
            },
        }
        });
        this.setState({ result: result.paymentIntent.status })

        
        if (result.error) {
        // Show error to your customer (e.g., insufficient funds)
        this.notifyStripeErrorInfo()
        console.log(result.error.message);
        } else {
        // The payment has been processed!
        this.notifyStripeCheckPayment();
        if (result.paymentIntent.status === 'succeeded') {
            this.notifyStripeCheckPaymentDone();
            setCartToPaid()
            // Show a success message to your customer
            // There's a risk of the customer closing the window before callback
            // execution. Set up a webhook or plugin to listen for the
            // payment_intent.succeeded event that handles any business critical
            // post-payment actions.
        }
        }

    };
    
    buttonText = () => {
        if(this.state.result === 'succeeded') {
            return this.props.stripe_success_pay_thank_you
        } else return this.props.stripe_but_text
    }

    isPaidProp = () => {
        if(this.state.result === 'succeeded') {
            return 'none'
        } else return 'block'
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
            {this._setLanguage(this.props.user["https://fc-demo/language"])}
            {/* {console.log('this.state.total:', this.state.total)} */}
            {/* {console.log('this.state.total:', typeof this.state.total)} */}
            {/* {console.log('this.state.totalLocalStorage:', this.state.totalLocalStorage)} */}
                <CardSection 
                    isPaid={this.isPaidProp()}
                    stripe_enter_card_details={this.props.stripe_enter_card_details}
                />
                <button className="stripebutton" disabled={ !this.props.stripe || this.state.payButtonClick === true || (this.state.total === 0 && this.state.totalLocalStorage === 0) }>{this.buttonText()}</button>
            </form>
        );
    }
}

export default CheckoutForm
