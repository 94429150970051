const rules = {
    VISITOR: {
      static: [
        "items:list",
        "orders:list",
        "venues:list", 
        "home-page:visit",
        "profile-page:visit",
        "dashboard-demo-page:visit"
      ],
      dynamic: {
        "items:edit": ({userId, itemOwnerId}) => {
          if (!userId || !itemOwnerId) return false;
          return userId === itemOwnerId;
        }
      }
    },
    BASIC: {
      static: [
        "items:list",
        "orders:list",
        "orders:create",
        "venues:list",
        "home-page:visit",
        "profile-page:visit",
        "dashboard-page:visit",
        "product-detail-page:visit",
        "users:getSelf",
        "users:updateSelf",
        "payment-method:add",
        "payment-method:remove"
      ],
      dynamic: {
        "items:edit": ({userId, itemOwnerId}) => {
          if (!userId || !itemOwnerId) return false;
          return userId === itemOwnerId;
        }
      }
    },
    VENUE: {
      static: [
        "items:list",
        "items:create",
        "items:edit",
        "items:delete",
        "items:status",
        "orders:list",
        "orders:create",
        "orders:edit",
        "orders:delete",
        "orders:status",
        "home-page:visit",
        "profile-page:visit",
        "dashboard-page:visit",
        "product-detail-page:visit",
        "users:list",
        "users:getSelf",
        "users:updateSelf",
        "payment-method:add",
        "payment-method:remove"
      ],
      dynamic: {
        "items:edit": ({userId, itemOwnerId}) => {
          if (!userId || !itemOwnerId) return false;
          return userId === itemOwnerId;
        },
        "orders:edit": ({userId, itemOwnerId}) => {
          if (!userId || !itemOwnerId) return false;
          return userId === itemOwnerId;
        }
      }
    },
    VENDOR: {
      static: [
        "items:list",
        "items:create",
        "items:edit",
        "items:delete",
        "items:status",
        "orders:list",
        "orders:create",
        "orders:edit",
        "orders:delete",
        "orders:status",
        "home-page:visit",
        "profile-page:visit",
        "dashboard-page:visit",
        "product-detail-page:visit",
        "users:list",
        "users:getSelf",
        "users:updateSelf",
        "payment-method:add",
        "payment-method:remove"
      ],
      dynamic: {
        "items:edit": ({userId, itemOwnerId}) => {
          if (!userId || !itemOwnerId) return false;
          return userId === itemOwnerId;
        }
      }
    },
    ADMIN: {
      static: [
        "items:list",
        "items:create",
        "items:edit",
        "items:delete",
        "items:status",
        "orders:list",
        "orders:create",
        "orders:edit",
        "orders:delete",
        "orders:status",
        "venues:list",
        "venues:create",
        "venues:edit",
        "venues:delete",
        "venues:status",
        "account:list",
        "account:create",
        "account:edit",
        "account:delete",
        "account:status",
        "home-page:visit",
        "profile-page:visit",
        "dashboard-page:visit",
        "product-detail-page:visit",
        "users:list",
        "users:getSelf",
        "users:updateSelf",
        "payment-method:add",
        "payment-method:remove"
      ],
      dynamic: {
        "items:edit": ({userId, itemOwnerId}) => {
          if (!userId || !itemOwnerId) return false;
          return userId === itemOwnerId;
        },
        "orders:edit": ({userId, itemOwnerId}) => {
          if (!userId || !itemOwnerId) return false;
          return userId === itemOwnerId;
        }
      }
    }
  };
  
  export default rules;