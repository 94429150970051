import React from 'react'
import { Segment, Step } from 'semantic-ui-react'

const style = {
    h1: {
        marginTop: '3em',
    },
    h2: {
        margin: '4em 0em 2em',
    },
    h3: {
        marginTop: '2em',
        padding: '2em 0em',
    },
    last: {
        marginTop: '50px',
    },
}

const OrderingStepsBilling = (props) => (
    <div>
        <Segment style={style.last}>
        <Step.Group fluid style={{'marginTop': '4em'}}>
            <Step completed disabled icon='shopping cart' title={props.steps_cart_header} description={props.steps_cart_label} />
            <Step completed disabled icon='plane' title={props.steps_shipping_header} description={props.steps_shipping_label} />
            <Step active icon='payment' title={props.steps_billing_header} description={props.steps_billing_label} />
            <Step
            disabled
            icon='info circle'
            title={props.steps_confirm_header}
            description={props.steps_confirm_label}
            />
        </Step.Group>
        </Segment>
    </div>
)

export default OrderingStepsBilling
