import React, { useState, useEffect } from 'react';
import { useAuth0 } from "../../react-auth0-spa";
import { Menu, Icon, Ref, Header, Divider } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import './menuStyles.css';

const PopUpMenu = (props) => {
  console.log(props)
  const { user, logout } = useAuth0();
  const [isOpenPopUpMenu, setIsOpenPopUpMenu] = useState(props.visible);
  const toggleMenu = React.createRef();

  const onClickOutsideHandler= (event) => {
    if (isOpenPopUpMenu  && !toggleMenu.current.contains(event.target)) {
      props.handleSideMenuClick();
    }
  };

  const onClickMenuItem = (event) => {
    if(isOpenPopUpMenu) {
      props.handleSideMenuClick();
    }
  };

  useEffect(() => {
    setTimeout(() => {window.addEventListener('click', onClickOutsideHandler);}, 10);
    setTimeout(() => {window.addEventListener('click', onClickMenuItem);}, 20);
    setIsOpenPopUpMenu({ isOpenPopUpMenu: props.visible });
    return () => {
      window.removeEventListener('click', onClickOutsideHandler);
      window.removeEventListener('click', onClickMenuItem);
    }
  }, []);

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin
    });

  return (
    <Ref innerRef={toggleMenu}>
    <Menu vertical className="hover_menu">
      <>
        <Header as="h3">
        {user.nickname}
        <Header.Subheader>
            {user.role}
        </Header.Subheader>
        </Header>
        <Divider/>
      </>
      <Link to={'/profile'}>
        <Menu.Item 
          name='userprofile' 
          
          link
          >
          <Icon name='user' />
            {props.popup_menu_my_profile}
        </Menu.Item>
      </Link>
      <Link to={'/dashboard'}>
        <Menu.Item
          name='dashboardAdmin'
          
          link
        >
          <Icon name='dashboard' />
            {props.popup_menu_dashboard}
        </Menu.Item>
      </Link>
      <Link to={'/help'}>
        <Menu.Item
          name='help' 
          
          link
          >
          <Icon name='help' />
            {props.popup_menu_help}
        </Menu.Item>
      </Link>
      <Link to={'/customer-care'}>
        <Menu.Item
          name='customer-care' 
          
          link
          >
          <Icon name='call' />
            {props.popup_menu_cust_care}
        </Menu.Item>
      </Link>
      <Link to={'/faq'}>
        <Menu.Item
          name='faq' 
          
          link
          >
          <Icon name='help circle' />
            {props.popup_menu_faq}
        </Menu.Item>
      </Link>
      <Menu.Item>
      <Menu.Item 
          link
          onClick={() => logoutWithRedirect()}
          >
          <Icon name='log out' />
            {props.popup_menu_logout}
        </Menu.Item>
      </Menu.Item>
    </Menu>
    </Ref>
  )
}

export default PopUpMenu