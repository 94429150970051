import React from 'react';
import { connect, ReactReduxContext } from 'react-redux'
import { Link } from 'react-router-dom';
import { Button, Segment } from 'semantic-ui-react';

const BottomButtons = (props) => {

    const isSetPaymentMethod = (store) => {
        console.log('isSetPaymentMethod', store.PaymentProvider)
        if(store.PaymentProvider === 'none') {
        return <div><Button color="red" disabled={true} type='submit'>{props.but_next}</Button></div>
        } else {
        return <div><Link to={'/confirmation'}><Button primary type='submit'>{props.but_next}</Button></Link></div>
        }
    }

    return (
        <div>
        <Segment.Group horizontal>
            <Segment textAlign='left'><Link to={'/shipping'}><Button secondary type='submit'>{props.but_back}</Button></Link></Segment>
            <Segment textAlign='right'>
            <ReactReduxContext.Consumer>
                {({ store }) => (
                isSetPaymentMethod(store.getState())
                )}
            </ReactReduxContext.Consumer>
            </Segment>
        </Segment.Group>
        </div>
    )
    }

export default connect()(BottomButtons)
