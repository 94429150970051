import React, { Component } from 'react'
import Iframe from 'react-iframe'

export default class ContainerExampleContainer extends Component {

    render() {
        return (
            <div>
                {/* <p>It was KIBANA in iframe</p> */}
                <Iframe
                    url='https://el.veloxoft.com/login?next=%2Fapp%2Fkibana'
                    width="1600px"
                    height="900px"
                    frameBorder={0}
                    id="myId"
                    className="myClassname"
                    position="relative"
                />
          </div>
        )
    }
}
