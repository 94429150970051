import React from 'react';
import { Route, Switch, BrowserRouter, Router } from "react-router-dom";
import { Container } from "reactstrap";
import { useAuth0 } from "./react-auth0-spa";
import history from './utils/history';
import PrivateRoute from "./components/PrivateRoute";
import Loading from "./components/Loading";
import { DashboardAdmin, Home, ItemsByVendor, ItemsDetails, DetailPageBuyNowShipping, DetailPageBuyNowBilling, DetailPageBuyNowConfirm, DetailPageBuyNowOrdered, ProfileMenuPage, CartPage } from './pages';
import TopBar from './components/TopBar';
import Help from './components/Help';
import ReportItem from './components/ReportItem';

const App = () => {
    const { loading } = useAuth0();

    if (loading) {
        return <Loading />;
    }

    return (
            <Router history={history}>
            <div id="app" className="d-flex flex-column h-100">
            <TopBar />
            <Container className="flex-grow-1 mt-5">
                <Switch>
                    <PrivateRoute exact path='/' component={Home}/>
                    <Route path='/itemsbyvendor' component = {ItemsByVendor}/>
                    <Route path='/items_details' component = {ItemsDetails}/>
                    <PrivateRoute path='/dashboard' component={DashboardAdmin} />
                    <PrivateRoute path='/profile' component={ProfileMenuPage} />
                    <PrivateRoute path='/cartpage' component={CartPage} />
                    <PrivateRoute path='/shipping' component={DetailPageBuyNowShipping} />
                    <PrivateRoute path='/billing' component={DetailPageBuyNowBilling} />
                    <PrivateRoute path='/confirmation' component={DetailPageBuyNowConfirm} />
                    <PrivateRoute path='/ordered' component={DetailPageBuyNowOrdered} />
                    <PrivateRoute path='/help' component={Help} />
                    <PrivateRoute path='/report_item'  component = {ReportItem}/>
                    <PrivateRoute path='/customer-care'  component = {Help}/>
                    <PrivateRoute path='/faq'  component = {Help}/>
                </Switch>
            </Container>
            </div>
            </Router>
    );
}

export default App;