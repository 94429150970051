import React, { useState } from 'react';
import { useAuth0 } from "../react-auth0-spa";
import LocalizedStrings from 'react-localization';
import { Form, Checkbox, Segment, Button } from 'semantic-ui-react';
import OrderingStepsShipping from '../components/OrderingSteps/indexShipping';
import { Link } from 'react-router-dom';

const Shipping = () => {
  const { user } = useAuth0();
  const [ checkBoxTake, setCheckBoxTake ] = useState(false);
  const [ checkBoxDel, setCheckBoxDel ] = useState(false);
  const [ nextBut, setNextBut ] = useState(true);

  const strings = new LocalizedStrings({
    en:{
      steps_cart_header: "Shopping Cart",
      steps_cart_label: "Your shopping cart",
      steps_shipping_header: "Shipping",
      steps_shipping_label: "Choose your shipping options",
      steps_billing_header: "Billing",
      steps_billing_label: "Enter billing information",
      steps_confirm_header: "Confirm Order",
      steps_confirm_label: "Verify order details",
      checkbox_take_by_myself: "Take by myself",
      checkbox_other_delivery: "Order delivery - only available for large quantities",
      but_next: "Next",
      but_back: "Back"
    },
    bg: {
      steps_cart_header: "Кошница",
      steps_cart_label: "Вашите продукти",
      steps_shipping_header: "Доставка",
      steps_shipping_label: "Изберете опция за доставка",
      steps_billing_header: "Плащане",
      steps_billing_label: "Въведете информация за плащане",
      steps_confirm_header: "Потвърждаване",
      steps_confirm_label: "Потвърдете информацията на поръчката",
      checkbox_take_by_myself: "Взимане на място",
      checkbox_other_delivery: "Доставка - възможна само за Бизнес сделки",
      but_next: "Напред",
      but_back: "Назад"
    }
   });

   const _setLanguage = () => {
    //console.log(lang)
    strings.setLanguage(user["https://fc-demo/language"])
  }

  _setLanguage();

  return (
    <div>
        <OrderingStepsShipping 
          steps_cart_header= {strings.steps_cart_header}
          steps_cart_label= {strings.steps_cart_label}
          steps_shipping_header= {strings.steps_shipping_header}
          steps_shipping_label= {strings.steps_shipping_label}
          steps_billing_header= {strings.steps_billing_header}
          steps_billing_label= {strings.steps_billing_label}
          steps_confirm_header= {strings.steps_confirm_header}
          steps_confirm_label= {strings.steps_confirm_label}
        />
        <Segment style={{marginLeft: "1%", marginRight: "1%"}}>
          <Form>
            <Form.Field disabled={checkBoxTake}>
            <Checkbox label={strings.checkbox_take_by_myself} disabled={checkBoxTake} onClick={() => (setCheckBoxDel(!checkBoxDel), setNextBut(!nextBut))}/>
              {/* <label>Take by myself</label> */}
              {/* <input placeholder='First Name' /> */}
            </Form.Field>
            <Form.Field disabled={checkBoxDel}>
            <Checkbox label={strings.checkbox_other_delivery} disabled={checkBoxDel} onClick={() => (setCheckBoxTake(!checkBoxTake), setNextBut(!nextBut))}/>
              {/* <label>Order delivery - only available for large quantities</label> */}
              {/* <input placeholder='Last Name' /> */}
            </Form.Field>
            {/* <Form.Field>
              <Checkbox label='I agree to the Terms and Conditions' />
            </Form.Field> */}
            <Segment.Group horizontal>
              <Segment textAlign='left'><Link to={'/cartpage'}><Button secondary type='submit'>{strings.but_back}</Button></Link></Segment>
              {/* <Segment textAlign='right'><Link to={`/billing/${this.productUrl()}`}><Button primary type='submit'>Next</Button></Link></Segment> */}
              <Segment textAlign='right' disabled={nextBut}><Link to={!nextBut ? '/billing' : '#'}><Button primary type='submit'>{strings.but_next}</Button></Link></Segment>
            </Segment.Group>  
          </Form>
        </Segment>
      </div>
  )
}

export default Shipping
