import React from 'react';
import { useAuth0 } from "../react-auth0-spa";
import LocalizedStrings from 'react-localization';
import { Segment, Button } from 'semantic-ui-react';
import OrderingStepsBilling from '../components/OrderingSteps/indexBilling';
import { Link } from 'react-router-dom';
import PaymentOptions from '../components/MyProfile/PaymentForm';

const Billing = () => {
  const { user } = useAuth0();

  const strings = new LocalizedStrings({
    en:{
      steps_cart_header: "Shopping Cart",
      steps_cart_label: "Your shopping cart",
      steps_shipping_header: "Shipping",
      steps_shipping_label: "Choose your shipping options",
      steps_billing_header: "Billing",
      steps_billing_label: "Enter billing information",
      steps_confirm_header: "Confirm Order",
      steps_confirm_label: "Verify order details",
      choose_header: "Choose Payment Provider",
      epaybg_text: "Only for Bulgaria",
      epaybg_activate: "Activate ePayBG",
      stripe_text: "Worldwide",
      configured_text: "Configured",
      but_next: "Next",
      but_back: "Back"
    },
    bg: {
      steps_cart_header: "Кошница",
      steps_cart_label: "Вашите продукти",
      steps_shipping_header: "Доставка",
      steps_shipping_label: "Изберете опция за доставка",
      steps_billing_header: "Плащане",
      steps_billing_label: "Въведете информация за плащане",
      steps_confirm_header: "Потвърждаване",
      steps_confirm_label: "Потвърдете информацията на поръчката",
      choose_header: "Изберете услуга за плащане",
      epaybg_text: "Само за България",
      epaybg_activate: "Активиране на ИпейБГ",
      stripe_text: "За целия свят",
      configured_text: "Настроен",
      but_next: "Напред",
      but_back: "Назад"
    }
   });

   const _setLanguage = () => {
    //console.log(lang)
    strings.setLanguage(user["https://fc-demo/language"])
  }

  _setLanguage();

  return (
    <div>
      <OrderingStepsBilling 
        steps_cart_header= {strings.steps_cart_header}
        steps_cart_label= {strings.steps_cart_label}
        steps_shipping_header= {strings.steps_shipping_header}
        steps_shipping_label= {strings.steps_shipping_label}
        steps_billing_header= {strings.steps_billing_header}
        steps_billing_label= {strings.steps_billing_label}
        steps_confirm_header= {strings.steps_confirm_header}
        steps_confirm_label= {strings.steps_confirm_label}
      />
      <Segment secondary textAlign={"center"}>
          <PaymentOptions 
            user={user}
            choose_header= {strings.choose_header}
            epaybg_text= {strings.epaybg_text}
            epaybg_activate= {strings.epaybg_activate}
            but_next= {strings.but_next}
            but_back= {strings.but_back}

          />
          
      </Segment>
       
    </div>
  )
}

export default Billing
