import React, { useState } from "react";
import axios from 'axios';
import { useAuth0 } from "../react-auth0-spa";
import LocalizedStrings from 'react-localization';
import Can from "../components/Can";
import MapPage from '../components/MapPage3';
import { ToastContainer, toast } from 'react-toastify';
import { Modal, Card, Image, Header } from "semantic-ui-react";
import AuthFlow from "../components/AuthFlow"
import sell_food from "./images/sell_food.svg";
import find_food from "./images/find_food.svg";
import "./home.css";
//import { string } from "prop-types";

const Home = () => {
  const { user, isAuthenticated } = useAuth0();
  //const [ userLanguage, setUserLanguage ] = useState(user["https://fc-demo/language"]);
  const [isOpenBuySell, setIsOpenBuySell] = useState(user["https://fc-demo/firstLogin"]);
  console.log('Home user', user)
  const [isOpenAuthFlow, setIsOpenAuthFlow] = useState(false);
  const toggle = () => setIsOpenBuySell(!isOpenBuySell);
  const toggleAuthFlow = () => setIsOpenAuthFlow(!isOpenAuthFlow);
  const user_geo_lat = 42.6954108;
  const user_geo_lng = 23.2539073;
  //const [loadingFirstTime, setloadingFirstTime] = useState(user["https://fc-demo/firstLogin"]);

  const strings = new LocalizedStrings({
    en:{
      moment_locale_prop: 'en-gb',
      notifyFirstCreatedPlace:"Your first FoodVenue is created. Congrats.",
      notifyConfirmPlace:"You already has a place.",
      notifyError:"Something went wrong!",
      welcome: "Welcome to FoodChain!",
      how_to_use_platform: "How would you like to use the platform?",
      buy_food: "Find food",
      i_want_to: "I want to discover reduced food around me.",
      sell_food: "Sell food",
      sell_food_desc: "I am a restaurant, cafe, supermarket or a food producer looking to sell my excess food.",
      sell_food_cont: "Register as a FoodChain supplier",
      alert_geolocation: "Sorry, no position available.",
      map_popup_place_row: "Venue",
      map_popup_qty_row: "qty",
      map_popup_price_row: "per 1 pcs",
      offers_time_remaining: " remaining",
      offers_area_org_price: "Original price: ",
      offers_area_red_price: "Reduced price: ",
      offers_area_qty: "Quantity available: ",
      offers_area_time: "Collect before: ",
      offers_area_no_res: "No results",
      offers_area_no_res_text: "Looks like there are no food items around you :( /n FoodChain vendors are busy uploading their left-over food, so be sure to check again soon.",
      map_render_error: "Something went wrong!",
      map_render_error_text: "Error details",
      filters_header: "Filter Results",
      filters_data_search_header: "Find food...",
      filters_data_search_pl: "Search",
      filters_price_range_header: "Price Range",
      filters_price_range_currency: "$",
      filters_quantity_header: "Available Quantity",
      filters_date_header: "Pick a date",
      filters_date_pl: "Pick a date",
      filters_deal_type_header: "Choose deal type",
      filters_deal_type_personal: "PERSONAL",
      filters_deal_type_business: "BUSINESS",
      filters_no_suggestions: "No suggestions found",
      product_detail_qty_choose: "Choose quantity",
      product_detail_qty_available: "available",
      product_detail_cart_but_text: "Go to Cart",
      product_detail_cart_sold_out: "Sold Out",
      product_detail_report: "Report a problem with this item",
      product_detail_env_header: "By buying this item you are saving:",
      product_detail_env_co2: "kg of C02",
      product_detail_env_water: "l of water",
      product_detail_away: "km away from you",
      product_detail_see_all: "See all products by this vendor",
      product_detail_buy_before: "Buy before: ",
      product_detail_pick_up_before: "Pick-up before: ",
      product_detail_desc: "Description: ",
      product_detail_aller: "Allergens: ",
      product_detail_ingr: "Ingredients: ",
      product_detail_cat_type: "Categoty type: ",
      product_detail_delivery: "Delivery: ",
      product_detail_pick_by: "Pick up by the Buyer",
      product_detail_sold_by: "Sold by",
      product_detail_vendor_desc: "Vendor description",
      product_detail_place: "Place:",
      product_detail_but_show: "Show",
      product_detail_but_show_more: "more",
      product_detail_but_show_less: "less",
    },
    bg: {
      moment_locale_prop: 'bg',
      notifyFirstCreatedPlace:"Поздравления! Вашият първи магазин е създаден.",
      notifyConfirmPlace:"Имате създаден магазин.",
      notifyError:"Нещо се обърка!",
      welcome: "Добре дошли във FoodChain!",
      how_to_use_platform: "Как искате да използвате нашата платформа?",
      buy_food: "Купувач",
      i_want_to: "Искам да намирам оферти за храна около мен.",
      sell_food: "Търговец",
      sell_food_desc: "Аз имам прекрасен ресторант, кафе, супермаркет или съм производител на хранителни продукти и искам да продавам.",
      sell_food_cont: "Регистрирайте се във FoodChain като бизнес.",
      alert_geolocation: "Геолокацията невъзможна!.",
      map_popup_place_row: "Обект",
      map_popup_qty_row: "бр.",
      map_popup_price_row: "за 1 брой",
      offers_time_remaining: " остават",
      offers_area_org_price: "Стандартна цена: ",
      offers_area_red_price: "Намалена цена: ",
      offers_area_qty: "Налично количество: ",
      offers_area_time: "Вземете преди: ",
      offers_area_no_res: "Няма резултати",
      offers_area_no_res_text: "Изглежда, че няма оферти около Вас :( /n Доставчиците във FoodChain въвеждат оферти моля, проверете по-късно.",
      map_render_error: "Нещо се обърка!",
      map_render_error_text: "Детайли за грешка",
      filters_header: "Филтриране на резултати",
      filters_data_search_header: "Намерете оферта по ключова дума...",
      filters_data_search_pl: "Търси",
      filters_price_range_header: "Ценови диапазон",
      filters_price_range_currency: "лв",
      filters_quantity_header: "Налично количество",
      filters_date_header: "Изберете срок на годност - до дата",
      filters_date_pl: "Изберете дата",
      filters_deal_type_header: "Тип на офертата",
      filters_deal_type_personal: "ПОТРЕБИТЕЛ",
      filters_deal_type_business: "БИЗНЕС",
      filters_no_suggestions: "Няма резултати",
      product_detail_qty_choose: "Изберете количество",
      product_detail_qty_available: "налични",
      product_detail_cart_but_text: "Към Кошницата",
      product_detail_cart_sold_out: "Продадено",
      product_detail_report: "Докладвай проблем с този продукт",
      product_detail_env_header: "Като закупите този продукт вие спестявате:",
      product_detail_env_co2: "кг C02",
      product_detail_env_water: "литра вода",
      product_detail_away: "км разстояние от вас",
      product_detail_see_all: "Вижте всички продукти от този доставчик",
      product_detail_buy_before: "Купете преди: ",
      product_detail_pick_up_before: "Вземете преди: ",
      product_detail_desc: "Описание: ",
      product_detail_aller: "Алергени: ",
      product_detail_ingr: "Хранителен състав: ",
      product_detail_cat_type: "Категория: ",
      product_detail_delivery: "Доставка: ",
      product_detail_pick_by: "Взимане от купувача",
      product_detail_sold_by: "Продава се от",
      product_detail_vendor_desc: "Описание доставчик",
      product_detail_place: "Обект:",
      product_detail_but_show: "Покажи",
      product_detail_but_show_more: "повече",
      product_detail_but_show_less: "по-малко",
    }
  });

  const _setLanguage = () => {
  //console.log(lang)
  strings.setLanguage(user["https://fc-demo/language"])
  }

  _setLanguage();

  const notifyFirstCreatedPlace = () => toast.success(strings.notifyFirstCreatedPlace);
  const notifyConfirmPlace = () => toast.info(strings.notifyConfirmPlace);
  const notifyError = () => toast.error(strings.notifyError);

  const savePlaceToDBFirstTimeLogin = (idsub, name, user_geo_lat, user_geo_lng, geolocation, telephone) => {
    const findPipe = idsub.indexOf("|");
    const id = idsub.substr(findPipe+1);
    if(isOpenBuySell) {
      if(typeof user_geo_lat === 'number' && typeof user_geo_lng === 'number') {
        axios.post("https://api.dontwaste.me/places/", {
          account_id: id,
          name: name,
          location: {
            coordinates: [user_geo_lat, user_geo_lng],
            geolocation: geolocation,
            id: id,
            type: 'Point'
          },
          contact: {
            name: name,
            phone_number: telephone
          },
          // place_image_url: image_url,
          // place_image_name: place_image_name,
          // description: description,
          created_by: name
        },
          {
            headers: {
              "Content-Type": "application/json"
            }
          }
        )
        .then(res => res.status === 201 ? notifyFirstCreatedPlace() : null)
        .catch(err => {
          console.log(err);
          notifyError();
        });
      } else {
        axios.post("https://api.dontwaste.me/places/", {
          account_id: id,
          name: name,
          location: {
            coordinates: [42.6954108, 23.2539073],
            geolocation: geolocation,
            id: id,
            type: 'Point'
          },
          contact: {
            name: name,
            phone_number: telephone
          },
          // place_image_url: image_url,
          // place_image_name: place_image_name,
          // description: description,
          created_by: name
        },
          {
            headers: {
              "Content-Type": "application/json"
            }
          }
        )
        .then(res => res.status === 201 ? notifyFirstCreatedPlace() : null)
        .catch(err => {
          console.log(err);
          notifyError();
        });
      }
    }
  };
  /* {isOpenBuySell ? savePlaceToDBFirstTimeLogin(user.sub, user.nickname, user_geo_lat, user_geo_lng) : notifyConfirmPlace()} */

  return (
    isAuthenticated ?
      <div>
        <ToastContainer />
              <Modal
                open={isOpenBuySell}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                dimmer="blurring"
              >
                <Header as="h2" textAlign="center">
                  {strings.welcome}
                </Header>
                <Modal.Content>
                  <Header as="h3" textAlign="center">
                    {strings.how_to_use_platform}
                  </Header>
                  <Card.Group centered>
                    <Card onClick={toggle}>
                      <Image
                        src={find_food}
                        wrapped
                        style={{
                          background: "none !important",
                          maxHeight: "300px"
                        }}
                        className="buy_sell_modal_card"
                      />
                      <Card.Content>
                        <Card.Header textAlign="center">{strings.buy_food}</Card.Header>

                        <Card.Description>
                          {strings.i_want_to}
                        </Card.Description>
                      </Card.Content>
                    </Card>
                    <Card onClick={toggle}>
                      <Image
                        src={sell_food}
                        wrapped
                        style={{
                          background: "none !important",
                          maxHeight: "300px"
                          
                        }}
                        className="buy_sell_modal_card"
                      />
                      <Card.Content>
                        <Card.Header textAlign="center">{strings.sell_food}</Card.Header>

                        <Card.Description>
                          {strings.sell_food_desc}
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  </Card.Group>
                </Modal.Content>
              </Modal>
              <Modal
                open={isOpenAuthFlow}
                onClose={toggleAuthFlow}
                dimmer="inverted"
                size='fullscreen'
                closeIcon
                closeOnDimmerClick={false}
              >
                <Header textAlign= "center" content={strings.sell_food_cont} />
                <Modal.Content>
                  <AuthFlow handleFinalSubmit={toggleAuthFlow}/>
                </Modal.Content>
              </Modal>
              <Can
                role={user["https://fc-demo/role"]}
                perform="dashboard-page:visit"
                yes={() => (
                  <div>
                    <MapPage 
                      moment_locale_prop={strings.moment_locale_prop}
                      alert_geolocation={strings.alert_geolocation} 
                      map_popup_place_row={strings.map_popup_place_row}
                      map_popup_qty_row={strings.map_popup_qty_row}
                      map_popup_price_row={strings.map_popup_price_row}
                      offers_time_remaining={strings.offers_time_remaining}
                      offers_area_org_price={strings.offers_area_org_price}
                      offers_area_red_price={strings.offers_area_red_price}
                      offers_area_qty={strings.offers_area_qty}
                      offers_area_timе={strings.offers_area_timе}
                      offers_area_no_res={strings.offers_area_no_res}
                      оffers_area_no_res_text={strings.оffers_area_no_res_text}
                      offers_area_time={strings.offers_area_time}
                      map_render_error={strings.map_render_error}
                      map_render_error_text={strings.map_render_error_text}
                      filters_header={strings.filters_header}
                      filters_data_search_header={strings.filters_data_search_header}
                      filters_data_search_pl={strings.filters_data_search_pl}
                      filters_price_range_header= {strings.filters_price_range_header}
                      filters_price_range_currency={strings.filters_price_range_currency}
                      filters_quantity_header={strings.filters_quantity_header}
                      filters_date_header={strings.filters_date_header}
                      filters_date_pl={strings.filters_date_pl}
                      filters_deal_type_header={strings.filters_deal_type_header}
                      filters_deal_type_personal={strings.filters_deal_type_personal}
                      filters_deal_type_business={strings.filters_deal_type_business}
                      filters_no_suggestions={strings.filters_no_suggestions}
                      product_detail_qty_choose={strings.product_detail_qty_choose}
                      product_detail_qty_available={strings.product_detail_qty_available}
                      product_detail_cart_but_text={strings.product_detail_cart_but_text}
                      product_detail_cart_sold_out={strings.product_detail_cart_sold_out}
                      product_detail_report={strings.product_detail_report}
                      product_detail_env_header={strings.product_detail_env_header}
                      product_detail_env_co2={strings.product_detail_env_co2}
                      product_detail_env_water={strings.product_detail_env_water}
                      product_detail_away={strings.product_detail_away}
                      product_detail_see_all={strings.product_detail_see_all}
                      product_detail_buy_before={strings.product_detail_buy_before}
                      product_detail_pick_up_before={strings.product_detail_pick_up_before}
                      product_detail_desc={strings.product_detail_desc}
                      product_detail_aller={strings.product_detail_aller}
                      product_detail_ingr={strings.product_detail_ingr}
                      product_detail_cat_type={strings.product_detail_cat_type}
                      product_detail_delivery={strings.product_detail_delivery}
                      product_detail_pick_by={strings.product_detail_pick_by}
                      product_detail_sold_by={strings.product_detail_sold_by}
                      product_detail_vendor_desc={strings.product_detail_vendor_desc}
                      product_detail_place={strings.product_detail_place}
                      product_detail_but_show={strings.product_detail_but_show}
                      product_detail_but_show_more={strings.product_detail_but_show_more}
                      product_detail_but_show_less={strings.product_detail_but_show_less}
                    />
                  </div>
                )}
                no={() => (
                  <div>
                    <p>You account is DEMO</p><p>You account is DEMO</p><p>You account is DEMO</p><p>You account is DEMO</p><p>You account is DEMO</p>
                  </div>
                )}
              />
              {isOpenBuySell ? savePlaceToDBFirstTimeLogin(user.sub, user.nickname, user_geo_lat, user_geo_lng, 'geolocation', 'telephone') : notifyConfirmPlace()}
          </div>
        : <div><p>not logged in</p></div>
  )
}

export default Home
