import React, { Component } from "react";
import axios from "axios";
import { Segment, Image, Grid, Statistic } from "semantic-ui-react";
import factory from "../images/dashboard/factory.svg";

export default class StoresCountDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
        places: [],
        loading: true
    };
  }  

  getPlacesDataFromDbByAccountId = (userid, user_type) => {
    const findPipe = userid.indexOf("|");
    const id = userid.substr(findPipe+1);
    let url = user_type === "ADMIN" ? 'https://api.dontwaste.me/places/' : `https://api.dontwaste.me/places/accounts/${id}`
    if (this.state.loading) {
    axios.get(`${url}`, {})
      .then(places => { this.setState({ places: places.data.places, loading: false}) })
      .catch(err => {console.log(err);});
    }
  };

  render() {
    return (
        <Grid.Column width={3} data={this.getPlacesDataFromDbByAccountId(this.props.userid, this.props.user_type)}>
            <Segment raised placeholder>
                <Statistic>
                    <Statistic.Label><Image src={factory} size="mini"/></Statistic.Label>
                    
                            <Statistic.Value>{this.state.places.length}</Statistic.Value>
                    
                    <Statistic.Label>Обекти</Statistic.Label>
                    {/* <Statistic.Label>{this.props.dashboard_box_products}</Statistic.Label> */}
                </Statistic>
            </Segment>
        </Grid.Column>
    );
  }
}
