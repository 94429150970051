import React from 'react';
import { useAuth0 } from "../react-auth0-spa";
import LocalizedStrings from 'react-localization';
import { Link } from 'react-router-dom'
import { Segment, Button, Header, Icon, Table } from 'semantic-ui-react';
import OrderingStepsOrdered from '../components/OrderingSteps/indexOrdered'

const Ordered = () => {
  const { user } = useAuth0();
  

  const strings = new LocalizedStrings({
    en:{
      steps_cart_header: "Shopping Cart",
      steps_cart_label: "Your shopping cart",
      steps_shipping_header: "Shipping",
      steps_shipping_label: "Choose your shipping options",
      steps_billing_header: "Billing",
      steps_billing_label: "Enter billing information",
      steps_confirm_header: "Confirm Order",
      steps_confirm_label: "Verify order details",
      ordered_header: "YOUR ORDER IS CONFIRMED",
      but_home: "GO TO HOME"
    },
    bg: {
      steps_cart_header: "Кошница",
      steps_cart_label: "Вашите продукти",
      steps_shipping_header: "Доставка",
      steps_shipping_label: "Изберете опция за доставка",
      steps_billing_header: "Плащане",
      steps_billing_label: "Въведете информация за плащане",
      steps_confirm_header: "Потвърждаване",
      steps_confirm_label: "Потвърдете информацията на поръчката",
      ordered_header: "ВАШАТА ПОРЪЧКА Е ПОТВЪРДЕНА",
      but_home: "КЪМ НАЧАЛО"
    }
   });

   const _setLanguage = () => {
    //console.log(lang)
    strings.setLanguage(user["https://fc-demo/language"])
  }

  _setLanguage();

  return (
    <div>
          <OrderingStepsOrdered 
            steps_cart_header= {strings.steps_cart_header}
            steps_cart_label= {strings.steps_cart_label}
            steps_shipping_header= {strings.steps_shipping_header}
            steps_shipping_label= {strings.steps_shipping_label}
            steps_billing_header= {strings.steps_billing_header}
            steps_billing_label= {strings.steps_billing_label}
            steps_confirm_header= {strings.steps_confirm_header}
            steps_confirm_label= {strings.steps_confirm_label}
          />
          <Header as='h1' textAlign='center'>{strings.ordered_header}</Header>
          {/* <Header as='h2' textAlign='center'>PLEASE WAIT THE PAYMENT CONFIRMATION DIALOG!</Header> */}
          <Segment style={{marginLeft: "1%", marginRight: "1%"}}>
            <Table textAlign='center' style={{padding: '2%'}} >
              <Table.Row>
                <Icon name='check' size='massive' color='green' />
              </Table.Row>
              <Table.Row>
                <Link to='/'><Button primary type='submit'>{strings.but_home}</Button></Link>
              </Table.Row>
              </Table>
          </Segment>
      </div>
  )
}

export default Ordered
