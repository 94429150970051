import React, { Component } from 'react';
import axios from 'axios';
import LocalizedStrings from 'react-localization';
import { ToastContainer, toast } from 'react-toastify';
import * as moment from "moment";
import { Icon, Label, Button, Header, Dimmer, Loader, Item, Segment, Grid, Statistic, Image, List} from 'semantic-ui-react';
import product from "../images/dashboard/barcode.svg";
import sleeping from "../images/empty/sleeping.png";
import "./styles.css";

class OrdersNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      loading: true,
      loadingOrders: true,
      opened: false
    };
  }

  strings = new LocalizedStrings({
    en:{
      header: "Purchases",
      header_desc: "These are your purchases.",
      ord_box: "Purchases",
      no_ord: "You have no purchases!",
      ord_det: "Purchase details:",
      ord_item: "item",
      ord_itemid: "item_id",
      ord_qty: "Quantity:",
      ord_id: "Purchase ID:",
      ord_created: "Created by:",
      ord_purchased: "Purchased by",
      ord_date: "Date:",
      show: "Show",
      hide: "Hide",
      notifyError: "Something went wrong!"
    },
    bg: {
      header: "Покупки",
      header_desc: "Това табло показва твоите покупки.",
      ord_box: "Покупки",
      no_ord: "Нямате покупки!",
      ord_det: "Детайли за покупката:",
      ord_item: "Продукт",
      ord_itemid: "Продукт Ид.Н.",
      ord_qty: "Количество:",
      ord_id: "Покупка Ид.Н.:",
      ord_created: "Създадена от:",
      ord_purchased: "Покупка от",
      ord_date: "Дата:",
      show: "Покажи",
      hide: "Скрий",
      notifyError: "Нещо се обърка!"
    }
   });

   _setLanguage = (lang) => {
    //console.log(lang)
    this.strings.setLanguage(lang)
  }

  getOrdersByToken = (userid) => {
    const findPipe = userid.indexOf("|");
    const id = userid.substr(findPipe+1);
    if (this.state.loadingOrders) {
      axios.get(`https://api.dontwaste.me/orders/purchases/accounts/${id}`, {
      })
      .then(res => { this.setState({ orders: res.data.orders, loadingOrders: false, loading: false }) })
      .catch(err => {
        console.log(err);
        this.notifyError()
      });
    }
    }

  calculatePickUpTime(timestamp) {
    //const divided = timestamp / 1000000;
    //var date = moment.unix(divided).format()
    var date = moment.unix(timestamp).format("dddd, MMMM Do YYYY, h:mm:ss a");
    //const date = new Date(timestamp/1000).toString()
    return date;
  }

  openExtra = () => {
    this.setState({opened: !this.state.opened})
  }

  notifyError = () => toast.error(this.strings.notifyError);

  render() {
    const { orders, loading, opened } = this.state;
    return (
              <div>
              {this._setLanguage(this.props.user["https://fc-demo/language"])}
              <ToastContainer />
              <Dimmer active={loading} inverted data={this.getOrdersByToken(this.props.user.sub)}>
                
                <Loader inverted>Loading</Loader>
                  </Dimmer>
                  <Grid stackable className="header_wrapper">
              <Grid.Column width={10}>
              <Item style={{ display: 'flex', marginBottom: '2rem' }}>
                            {/* <Icon name="food" size="huge" className="item_icon" /> */}
                            <Item.Content>
                                <Item.Header as='h2'>{this.strings.header}</Item.Header>
                                <Item.Description style={{maxWidth:'600px', color: "#4f5765"}}>
                                   {this.strings.header_desc}
                                </Item.Description>
                            </Item.Content>
                        </Item>
              </Grid.Column>
              
              <Grid.Column width={6}>
                  <Segment placeholder raised className="stats_wrapper">
                  <Statistic horizontal>
                                            <Statistic.Label><Image src={product} size="tiny" floated="left"/></Statistic.Label>
                                            {
                                                this.state.loading ?
                                                    <Loader active={true} size='medium' />
                                                    :
                                                    <Statistic.Value>{this.state.orders.length}</Statistic.Value>
                                            }
                                            <Statistic.Label>{this.strings.ord_box}</Statistic.Label>
                                        </Statistic>
                  </Segment>
              </Grid.Column>
            </Grid>
                    
          <Segment raised padded>
          { orders.length <= 0 ?
            ( <>
            <Image src={sleeping} centered verticalAlign="middle" size="medium" className="empty_state_image"/>
            <Header as="h3" textAlign="center">{this.strings.no_ord}</Header>
            </>)
          :
            <Item.Group divided className="product_list">
              {
                Object.values(orders).map(
                  (order, i) => (
                      <Item key={order._id}>
                          {loading ? (
                            <Item.Content>
                            <Item.Header as='a'>Header</Item.Header>
                            <Item.Meta>Description</Item.Meta>
                            <Item.Description>
                              <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                            </Item.Description>
                            <Item.Extra>Additional Details</Item.Extra>
                            </Item.Content>
                          )
                        :(
                        <Item.Content>
                        <Item.Header > {this.strings.ord_id} {order._id}</Item.Header>
                          <Item.Meta><Label color = {order.status === 'CONFIRMED' ? 'green' : 'red'}>{order.status}</Label></Item.Meta>
                          <Item.Description>
                          <Grid>
                            <Grid.Column width={8}>
                              <List>
                              <List.Item>
                                        <List.Content> {this.strings.created} {order.account_id}</List.Content>
                                      </List.Item>
                                      <List.Item>
                                        <List.Content> {this.strings.ord_id} {order.vendor_id}</List.Content>
                                      </List.Item>
                            <List.Item>
                                        <List.Content> {this.strings.ord_date} {this.calculatePickUpTime(order.created)}</List.Content>
                                      </List.Item>
                                      <List.Item>
                                        <List.Content>Address Line 1 </List.Content>
                                      </List.Item>
                                      <List.Item>
                                        <List.Content> Address Line 2 </List.Content>
                                      </List.Item>
                                    </List>
      
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Header as="h3">{this.strings.ord_det}</Header>
                                    {
                                      order.inventories.map(
                                        (item, i) => (
                                          <List>
                                            <List.Header>{this.strings.ord_item} {i+1}</List.Header>
                                              <List.Item className="reduced_section">
                                                <List.Icon name='balance scale' className="reduced_icon"/>
                                                <List.Content className="reduced_text">{this.strings.ord_itemid} {item.inventory_id}</List.Content>
                                              </List.Item>
                                              <List.Item>
                                                <List.Icon name='warning sign' />
                                                <List.Content>{this.strings.ord_qty} {item.quantity} </List.Content>
                                              </List.Item>
                                          </List>
                                        )
                                      )
                                    }
                              </Grid.Column>
                          </Grid>
                          </Item.Description>
                          {!opened ? null :
                          ( <Item.Extra>
                            <List>
                            <List.Item>
                                        <List.Icon name='balance scale' />
                                        <List.Content> {order.total_amount}</List.Content>
                                      </List.Item>
                                      <List.Item>
                                        <List.Icon name='marker' />
                                        <List.Content>{order.user_notes}</List.Content>
                                      </List.Item>
                                      <List.Item>
                                        <List.Icon name='warning sign' />
                                        <List.Content> {order.user_contacts} </List.Content>
                                      </List.Item>
                                    </List>
                            </Item.Extra>)}
                          <Item.Extra>
                            <Button primary onClick={this.openExtra}><Icon name={opened ? "chevron up" : "chevron down"}/>{!opened ? this.strings.show : this.strings.hide}</Button>
                          </Item.Extra>
                        </Item.Content>
                      )
                        }
                        </Item>  
                        )
                        )
            }
            </Item.Group>

            }
        </Segment>
              </div>
              
          )
        }
        
}

export default OrdersNew