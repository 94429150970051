import React from 'react';
import { useAuth0 } from "../../react-auth0-spa";
import { ToastContainer } from 'react-toastify';
import LocalizedStrings from 'react-localization';
import { Tab } from 'semantic-ui-react';
import PaymentProvidersProfile from './PaymentProvidersProfile';
import Profile from './Profile';
import { css } from "emotion";
import AuthFlow from '../AuthFlow';

const ProfileTabs = () => {
  const { user } = useAuth0();
  const ui = css`
    .menu {
      display: flex;
      justify-content: center;

      .active{
        color: #129490 !important;
      }
    }
  `;

  const strings = new LocalizedStrings({
    en:{
      menuItem_your_profile:"Your Profile",
      menuItem_payment_prov:"Payment Providers",
      menuItem_sell_food:"Sell Food"
    },
    bg: {
      menuItem_your_profile:"Вашият профил",
      menuItem_payment_prov:"Платежни инструменти",
      menuItem_sell_food:"Продавай храна"
    }
   });

  const _setLanguage = () => {
    //console.log(lang)
    strings.setLanguage(user["https://fc-demo/language"])
  }

  _setLanguage();

  const panes = [
    { menuItem: strings.menuItem_your_profile, render: () => <Tab.Pane><ToastContainer /><Profile user={user}/></Tab.Pane> },
    { menuItem: strings.menuItem_payment_prov, render: () => <Tab.Pane><PaymentProvidersProfile user={user}/></Tab.Pane> },
    { menuItem: strings.menuItem_sell_food, render: () => <Tab.Pane><AuthFlow user={user}/></Tab.Pane> },
  ]

  return (
    <Tab className={ui} panes={panes} />
  )
}

export default ProfileTabs
