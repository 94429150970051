import React from 'react';
import { useAuth0 } from "../react-auth0-spa";
import LocalizedStrings from 'react-localization';
import { Tab } from 'semantic-ui-react';
import MyProfile from '../components/MyProfile';
import Purchases from '../components/Purchases/';
import Products from '../components/Products';
import Inventories from '../components/Inventories/';
import OrdersNew from '../components/Orders';
import Stores from '../components/Stores';
import VendorDashboard from '../components/Dashboard/VendorDashboard';
import UserDashboard from '../components/Dashboard/UserDashboard';
import Insights from '../components/Insights';
import "./dashboard.css";

const DashboardAdmin = () => {
  const { user } = useAuth0();

  const strings = new LocalizedStrings({
    en:{
      tab_dashboard_content:"Dashboard",
      tab_insights_content:"Insights",
      tab_products_content:"Products",
      tab_inventories_content:"Inventories",
      tab_orders_content:"Orders",
      tab_places_content:"Places",
      tab_purchases_content:"My Purchases",
      tab_profile_content:"My Profile",
      dashboard_welcome: "Welcome back",
      dashboard_currency: "$",
      dashboard_label: "This is your FoodChain dashboard. View your orders, stores, and food metrics in a glance",
      dashboard_box_revenue: "REVENUE",
      dashboard_box_orders: "ORDERS",
      dashboard_segment_orders_header: "Orders",
      dashboard_segment_orders_label: "View your latest orders and let us know if there is a problem",
      dashboard_segment_orders_table_id: "ID",
      dashboard_segment_orders_table_status: "Status",
      dashboard_segment_orders_table_item_id: "Item ID",
      dashboard_segment_orders_table_item_qty: "Quantity",
      dashboard_segment_orders_table_item_actions: "Actions",
      dashboard_segment_orders_table_but_report: "Report a problem",
      dashboard_segment_products_header: "Products",
      dashboard_segment_products_label: "View your latest products and quickly add them to your inventory",
      dashboard_segment_products_image: "Image",
      dashboard_segment_products_name: "Name",
      dashboard_segment_products_desc: "Description",
      dashboard_segment_products_but_edit: "Edit",
      dashboard_segment_products_but_add: "Add Product",
      dashboard_segment_products_no_products: "No products",
      dashboard_box_products: "Products",
      dashboard_box_clients: "Clients",
      dashboard_box_co2_kg: "KG",
      dashboard_box_co2: "CO2 emissions reduced",
      dashboard_box_water_l: "L",
      dashboard_box_water: "Water rescued",
      dashboard_segment_stores_header: "View your stores",
      dashboard_segment_stores_label: "View and manage your latest stores at a glance. Click View more to find all of your places",
      dashboard_segment_stores_name: "Name",
      dashboard_segment_stores_status: "Status",
      dashboard_segment_stores_type: "Type",
      dashboard_segment_stores_contact: "Contact",
      dashboard_segment_stores_actions: "Actions",
      dashboard_segment_stores_but_edit: "Edit",
      dashboard_segment_stores_but_add: "Add Store",
      products_header: "Products",
      products_label: "This section contains all of your company's products. Think of it as a database where you can store all of the data for your products and when any of these products are about to expire you can quickly sell it at a reduced price by adding it to your 'Inventory'. Products are shared across all of your stores so that you don't have to list the same product many times.",
      products_products: "Products",
      products_rating: "Rating",
      products_product_detail_label: "Product details",
      products_but_edit: "Edit",
      products_but_delete: "Delete",
      products_add_product: "Add Product",
      inv_header: "Inventories",
      inv_label: "This is where the magic happens! Choose the store where you have left-over food, select the product, and set the reduced price and pick-up time for the customer. You can monitor the items that you have sold and adjust the price and quantity available if necessary.",
      inv_items_on_sale: "Items on sale",
      inv_items_sold: "of Items sold",
      inv_but_edit: "Edit",
      inv_but_delete: "Delete",
      inv_add_item: "Add Item",
      orders_header: "Orders",
      orders_label: "This section contains all of your company's products. Think of it as a database where you can store all of the data for your products and when any of these products are about to expire you can quickly sell it at a reduced price by adding it to your 'Inventory'. Products are shared across all of your stores so that you don't have to list the same product many times.",
      orders_orders: "ORDERS",
      orders_orders_id: "Order ID",
      orders_show: "Show",
      orders_show_more: "more",
      orders_show_less: "less",
      orders_order_details: "Order details",
      places_header: "Places",
      places_label: "These are all of the stores that belong to your Company. Do you own a food truck? Simply change the location associated with your store and you can start saving food wherever you are!",
      places_places: "PLACES",
      places_but_edit: "Edit",
      places_but_delete: "Delete",
      places_add_item: "Add Store",
      purchases_header: "Purchases",
      purchases_label: "These are your purchases.",
      purchases_purchases: "Purchases",
      purchases_orders_id: "Purchase ID",
      purchases_show: "Show",
      purchases_show_more: "more",
      purchases_show_less: "less",
      purchases_order_details: "Purchase details",
    },
    bg: {
      tab_dashboard_content:"Табло",
      tab_insights_content:"Метрики",
      tab_products_content:"Продукти",
      tab_inventories_content:"Инвентар",
      tab_orders_content:"Поръчки",
      tab_places_content:"Обекти",
      tab_purchases_content:"Покупки",
      tab_profile_content:"Профил",
      dashboard_welcome: "Добре дошли отново",
      dashboard_currency: "лв",
      dashboard_label: "Това е твоето FoodChain табло. Информация за продукти, поръчки, обекти и други метрики на едно място.",
      dashboard_box_revenue: "Оборот",
      dashboard_box_orders: "Поръчки",
      dashboard_segment_orders_header: "Поръчки",
      dashboard_segment_orders_label: "Виж последните си поръчки и ни сигнализирай ако има проблем",
      dashboard_segment_orders_table_id: "Иден. номер",
      dashboard_segment_orders_table_status: "Статут",
      dashboard_segment_orders_table_item_id: "Инвентар Ид.Н.",
      dashboard_segment_orders_table_item_qty: "Количество",
      dashboard_segment_orders_table_item_actions: "Действия",
      dashboard_segment_orders_table_but_report: "Сигнализирай за проблем",
      dashboard_segment_products_header: "Продукти",
      dashboard_segment_products_label: "Виж най-новите си продукти и бързо създай инвентар",
      dashboard_segment_products_image: "Снимка",
      dashboard_segment_products_name: "Име",
      dashboard_segment_products_desc: "Описание",
      dashboard_segment_products_but_edit: "Промени",
      dashboard_segment_products_but_add: "Добави продукт",
      dashboard_segment_products_no_products: "Нямате продукти",
      dashboard_box_products: "Продукти",
      dashboard_box_clients: "Клиенти",
      dashboard_box_co2_kg: "КГ",
      dashboard_box_co2: "CO2 спестени емисии",
      dashboard_box_water_l: "Литра",
      dashboard_box_water: "Запазена вода",
      dashboard_segment_stores_header: "Твоите обекти",
      dashboard_segment_stores_label: "Прегледай и промени обектите си ако има нужда",
      dashboard_segment_stores_name: "Име",
      dashboard_segment_stores_status: "Статут",
      dashboard_segment_stores_type: "Тип",
      dashboard_segment_stores_contact: "Контакт",
      dashboard_segment_stores_actions: "Действия",
      dashboard_segment_stores_but_edit: "Промени",
      dashboard_segment_stores_but_add: "Добави обект",
      products_header: "Продукти",
      products_label: "This section contains all of your company's products. Think of it as a database where you can store all of the data for your products and when any of these products are about to expire you can quickly sell it at a reduced price by adding it to your 'Inventory'. Products are shared across all of your stores so that you don't have to list the same product many times.",
      products_products: "Продукти",
      products_rating: "Рейтинг",
      products_product_detail_label: "Детайли продукт",
      products_but_edit: "Промени",
      products_but_delete: "Изтрий",
      products_add_product: "Добави продукт",
      inv_header: "Инвентар",
      inv_label: "This is where the magic happens! Choose the store where you have left-over food, select the product, and set the reduced price and pick-up time for the customer. You can monitor the items that you have sold and adjust the price and quantity available if necessary.",
      inv_items_on_sale: "Позиции за продажба",
      inv_items_sold: "продадени позиции",
      inv_but_edit: "Промени",
      inv_but_delete: "Изтрий",
      inv_add_item: "Добави инвентар",
      orders_header: "Поръчки",
      orders_label: "This section contains all of your company's products. Think of it as a database where you can store all of the data for your products and when any of these products are about to expire you can quickly sell it at a reduced price by adding it to your 'Inventory'. Products are shared across all of your stores so that you don't have to list the same product many times.",
      orders_orders: "ПОРЪЧКИ",
      orders_orders_id: "Поръчка Ид.Н.",
      orders_show: "Покажи",
      orders_show_more: "повече",
      orders_show_less: "по-малко",
      orders_order_details: "Детайли поръчка",
      places_header: "Обекти",
      places_label: "These are all of the stores that belong to your Company. Do you own a food truck? Simply change the location associated with your store and you can start saving food wherever you are!",
      places_places: "ОБЕКТИ",
      places_but_edit: "Промени",
      places_but_delete: "Изтрий",
      places_add_item: "Добави обект",
      purchases_header: "Покупки",
      purchases_label: "Това са твоите покупки.",
      purchases_purchases: "Покупки",
      purchases_orders_id: "Покупка Ид.Н.",
      purchases_show: "Покажи",
      purchases_show_more: "повече",
      purchases_show_less: "по-малко",
      purchases_order_details: "Детайли покупка",
    }
   });

   const _setLanguage = () => {
    //console.log(lang)
    strings.setLanguage(user["https://fc-demo/language"])
  }

  _setLanguage();

  let panes;
  console.log('user', user["https://fc-demo.platform/meta_data"].account_type)
  user["https://fc-demo.platform/meta_data"].account_type === "BASIC" ? (
  panes = [
    { menuItem: { key: 'dashboard', icon: 'dashboard', content: 'Dashboard' }, render: () => <Tab.Pane attached={false}><UserDashboard user={user}/></Tab.Pane> },
    { menuItem: { key: 'mypurchases', icon: 'cart arrow down', content: 'My Purchases' }, render: () => <Tab.Pane attached={false}><Purchases user={user}/></Tab.Pane> },
  ])
  :
  (
    panes = [
      { menuItem: { key: 'dashboard', icon: 'dashboard', content: strings.tab_dashboard_content }, render: () => 
        <Tab.Pane 
          attached={false}
        ><VendorDashboard 
          user={user}
          dashboard_welcome= {strings.dashboard_welcome}
          dashboard_currency= {strings.dashboard_currency}
          dashboard_label= {strings.dashboard_label}
          dashboard_box_revenue= {strings.dashboard_box_revenue}
          dashboard_box_orders= {strings.dashboard_box_orders}
          dashboard_segment_orders_header= {strings.dashboard_segment_orders_header}
          dashboard_segment_orders_label= {strings.dashboard_segment_orders_label}
          dashboard_segment_orders_table_id= {strings.dashboard_segment_orders_table_id}
          dashboard_segment_orders_table_status= {strings.dashboard_segment_orders_table_status}
          dashboard_segment_orders_table_item_id= {strings.dashboard_segment_orders_table_item_id}
          dashboard_segment_orders_table_item_qty= {strings.dashboard_segment_orders_table_item_qty}
          dashboard_segment_orders_table_item_actions= {strings.dashboard_segment_orders_table_item_actions}
          dashboard_segment_orders_table_but_report= {strings.dashboard_segment_orders_table_but_report}
          dashboard_segment_products_header= {strings.dashboard_segment_products_header}
          dashboard_segment_products_label= {strings.dashboard_segment_products_label}
          dashboard_segment_products_image= {strings.dashboard_segment_products_image}
          dashboard_segment_products_name= {strings.dashboard_segment_products_name}
          dashboard_segment_products_desc= {strings.dashboard_segment_products_desc}
          dashboard_segment_products_but_edit= {strings.dashboard_segment_products_but_edit}
          dashboard_segment_products_but_add= {strings.dashboard_segment_products_but_add}
          dashboard_segment_products_no_products= {strings.dashboard_segment_products_no_products}
          dashboard_box_products= {strings.dashboard_box_products}
          dashboard_box_clients= {strings.dashboard_box_clients}
          dashboard_box_co2_kg= {strings.dashboard_box_co2_kg}
          dashboard_box_co2= {strings.dashboard_box_co2}
          dashboard_box_water_l= {strings.dashboard_box_water_l}
          dashboard_box_water= {strings.dashboard_box_water}
          dashboard_segment_stores_header= {strings.dashboard_segment_stores_header}
          dashboard_segment_stores_label= {strings.dashboard_segment_stores_label}
          dashboard_segment_stores_name= {strings.dashboard_segment_stores_name}
          dashboard_segment_stores_status= {strings.dashboard_segment_stores_status}
          dashboard_segment_stores_type= {strings.dashboard_segment_stores_type}
          dashboard_segment_stores_contact= {strings.dashboard_segment_stores_contact}
          dashboard_segment_stores_actions= {strings.dashboard_segment_stores_actions}
          dashboard_segment_stores_but_edit= {strings.dashboard_segment_stores_but_edit}
          dashboard_segment_stores_but_add= {strings.dashboard_segment_stores_but_add}
          /></Tab.Pane> 
      },
      { menuItem: { key: 'insights', icon: 'chart area', content: strings.tab_insights_content }, render: () => 
        <Tab.Pane 
          attached={false}
        ><Insights 
  
        /></Tab.Pane>
      },
      { menuItem: { key: 'products', icon: 'food', content: strings.tab_products_content }, render: () => 
        <Tab.Pane 
          attached={false}
        ><Products 
          user={user}
  
        /></Tab.Pane> 
      },
      { menuItem: { key: 'Inventories', icon: 'percent', content: strings.tab_inventories_content }, render: () => 
        <Tab.Pane 
          attached={false}
        ><Inventories 
          user={user}
  
          /></Tab.Pane> 
      },
      { menuItem: { key: 'orders', icon: 'ordered list', content: strings.tab_orders_content }, render: () => 
        <Tab.Pane 
          attached={false}
        ><OrdersNew 
          user={user}
  
          /></Tab.Pane> 
      },
      { menuItem: { key: 'places', icon: 'building', content: strings.tab_places_content }, render: () => 
        <Tab.Pane /*loading={loading}*/ 
          attached={false}
        ><Stores 
          user={user}
  
          /></Tab.Pane> 
      },
      { menuItem: { key: 'mypurchases', icon: 'cart arrow down', content: strings.tab_purchases_content }, render: () => 
        <Tab.Pane 
          attached={false}
        ><Purchases 
          user={user}
  
          /></Tab.Pane>
      },
    ]
  )
  return (
    <Tab 
      className='dashboard_tab' 
      menu={{ color: 'teal' , pointing: true, secondary: true }} 
      panes={panes} 
      style={{marginTop: '50px'}}
    />
  )
}

export default DashboardAdmin
